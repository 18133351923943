import React, { useState, useEffect } from "react";
import Typist from "react-typist";
import "./landing.css"
import "./landingSVG.css"
import { Link } from "react-router-dom";
import Navbar from "./navbar"


const paths = [
    <path d="M2915 17330 c-9 -15 4 -30 26 -30 12 0 19 7 19 20 0 21 -33 29 -45
10z"/>,
    <path d="M4517 17294 c-11 -11 2 -23 40 -39 75 -32 117 -6 44 28 -39 17 -73
22 -84 11z"/>,
    <path d="M4101 17193 c-31 -3 -52 -12 -66 -28 -12 -12 -66 -44 -119 -70 -54
-27 -118 -65 -144 -86 -25 -21 -58 -41 -74 -44 -36 -8 -41 -18 -33 -66 5 -30
19 -52 56 -88 30 -29 51 -61 55 -80 4 -21 21 -42 48 -63 22 -18 58 -49 78 -70
21 -21 45 -38 53 -38 9 0 21 -12 27 -26 7 -14 29 -34 50 -45 26 -13 40 -28 43
-47 4 -15 10 -35 16 -44 5 -10 9 -24 9 -32 0 -8 16 -31 36 -52 20 -22 43 -47
51 -56 12 -13 20 -14 44 -6 29 10 29 11 13 29 -9 10 -21 16 -25 13 -16 -9 -10
23 7 37 9 8 13 21 10 35 -8 32 5 36 54 19 36 -13 49 -13 79 -3 20 7 40 19 44
25 10 16 43 17 52 2 5 -7 12 -6 23 4 10 8 30 17 45 21 15 4 25 11 22 16 -9 14
-63 12 -68 -2 -3 -9 -9 -7 -19 6 -12 15 -21 17 -44 12 -16 -4 -46 -7 -66 -7
-24 1 -40 -4 -46 -15 -7 -13 -18 -15 -50 -9 -41 7 -41 7 -26 30 8 13 23 25 34
28 13 3 17 11 13 26 -7 28 -27 27 -43 -3 -7 -13 -21 -27 -31 -29 -11 -3 -19
-14 -19 -26 0 -26 -16 -27 -30 -2 -7 13 -7 22 0 26 16 10 12 19 -12 30 -15 7
-22 18 -21 31 2 17 -5 23 -27 28 -17 3 -30 12 -30 20 0 8 -20 27 -45 43 -24
15 -44 33 -45 40 0 7 -9 17 -20 23 -11 6 -20 20 -20 32 0 18 -4 20 -36 15 -31
-5 -34 -4 -29 14 5 13 -1 26 -17 39 -13 11 -18 20 -13 21 6 1 15 2 20 3 6 0
10 6 10 11 0 6 -4 9 -10 7 -23 -8 -29 -6 -33 13 -2 11 -13 26 -23 33 -23 16
-25 37 -3 28 11 -4 18 1 21 14 3 11 9 21 14 21 19 3 24 5 34 10 28 17 66 19
71 4 4 -8 13 -15 21 -15 10 0 13 6 9 16 -3 9 -6 18 -6 20 0 2 -7 4 -15 4 -8 0
-15 7 -15 15 0 8 5 15 10 15 6 0 24 10 40 22 24 17 32 19 45 8 12 -10 18 -10
30 0 9 8 28 10 49 6 27 -5 37 -3 44 10 9 16 13 16 47 -5 27 -15 41 -19 48 -12
16 16 44 13 51 -4 3 -8 12 -15 20 -15 8 0 18 -9 21 -21 4 -12 12 -18 21 -15
17 7 44 -3 44 -15 0 -5 -6 -9 -14 -9 -18 0 -29 -16 -21 -30 4 -6 13 -5 26 3
18 11 25 7 62 -35 23 -26 54 -52 70 -57 15 -6 27 -16 27 -23 0 -11 90 -78 105
-78 3 0 5 7 5 15 0 8 11 20 25 27 22 12 23 16 12 37 -7 13 -30 31 -52 40 -55
22 -65 29 -75 56 -5 12 -22 29 -39 36 -34 15 -46 38 -36 69 4 14 2 20 -9 20
-9 0 -41 23 -71 50 -30 28 -64 50 -74 50 -10 0 -35 7 -54 15 -20 8 -52 15 -71
15 -19 0 -38 5 -41 10 -3 6 -14 9 -23 8 -9 -1 -37 -3 -61 -5z m397 -231 c2 -7
-6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2 31 -12z"/>,
    <path d="M5065 17170 c-8 -12 16 -62 31 -67 20 -6 25 29 9 53 -16 26 -30 30
-40 14z"/>,
    <path d="M4525 17130 c3 -5 22 -10 41 -10 19 0 34 5 34 10 0 6 -18 10 -41 10
-24 0 -38 -4 -34 -10z"/>,
    <path d="M4658 17103 c-30 -36 -48 -73 -48 -97 0 -20 7 -27 37 -35 20 -6 40
-8 45 -5 5 3 63 -4 130 -16 102 -19 126 -21 157 -10 32 10 43 10 70 -4 17 -9
36 -14 41 -11 9 6 117 -31 138 -48 19 -15 15 -26 -22 -63 -34 -34 -76 -45 -76
-20 0 18 -53 39 -79 31 -30 -9 -26 -31 9 -45 16 -7 28 -15 26 -19 -8 -12 -175
6 -199 22 -15 10 -29 12 -38 7 -11 -7 -6 -16 21 -40 29 -26 41 -30 68 -25 25
5 32 2 32 -10 0 -8 -4 -15 -10 -15 -15 0 -12 -17 7 -40 25 -29 47 -26 39 5 -6
23 -4 25 27 25 19 0 48 7 66 16 18 9 50 18 72 21 35 5 39 3 39 -16 0 -48 62
-3 64 46 1 18 7 29 19 31 12 3 24 -11 42 -50 14 -29 25 -60 25 -68 0 -8 14
-28 30 -45 29 -30 30 -33 16 -59 -11 -21 -11 -34 -3 -57 9 -22 9 -34 0 -47
-12 -20 -6 -112 7 -112 13 0 40 -78 40 -114 0 -19 9 -43 21 -57 11 -13 18 -31
16 -41 -7 -25 5 -22 27 8 15 22 16 29 6 49 -7 13 -10 35 -6 48 4 16 1 27 -8
30 -8 3 -15 31 -19 77 -3 39 -11 77 -16 84 -8 9 -7 21 2 39 14 31 22 34 31 10
3 -10 9 -25 14 -33 5 -8 14 -25 20 -37 27 -51 38 -68 50 -76 7 -5 10 -18 6
-30 -3 -16 2 -29 15 -41 12 -11 21 -31 21 -46 0 -16 7 -30 15 -34 20 -7 19
-25 -2 -48 -13 -15 -14 -21 -4 -27 25 -16 40 -63 41 -129 3 -158 1 -169 -28
-140 -9 9 -12 8 -12 -8 0 -11 5 -25 11 -31 16 -16 -8 -28 -26 -13 -9 7 -15 8
-20 0 -8 -14 -58 -13 -73 2 -9 9 -16 8 -29 -5 -10 -8 -38 -17 -63 -19 -40 -3
-45 -6 -43 -25 2 -15 -3 -23 -12 -23 -8 0 -15 8 -15 18 0 9 -9 32 -20 50 -15
25 -18 37 -10 52 15 28 12 51 -10 95 -26 50 -28 96 -7 129 15 23 20 25 40 13
12 -6 26 -18 30 -24 9 -14 37 -18 37 -5 0 4 -9 16 -21 25 -16 14 -20 27 -17
59 3 37 0 42 -21 48 -24 5 -34 18 -45 51 -2 10 -23 25 -44 35 -22 9 -46 28
-53 40 -15 29 -48 41 -61 23 -8 -11 -9 -11 -5 2 8 25 -30 80 -64 92 -16 6 -32
21 -35 32 -4 11 -46 59 -95 106 -49 47 -89 90 -89 97 0 6 -12 19 -26 29 -15 9
-46 38 -70 62 -23 25 -46 43 -49 39 -4 -4 -8 -22 -8 -40 -2 -31 0 -33 31 -30
26 2 32 -1 32 -18 0 -12 -10 -25 -25 -32 -29 -13 -29 -15 -10 -48 8 -14 14
-35 15 -47 0 -13 5 -23 10 -23 6 0 10 14 10 30 0 32 14 40 24 15 3 -8 11 -15
18 -15 7 0 20 -15 29 -33 9 -19 25 -33 37 -35 13 -2 22 -11 22 -21 0 -10 7
-24 15 -31 8 -7 15 -20 15 -29 0 -10 14 -22 33 -29 22 -8 48 -32 76 -71 24
-32 53 -63 66 -69 26 -12 41 -58 26 -76 -14 -16 -4 -26 26 -26 20 0 22 -4 17
-24 -4 -19 -1 -26 15 -31 20 -6 44 -65 42 -104 -3 -45 -2 -62 8 -136 14 -106
6 -184 -31 -275 -19 -46 -31 -97 -33 -133 -2 -31 -8 -64 -15 -72 -10 -13 -10
-19 4 -33 18 -21 22 -82 5 -99 -7 -7 -9 -19 -5 -29 5 -14 10 -13 34 11 20 20
27 38 29 69 2 66 20 165 36 205 8 20 19 50 23 66 8 26 16 31 51 36 37 5 82 37
83 61 0 3 15 10 33 13 17 4 37 10 42 14 6 4 34 13 63 20 50 12 54 12 96 -13
30 -18 45 -34 49 -53 3 -16 10 -46 15 -68 10 -51 -1 -105 -34 -156 -29 -46
-39 -47 -23 -3 9 23 8 37 -7 69 -10 22 -17 52 -15 66 3 34 -36 52 -96 43 -49
-7 -108 -42 -108 -64 0 -19 51 -25 81 -10 16 9 30 9 55 1 33 -11 34 -13 36
-65 0 -30 8 -69 17 -88 22 -45 13 -68 -61 -155 -75 -88 -87 -98 -154 -118 -52
-16 -58 -16 -81 -1 -14 9 -28 30 -32 47 -4 22 -11 30 -23 27 -21 -4 -31 -57
-39 -211 -8 -135 7 -165 169 -329 30 -31 44 -38 75 -38 21 0 48 7 60 16 27 19
87 15 87 -6 0 -8 -11 -20 -25 -27 -56 -28 -94 -43 -106 -43 -7 0 -19 -6 -26
-13 -21 -21 -54 -37 -78 -37 -11 0 -47 -11 -80 -25 -33 -14 -88 -29 -122 -35
-133 -20 -273 -70 -265 -93 6 -19 155 -5 202 19 11 6 47 17 80 24 33 8 66 20
73 27 7 7 31 13 53 13 21 0 39 4 39 9 0 17 164 75 227 79 53 4 62 7 65 25 2
11 8 22 15 24 16 6 27 -35 14 -51 -13 -15 9 -39 51 -55 16 -6 28 -19 30 -33 2
-17 11 -24 31 -26 30 -4 39 21 11 32 -27 10 -7 19 46 22 28 1 61 9 73 17 28
18 87 20 87 3 0 -7 -12 -13 -30 -13 -18 0 -39 -9 -55 -25 -31 -31 -20 -43 21
-22 38 20 152 34 185 22 15 -5 43 -5 68 1 36 8 48 6 75 -10 17 -10 37 -19 44
-19 8 0 41 -16 75 -37 104 -62 142 -80 178 -88 19 -4 64 -23 101 -41 63 -32
67 -36 83 -86 10 -29 23 -60 31 -68 7 -8 22 -36 33 -62 11 -27 25 -48 31 -48
6 0 15 -11 22 -25 8 -19 8 -28 -3 -41 -11 -14 -9 -20 18 -43 26 -22 32 -36 38
-86 4 -33 12 -66 18 -74 14 -16 17 -81 5 -81 -5 0 -14 14 -20 30 -10 31 -41
55 -57 46 -10 -6 25 -115 44 -136 9 -11 12 -34 8 -80 -3 -36 1 -85 7 -110 7
-25 18 -75 24 -111 7 -36 16 -70 19 -75 3 -5 8 -62 12 -126 3 -65 10 -132 16
-150 5 -18 9 -56 9 -85 0 -28 5 -55 10 -58 17 -10 11 -25 -10 -25 -11 0 -20
-4 -20 -9 0 -5 9 -11 20 -14 16 -4 20 -14 20 -50 0 -25 -5 -49 -10 -52 -14 -9
-2 -77 17 -92 42 -33 -21 -76 -81 -55 -18 7 -51 14 -74 16 -22 2 -54 13 -71
25 -33 23 -81 25 -88 4 -3 -7 12 -20 31 -30 28 -13 36 -23 36 -43 0 -15 10
-36 23 -48 22 -21 26 -22 53 -7 16 8 35 15 43 15 7 0 29 9 47 20 l33 21 12
-22 c15 -29 14 -236 -2 -275 -9 -20 -15 -143 -19 -389 -7 -349 -8 -365 -38
-515 -17 -85 -35 -169 -40 -185 -5 -17 -13 -66 -17 -110 -4 -44 -11 -87 -16
-96 -18 -35 -63 -282 -74 -414 -2 -22 -8 -62 -14 -89 -7 -33 -8 -89 -2 -170
10 -142 4 -249 -13 -243 -7 3 -23 -3 -36 -13 l-23 -18 22 -11 c18 -10 20 -15
10 -27 -9 -11 -10 -33 -1 -97 6 -45 16 -143 22 -217 6 -74 16 -170 21 -213 6
-42 8 -81 4 -86 -3 -5 0 -21 6 -35 8 -18 9 -46 2 -103 -6 -43 -7 -82 -3 -88 8
-12 24 -182 21 -236 -1 -20 4 -50 10 -65 12 -31 13 -165 3 -308 -7 -85 -8 -88
-23 -67 -13 17 -19 19 -29 9 -23 -23 -14 -48 17 -48 17 0 33 -4 36 -10 3 -5
13 -10 21 -10 10 0 14 13 14 49 0 29 6 53 14 60 11 9 15 69 21 272 4 142 11
262 16 265 14 8 11 178 -3 192 -7 7 -12 52 -14 114 -2 114 -11 188 -25 202 -5
6 -9 38 -9 73 0 47 -4 65 -15 69 -22 9 -22 435 0 454 8 7 15 29 15 50 0 20 3
40 7 44 25 24 33 91 32 249 -1 113 2 172 10 180 6 6 11 47 11 98 0 65 4 90 15
99 11 9 15 32 15 79 0 37 4 71 9 76 5 6 12 39 15 75 12 141 18 185 27 195 5 5
9 34 9 65 0 32 6 64 14 76 21 29 31 206 51 923 3 112 10 212 16 222 5 10 9 79
9 153 0 108 -3 137 -15 143 -12 7 -15 55 -20 273 -3 146 -5 329 -5 408 0 92
-4 150 -11 164 -8 14 -11 62 -10 128 1 81 -2 110 -13 125 -9 11 -16 31 -16 44
0 46 -48 126 -113 190 -40 39 -67 75 -71 92 -3 16 -16 38 -28 49 -64 57 -106
90 -116 90 -6 0 -23 6 -39 14 -15 8 -45 22 -66 32 -20 10 -47 28 -60 39 -29
26 -198 86 -262 92 -27 3 -56 10 -63 15 -6 6 -80 13 -163 15 -125 4 -154 2
-174 -11 -13 -9 -38 -16 -55 -16 -25 0 -33 6 -44 31 -7 17 -24 36 -37 43 -16
8 -26 23 -29 46 -4 28 -9 35 -27 33 -11 -1 -27 4 -33 12 -9 10 -32 15 -77 15
-72 0 -112 20 -135 68 -7 15 -34 43 -61 62 l-47 35 0 78 0 78 43 19 c23 11 50
20 59 20 9 0 29 6 45 14 15 7 45 22 67 32 28 13 44 30 56 59 10 22 29 51 44
64 14 14 26 28 26 33 0 4 15 28 34 52 18 24 36 60 40 80 4 22 16 42 31 52 l25
16 0 219 c0 134 4 220 10 224 15 9 12 47 -5 70 -10 12 -13 30 -9 50 4 22 1 37
-10 48 -11 11 -16 34 -16 77 0 64 -18 112 -47 129 -12 6 -16 20 -15 50 3 37 0
42 -25 52 -32 12 -43 26 -43 57 0 12 -4 22 -9 22 -10 0 -57 68 -84 123 -18 35
-29 41 -82 49 -13 2 -21 12 -23 30 -2 15 -12 34 -22 41 -12 8 -20 28 -22 57
-3 38 -7 45 -33 54 -16 6 -60 41 -97 79 -53 53 -68 74 -68 97 0 19 -7 33 -21
40 -15 9 -19 18 -15 35 7 29 -7 40 -24 20 -16 -19 -71 -19 -117 0 -19 8 -51
15 -70 15 -18 0 -50 7 -70 15 -53 23 -215 21 -235 -2z m692 -958 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>,
    <path d="M3420 17093 c-48 -5 -101 -34 -97 -53 1 -9 11 -14 22 -12 108 17 119
16 158 -22 34 -32 43 -36 92 -36 56 0 73 13 33 24 -13 3 -38 6 -56 6 -22 0
-35 6 -39 18 -4 9 -13 23 -20 30 -7 7 -13 22 -13 33 0 20 -4 20 -80 12z"/>,
    <path d="M3252 16975 c-24 -25 -22 -45 4 -45 9 0 29 -13 46 -30 20 -20 40 -30
61 -30 18 0 41 -7 51 -15 29 -22 46 -18 46 11 0 45 -18 62 -90 85 -39 13 -70
29 -70 36 0 21 -23 15 -48 -12z"/>,
    <path d="M3918 16932 c-17 -7 -16 -8 2 -20 17 -11 20 -10 20 8 0 11 -1 20 -2
19 -2 0 -11 -4 -20 -7z"/>,
    <path d="M4740 16878 c0 -22 7 -25 73 -31 33 -3 47 -1 47 8 0 21 -22 32 -72
37 -42 5 -48 3 -48 -14z"/>,
    <path d="M3055 16879 c-17 -4 -40 -17 -52 -28 -11 -11 -37 -26 -56 -34 -19 -8
-59 -38 -88 -66 -30 -28 -59 -51 -65 -51 -19 0 -80 -74 -109 -131 -23 -47 -26
-61 -20 -114 3 -33 7 -85 8 -115 3 -54 3 -55 38 -60 32 -5 34 -8 40 -48 3 -23
10 -46 15 -51 19 -19 90 -51 111 -51 12 0 25 -5 29 -12 5 -8 11 -5 20 7 10 14
11 22 3 32 -17 19 -5 39 42 69 70 44 157 134 202 209 23 38 45 72 49 73 5 2 8
15 8 28 0 14 7 38 15 53 10 21 13 52 11 112 -3 69 -7 84 -22 90 -11 4 -39 28
-63 53 -45 47 -60 52 -116 35z m45 -77 c0 -14 -39 -32 -46 -21 -4 6 0 15 7 20
17 11 39 12 39 1z m44 -69 c4 -4 17 -8 29 -8 18 0 22 -6 23 -35 2 -57 -25
-138 -52 -159 -13 -10 -24 -26 -24 -36 0 -27 -20 -56 -81 -116 -73 -71 -112
-95 -171 -103 -46 -7 -48 -6 -55 21 -4 15 -6 35 -5 44 2 8 -8 20 -22 27 -35
16 -46 70 -17 93 11 9 21 27 21 38 0 16 6 21 24 21 19 0 25 -6 28 -27 4 -34
22 -37 26 -5 2 13 -3 25 -12 28 -27 10 -20 44 9 44 18 0 25 5 25 20 0 11 6 21
13 22 6 0 17 2 22 2 22 3 36 19 30 35 -7 22 19 48 30 31 4 -6 4 -17 1 -25 -3
-9 1 -15 9 -15 9 0 15 9 15 23 0 29 34 62 55 54 22 -9 21 -93 -2 -106 -17 -10
-16 -12 7 -30 24 -20 25 -20 44 -1 13 12 16 24 11 37 -14 31 -14 133 0 133 6
0 15 -3 19 -7z m-334 -102 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9
-9z m-28 -66 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11
-8 11 -13z"/>,
    <path d="M2980 16489 c-10 -18 -9 -20 7 -17 10 2 19 11 21 21 5 24 -15 21 -28
-4z"/>,
    <path d="M3054 16466 c-4 -10 -2 -22 4 -28 8 -8 13 -7 18 6 4 10 2 22 -4 28
-8 8 -13 7 -18 -6z"/>,
    <path d="M2820 16431 c0 -16 -3 -36 -6 -45 -10 -25 12 -19 30 9 13 20 14 28 4
45 -18 28 -28 25 -28 -9z"/>,
    <path d="M2900 16413 c1 -21 33 -63 49 -63 17 0 13 25 -10 54 -24 30 -39 34
-39 9z"/>,
    <path d="M4466 16824 c-8 -20 0 -32 14 -18 5 5 5 15 1 22 -6 11 -10 10 -15 -4z" />,
    <path d="M3315 16790 c-4 -6 5 -23 20 -38 15 -15 33 -39 41 -54 18 -35 51 -37
64 -4 15 40 2 63 -50 85 -55 24 -66 25 -75 11z"/>,
    <path d="M4590 16721 c0 -16 38 -42 45 -32 3 6 4 15 1 20 -7 11 -46 21 -46 12z" />,
    <path d="M4305 16600 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>,
    <path d="M4425 16600 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10
-8 0 -18 -4 -21 -10z"/>,
    <path d="M4677 16603 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />,
    <path d="M5169 16589 c12 -11 21 -24 21 -30 0 -5 17 -9 38 -8 20 1 48 -1 62
-5 31 -8 53 5 45 26 -5 12 -24 18 -68 21 -34 3 -75 7 -91 10 l-28 5 21 -19z"/>,
    <path d="M3303 16583 c-7 -2 -13 -14 -13 -26 0 -12 -12 -44 -25 -72 -29 -58
-30 -65 -11 -65 25 0 63 63 71 118 7 50 4 56 -22 45z"/>,
    <path d="M4470 16494 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16
-16z"/>,
    <path d="M3634 16465 c-3 -19 -3 -52 2 -72 4 -21 7 -50 6 -64 -3 -30 21 -172
43 -249 8 -30 20 -77 26 -105 6 -27 18 -57 26 -67 8 -9 13 -29 11 -45 -3 -25
-8 -29 -43 -33 -31 -4 -41 -10 -43 -26 -2 -15 4 -23 19 -27 22 -6 69 18 69 35
0 14 43 -2 57 -21 11 -15 12 -14 13 2 0 9 -4 17 -10 17 -17 0 -11 46 8 63 10
10 23 17 28 17 11 0 104 114 104 128 0 5 -7 15 -15 22 -8 7 -15 20 -15 30 0
19 -30 50 -48 50 -20 0 -44 -62 -46 -118 -2 -40 -7 -58 -21 -68 -26 -19 -45
-1 -45 42 0 19 -5 34 -11 34 -6 0 -9 21 -8 54 2 32 -3 61 -10 69 -7 9 -14 68
-18 153 -4 108 -10 147 -24 177 -24 47 -46 48 -55 2z"/>,
    <path d="M5207 16456 c-7 -17 18 -42 27 -27 7 11 -3 41 -14 41 -4 0 -10 -6
-13 -14z"/>,
    <path d="M4601 16433 c21 -26 32 -33 57 -33 13 0 22 6 22 15 0 8 -9 15 -19 15
-11 0 -23 5 -26 10 -3 6 -15 10 -27 10 -18 0 -19 -2 -7 -17z"/>,
    <path d="M4827 16394 c-11 -11 3 -24 24 -24 12 0 19 5 17 13 -5 14 -31 21 -41
11z"/>,
    <path d="M3760 16299 c-12 -45 -9 -55 15 -46 9 4 15 19 15 41 0 50 -17 53 -30
5z"/>,
    <path d="M3217 16240 c-14 -11 -31 -36 -37 -55 -6 -19 -20 -37 -31 -40 -10 -4
-19 -13 -19 -21 0 -8 -4 -14 -10 -14 -5 0 -10 -9 -10 -20 0 -11 6 -20 14 -20
19 0 28 -14 20 -34 -9 -24 -54 -21 -54 4 0 23 -30 33 -80 28 -28 -2 -38 -11
-57 -45 l-24 -43 25 0 c14 0 28 -4 31 -10 3 -5 17 -10 31 -10 13 0 24 -4 24
-9 0 -14 98 -33 145 -29 l40 3 6 100 c3 55 11 113 17 128 14 33 15 76 4 95 -7
10 -14 8 -35 -8z"/>,
    <path d="M4475 16234 c-29 -29 -57 -96 -62 -150 -8 -74 8 -78 89 -22 35 24 77
49 93 56 43 17 174 22 183 7 18 -30 140 -82 168 -71 8 3 19 17 24 31 8 20 16
25 45 25 21 0 38 6 41 14 3 8 17 17 30 20 14 4 24 12 21 19 -6 19 -40 21 -53
2 -11 -15 -14 -15 -37 -1 -22 15 -25 15 -40 -2 -21 -23 -110 -21 -138 4 -12
11 -27 14 -43 10 -16 -4 -36 0 -56 13 -18 10 -39 17 -48 14 -9 -2 -37 3 -61
12 -24 8 -56 15 -72 15 -15 0 -31 5 -34 10 -9 15 -31 12 -50 -6z"/>,
    <path d="M7312 16198 c-1 -29 -4 -95 -8 -145 -6 -83 -5 -93 11 -100 13 -4 24
1 38 18 17 22 19 33 12 119 -3 52 -9 99 -13 105 -3 5 -6 19 -7 30 0 11 -7 21
-17 23 -13 3 -16 -6 -16 -50z"/>,
    <path d="M2404 16166 c-21 -44 31 -65 60 -25 19 28 10 49 -23 49 -18 0 -30 -8
-37 -24z"/>,
    <path d="M5600 16175 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M4122 16133 c3 -53 15 -62 48 -35 22 18 23 22 11 51 -10 23 -20 31
-38 31 -23 0 -24 -3 -21 -47z"/>,
    <path d="M4296 16158 c-3 -7 -9 -41 -15 -76 -5 -34 -18 -75 -29 -90 -10 -15
-29 -60 -41 -100 -28 -87 -20 -107 27 -68 16 14 35 26 41 26 6 0 20 5 31 12
17 11 24 8 50 -17 16 -16 30 -38 30 -50 0 -27 20 -52 45 -57 17 -3 20 -12 23
-61 3 -62 -7 -72 -34 -32 -9 14 -23 25 -31 25 -7 0 -32 9 -54 21 -23 11 -43
18 -46 16 -2 -3 4 -22 14 -43 13 -28 27 -41 50 -48 18 -5 35 -16 38 -25 9 -25
53 -71 68 -71 21 0 59 -21 92 -52 16 -16 35 -28 41 -28 11 0 44 -32 44 -43 0
-8 -60 -37 -77 -37 -7 0 -13 7 -13 15 0 8 5 15 11 15 6 0 9 10 7 23 -3 19 -9
22 -57 24 -32 1 -60 8 -71 18 -19 17 -40 20 -40 5 0 -5 -7 -10 -15 -10 -17 0
-20 16 -5 25 6 3 10 15 10 27 0 16 -5 19 -23 14 -17 -4 -36 3 -65 25 -23 17
-42 35 -42 41 0 5 -7 17 -16 26 -16 15 -16 63 1 103 8 22 -35 34 -54 15 -11
-11 -11 -21 -1 -51 10 -31 10 -39 -3 -52 -8 -9 -20 -12 -26 -9 -14 9 -14 61 0
84 9 14 9 38 2 88 -9 64 -10 67 -21 42 -6 -14 -16 -29 -22 -33 -7 -4 -18 -23
-26 -41 -16 -38 -33 -42 -85 -20 -35 15 -125 11 -135 -5 -3 -5 -14 -9 -24 -9
-22 0 -111 -40 -121 -54 -10 -13 -38 0 -47 21 -26 61 -47 84 -76 81 -23 -2
-36 -13 -55 -45 -14 -23 -44 -70 -65 -105 -35 -56 -40 -72 -45 -144 -3 -46
-12 -88 -20 -97 -11 -14 -11 -20 0 -33 8 -10 9 -18 2 -22 -5 -4 -13 -20 -17
-37 -5 -19 -39 -61 -92 -112 -46 -45 -84 -87 -84 -93 0 -6 -16 -26 -35 -45
-21 -21 -32 -41 -29 -50 4 -8 3 -23 0 -32 -5 -12 -9 -14 -18 -5 -18 18 -28 15
-38 -13 -8 -22 -13 -24 -30 -15 -16 9 -20 8 -20 -5 0 -8 5 -15 10 -15 21 0 8
-46 -20 -74 -16 -16 -33 -44 -36 -63 -5 -29 -8 -32 -22 -21 -8 7 -23 12 -34
10 -10 -2 -31 6 -47 17 -36 26 -106 56 -151 66 -19 4 -51 20 -71 36 l-36 28
-23 -24 c-28 -30 -38 -30 -67 -6 -20 16 -21 22 -11 54 15 49 -6 125 -37 137
-22 8 -23 13 -19 112 3 80 7 105 20 115 12 8 14 20 9 42 -4 17 -1 47 5 66 9
26 9 39 1 49 -8 9 -9 59 -3 167 9 167 20 203 69 236 23 16 84 73 120 114 7 8
24 25 37 38 30 30 39 62 26 95 -18 48 -41 29 -42 -34 0 -46 -4 -49 -73 -71
-28 -9 -54 -25 -60 -36 -17 -33 -73 -93 -88 -93 -29 0 -37 -25 -37 -111 0 -66
4 -90 16 -100 14 -11 14 -13 0 -21 -13 -7 -16 -31 -16 -137 0 -105 -3 -131
-16 -142 -13 -11 -15 -43 -14 -228 1 -119 6 -230 11 -247 6 -20 21 -36 42 -46
55 -26 94 -66 131 -131 19 -34 44 -71 56 -82 12 -11 26 -31 31 -44 9 -23 10
-23 29 -6 19 17 21 17 39 1 11 -10 22 -29 25 -42 11 -43 31 -22 28 29 -2 25 2
48 8 52 21 13 9 25 -24 25 -47 0 -66 9 -76 36 -5 12 -19 24 -32 26 -37 5 -36
49 1 71 38 22 40 22 52 -11 5 -15 22 -33 40 -41 17 -7 34 -24 39 -37 5 -13 16
-24 25 -24 17 0 21 35 5 45 -13 8 -3 25 14 25 7 0 25 -10 40 -23 24 -21 25
-25 11 -37 -10 -9 -12 -16 -5 -25 18 -21 -17 -75 -48 -75 -26 -1 -26 -1 -7
-16 20 -15 20 -15 -2 -32 -16 -12 -20 -21 -13 -31 11 -19 -4 -48 -28 -55 -10
-4 -24 -22 -31 -41 -7 -21 -19 -35 -29 -35 -9 0 -34 -3 -54 -6 -34 -6 -38 -4
-38 14 0 11 5 24 10 27 6 3 10 31 10 61 0 53 -1 54 -28 54 -25 0 -28 -4 -28
-32 0 -39 -24 -98 -40 -98 -13 0 -74 -83 -74 -102 0 -8 -5 -19 -11 -25 -14
-14 0 -63 18 -63 8 0 17 8 20 19 2 10 15 23 28 29 20 9 22 8 18 -8 -3 -10 -14
-26 -24 -36 -10 -9 -19 -22 -19 -27 0 -6 -8 -19 -17 -29 -10 -11 -18 -38 -19
-65 -1 -41 -7 -51 -53 -94 -46 -43 -51 -51 -41 -70 9 -16 8 -24 -4 -38 -9 -10
-16 -25 -16 -35 0 -9 -6 -19 -14 -22 -7 -3 -19 -31 -26 -63 -14 -69 -53 -125
-70 -101 -6 9 -8 36 -4 65 5 39 13 56 38 77 22 20 28 30 19 36 -15 9 1 82 17
82 6 0 10 7 10 15 0 9 6 18 14 21 8 3 19 19 25 35 5 16 17 29 25 29 9 0 16 7
16 15 0 8 -4 15 -9 15 -5 0 -11 9 -14 20 -3 12 -14 20 -25 20 -20 0 -20 -2
-35 -95 -2 -11 -13 -21 -24 -23 -20 -3 -39 -34 -78 -127 -9 -22 -21 -50 -27
-62 -6 -11 -8 -27 -6 -35 3 -7 1 -20 -3 -30 -11 -21 8 -76 31 -88 17 -9 18
-19 13 -88 -3 -42 -5 -89 -4 -104 1 -16 2 -43 3 -60 1 -18 -2 -41 -6 -50 -6
-13 0 -21 24 -33 41 -21 59 -59 38 -82 -8 -10 -21 -34 -29 -53 -7 -19 -36 -60
-64 -90 -27 -31 -61 -70 -75 -88 -22 -28 -33 -34 -81 -38 -49 -5 -59 -2 -85
19 -16 13 -45 28 -64 32 -19 4 -41 11 -50 15 -74 37 -178 80 -238 99 -64 20
-76 21 -90 8 -9 -8 -25 -17 -35 -20 -32 -12 -13 -32 27 -30 30 2 36 -1 36 -18
0 -13 -8 -23 -20 -26 -18 -5 -19 -8 -7 -22 8 -9 17 -23 20 -31 4 -12 6 -11 6
3 1 20 14 22 41 5 16 -11 23 -9 40 10 25 26 19 42 -16 42 -41 0 -22 24 19 25
28 1 40 -4 52 -22 15 -22 14 -24 -10 -33 -24 -9 -33 -29 -18 -43 3 -4 12 -2
20 5 10 8 22 6 48 -8 33 -18 35 -18 48 0 19 27 51 17 57 -18 5 -27 30 -50 30
-27 0 6 9 15 21 21 16 9 32 7 72 -6 58 -20 87 -46 87 -78 0 -12 9 -27 19 -33
18 -12 19 -10 14 23 l-6 36 39 -6 c37 -7 40 -5 53 26 7 18 14 42 15 53 1 13 9
21 26 24 14 1 28 8 32 14 4 6 11 8 15 3 4 -5 2 -11 -4 -13 -22 -8 -14 -25 9
-21 20 3 22 8 20 48 -3 46 9 71 65 129 18 20 28 22 58 17 25 -5 44 -3 58 7 30
19 83 10 101 -17 12 -19 38 -31 114 -52 6 -2 12 -13 12 -24 0 -11 4 -20 10
-20 5 0 9 10 7 22 -1 14 4 23 16 26 10 2 17 -3 17 -12 0 -23 19 -30 27 -9 4
10 11 21 17 25 6 4 7 10 3 14 -4 4 -12 3 -18 -3 -14 -14 -62 10 -83 42 -13 20
-22 23 -46 18 -27 -5 -30 -3 -30 21 0 29 24 43 90 53 19 3 39 12 43 20 4 7 20
13 35 13 15 0 37 7 49 15 14 10 24 12 28 5 7 -12 73 -3 92 12 21 17 58 5 61
-20 3 -22 1 -23 -32 -16 -39 7 -44 -1 -22 -32 14 -18 15 -18 28 -1 13 17 15
17 36 -7 12 -15 29 -26 37 -26 8 0 15 -7 15 -15 0 -8 9 -23 21 -34 12 -11 18
-24 15 -30 -9 -14 13 -24 28 -12 9 8 19 3 37 -19 26 -32 49 -39 49 -15 0 8
-20 29 -45 47 -33 25 -44 40 -45 61 0 16 -5 27 -14 27 -8 0 -17 11 -20 24 -3
14 -15 28 -27 31 -11 4 -24 18 -29 31 -5 13 -16 24 -24 24 -7 0 -19 7 -26 15
-7 8 -22 15 -34 15 -11 0 -44 12 -72 26 -51 26 -52 26 -107 10 -47 -14 -60
-23 -91 -66 -38 -53 -47 -56 -138 -51 -21 2 -47 -4 -60 -13 -29 -20 -58 -20
-102 1 -47 22 -61 59 -59 162 1 46 3 84 5 85 33 14 44 28 50 67 16 103 28 153
43 175 8 13 15 30 15 39 0 26 51 96 118 161 22 23 32 42 32 64 0 23 6 33 25
40 22 8 25 15 24 48 -2 21 2 45 7 52 5 8 9 42 9 75 l0 60 53 3 c46 3 54 6 62
28 11 27 25 24 48 -8 9 -12 26 -18 52 -18 l40 0 -7 -89 -6 -89 42 -21 c43 -22
64 -50 104 -133 17 -37 27 -47 47 -48 14 0 38 -12 54 -26 24 -21 27 -30 21
-60 -8 -47 10 -101 38 -110 12 -4 36 -17 53 -30 38 -29 48 -30 85 -3 29 21 39
49 17 49 -9 0 -9 3 0 12 17 17 15 38 -3 38 -8 0 -15 -7 -15 -15 0 -20 -13 -19
-33 3 -21 23 -22 38 -2 46 19 7 65 -10 65 -24 0 -5 7 -10 15 -10 17 0 20 -25
5 -35 -16 -10 -1 -25 26 -25 15 0 24 6 24 15 0 13 3 13 20 3 16 -11 22 -10 33
6 17 24 37 13 37 -20 0 -13 5 -24 10 -24 6 0 10 11 10 23 0 32 10 40 25 21 10
-15 15 -14 50 5 56 30 89 26 48 -5 -17 -12 -16 -13 5 -14 13 0 41 17 66 40 26
24 47 37 51 30 8 -13 45 -13 45 0 0 6 -4 10 -10 10 -5 0 -10 14 -10 30 0 17
-4 30 -10 30 -5 0 -10 -6 -10 -14 0 -23 -20 -30 -37 -13 -13 13 -11 17 17 37
30 22 32 22 46 4 8 -11 19 -27 25 -36 12 -22 33 -23 54 -2 15 16 19 15 53 -3
32 -16 37 -25 40 -57 4 -43 -7 -56 -50 -56 -16 0 -28 -4 -28 -9 0 -6 -13 -17
-28 -26 -33 -20 -72 -74 -72 -101 0 -10 -9 -30 -19 -43 l-18 -24 -19 21 c-10
12 -21 22 -25 22 -3 0 -9 -39 -13 -87 -3 -48 -11 -92 -16 -97 -6 -6 -10 -21
-10 -34 0 -23 3 -24 40 -19 64 8 70 18 70 114 l0 84 35 14 c19 8 47 31 62 52
41 53 85 93 111 99 12 4 22 10 22 15 0 5 11 9 25 9 28 0 33 -21 10 -40 -19
-16 -19 -30 0 -44 8 -6 15 -22 15 -36 -2 -154 4 -270 16 -299 8 -18 14 -44 14
-57 0 -26 12 -37 32 -29 17 7 52 81 44 94 -11 18 7 42 48 63 79 40 178 188
126 188 -14 0 -67 -55 -74 -77 -5 -17 -16 -21 -57 -22 -36 0 -49 3 -47 12 3 6
16 13 31 15 19 2 30 13 43 43 10 21 21 39 26 39 19 0 2 21 -97 123 -74 76
-105 115 -105 132 0 13 -6 30 -14 37 -22 23 -56 95 -56 122 0 13 -6 26 -14 29
-14 6 -53 -17 -71 -41 -9 -11 -18 -11 -57 2 -66 23 -133 12 -175 -29 -40 -39
-43 -70 -7 -79 19 -5 25 -12 22 -29 -2 -18 -11 -23 -42 -25 -22 -2 -49 1 -60
7 -12 5 -41 12 -66 15 -102 10 -139 28 -211 105 -65 68 -69 76 -69 119 -1 41
-2 45 -14 30 -7 -10 -16 -18 -20 -18 -13 0 -45 44 -46 62 0 10 -13 33 -30 51
-19 22 -30 46 -30 65 0 16 -6 35 -14 41 -8 7 -18 26 -21 42 -4 16 -14 38 -24
47 -9 9 -43 45 -75 79 -64 69 -64 67 -7 132 15 17 21 37 21 72 0 44 2 48 28
53 19 5 34 19 50 49 12 23 22 47 22 54 0 7 9 17 19 23 11 5 21 20 23 32 2 12
25 36 57 58 52 36 101 104 101 139 0 22 48 79 79 96 16 8 34 28 39 45 15 42
45 39 73 -7 12 -21 33 -50 46 -64 22 -24 24 -30 14 -65 -13 -47 -13 -55 -2
-73 8 -12 12 -12 24 -2 21 17 29 1 31 -64 1 -44 8 -61 42 -106 24 -33 45 -76
53 -109 7 -30 24 -69 37 -87 13 -17 24 -39 24 -47 0 -9 9 -25 20 -36 11 -11
20 -28 20 -37 0 -10 9 -33 20 -51 11 -18 20 -38 20 -46 0 -7 11 -33 25 -57 34
-60 33 -57 40 -122 5 -42 12 -60 26 -67 13 -8 19 -21 19 -45 0 -23 5 -35 14
-35 7 0 20 -10 29 -22 l15 -23 26 24 25 24 20 -24 c11 -13 23 -32 26 -41 4
-10 16 -18 29 -18 24 0 36 -29 36 -85 0 -31 19 -44 57 -38 23 4 32 11 36 29 6
33 -37 119 -73 146 -19 13 -30 31 -30 45 0 40 -28 83 -54 83 -22 0 -24 5 -30
68 -6 62 -11 73 -46 111 -22 24 -40 53 -40 65 0 13 -14 42 -31 66 -18 24 -40
63 -50 86 -11 23 -26 44 -34 48 -8 3 -15 13 -15 23 0 13 -3 14 -12 5 -21 -21
-38 -14 -36 16 5 82 -2 107 -42 151 -33 35 -40 50 -40 82 0 56 -13 77 -45 72
-19 -4 -25 -1 -25 13 0 9 -7 25 -16 35 -12 13 -13 22 -5 38 13 23 2 59 -24 81
-24 20 -74 122 -81 167 -7 50 38 162 92 227 52 63 169 150 221 165 59 16 80 4
258 -145 22 -18 62 -52 88 -74 27 -22 52 -40 57 -40 4 0 37 -28 71 -61 35 -34
84 -74 108 -90 24 -15 60 -43 80 -61 20 -18 59 -44 86 -57 28 -13 63 -35 79
-49 17 -13 39 -28 50 -33 22 -9 31 -15 104 -61 24 -16 48 -28 54 -28 6 0 33
-14 59 -30 26 -17 65 -34 86 -37 21 -3 43 -10 49 -15 13 -10 44 0 44 14 0 5
-11 19 -25 32 -19 17 -25 33 -25 63 0 53 -8 58 -41 27 -25 -24 -30 -25 -45
-13 -10 8 -25 26 -34 41 -20 34 -84 78 -111 78 -47 0 -79 40 -43 54 22 8 9 23
-26 31 -21 5 -30 13 -30 27 0 11 -21 42 -47 68 -72 74 -82 87 -100 132 -9 23
-33 58 -53 77 -32 31 -38 43 -42 94 -4 44 -11 64 -30 85 -15 15 -31 45 -37 66
-13 51 -58 98 -80 86 -15 -7 -17 -1 -16 48 0 31 3 62 8 69 4 7 -6 19 -28 31
-32 20 -34 24 -37 83 -2 39 -8 64 -15 67 -7 2 -15 -2 -17 -10z m-687 -554 c-6
-8 -9 -23 -6 -35 6 -22 -18 -69 -34 -69 -14 0 -11 38 4 44 6 3 3 4 -8 3 -34
-4 -31 32 4 46 17 6 31 17 31 25 0 7 5 10 10 7 8 -5 7 -11 -1 -21z m-74 -195
c8 -12 -23 -49 -42 -49 -7 0 -13 8 -13 18 0 33 40 56 55 31z m113 -373 c3 -12
-1 -17 -10 -14 -7 3 -15 13 -16 22 -3 12 1 17 10 14 7 -3 15 -13 16 -22z
m-701 -651 c-4 -8 -14 -15 -23 -15 -16 0 -16 2 1 20 20 22 31 20 22 -5z m67
-27 c14 -45 15 -78 2 -78 -17 0 -40 54 -32 75 7 18 25 20 30 3z m-354 -22 c0
-8 -7 -19 -15 -26 -16 -13 -18 -8 -9 27 6 23 24 22 24 -1z m456 -178 c-3 -7
-9 -21 -12 -30 -8 -21 -24 -24 -24 -4 0 26 12 46 27 46 8 0 12 -6 9 -12z
m1004 -33 c0 -24 -24 -44 -37 -31 -8 7 17 56 29 56 4 0 8 -11 8 -25z m-90 -21
c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6 -3 10 -13 10 -21z
m185 -524 c-5 -8 -11 -8 -17 -2 -6 6 -7 16 -3 22 5 8 11 8 17 2 6 -6 7 -16 3
-22z m55 -45 c0 -9 -8 -14 -22 -12 -18 2 -24 -4 -26 -26 -4 -34 -26 -31 -30 4
-2 19 3 27 25 36 40 16 53 15 53 -2z m-1792 -377 c-5 -32 -28 -35 -28 -4 0 17
5 26 16 26 10 0 14 -7 12 -22z m-658 -198 c0 -5 -7 -10 -15 -10 -8 0 -15 5
-15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z"/>,
    <path d="M5550 16099 c0 -17 4 -28 10 -24 6 3 10 17 10 31 0 13 -4 24 -10 24
-5 0 -10 -14 -10 -31z"/>,
    <path d="M4130 16025 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>,
    <path d="M4568 16033 c-24 -6 -23 -20 3 -28 20 -7 20 -8 5 -23 -13 -13 -21
-14 -45 -5 -33 12 -51 2 -51 -29 0 -21 4 -22 31 -8 15 9 26 6 52 -14 18 -14
57 -33 86 -42 51 -17 52 -17 74 4 27 27 19 35 -55 56 -69 20 -75 36 -15 36 37
0 48 -6 82 -40 30 -31 42 -37 52 -28 10 8 24 8 54 -1 39 -12 59 -9 59 10 0 12
-51 58 -65 59 -5 0 -30 14 -54 30 -40 27 -51 30 -120 29 -42 -1 -84 -3 -93 -6z"/>,
    <path d="M5640 16025 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M4117 15963 c-30 -34 -26 -73 8 -73 23 0 25 4 25 45 0 48 -8 55 -33
28z"/>,
    <path d="M5550 15951 c0 -17 5 -31 10 -31 6 0 10 11 10 24 0 14 -4 28 -10 31
-6 4 -10 -7 -10 -24z"/>,
    <path d="M2826 15963 c-3 -3 -6 -13 -6 -21 0 -8 -8 -21 -17 -28 -16 -12 -16
-13 8 -14 50 0 109 34 109 64 0 5 -88 5 -94 -1z"/>,
    <path d="M5005 15952 c-16 -10 -32 -20 -35 -24 -11 -15 -82 -58 -94 -58 -21 0
-36 -24 -29 -48 8 -31 34 -28 122 15 134 65 169 103 115 123 -33 13 -43 12
-79 -8z"/>,
    <path d="M2949 15817 l-27 -32 45 -39 c29 -26 43 -45 42 -60 -1 -11 2 -30 6
-40 6 -13 4 -21 -3 -24 -19 -6 -14 -32 6 -32 24 0 45 -44 33 -68 -6 -9 -16
-44 -23 -77 -16 -76 -34 -81 -87 -26 -37 39 -40 46 -47 122 -6 75 -9 83 -42
115 -35 34 -36 35 -20 62 9 15 27 30 40 33 19 5 16 7 -17 12 -91 14 -175 3
-175 -23 0 -5 10 -18 21 -29 17 -15 20 -27 15 -51 -5 -27 -2 -33 24 -48 16 -9
38 -37 51 -64 12 -26 35 -59 51 -74 21 -21 28 -37 28 -65 0 -21 7 -42 15 -49
12 -10 14 -24 9 -57 -7 -51 9 -58 44 -18 24 27 33 30 60 16 9 -5 25 -12 36
-15 15 -5 16 -9 6 -21 -12 -15 -7 -45 7 -45 9 0 33 48 33 65 0 7 -9 20 -20 30
l-21 19 27 21 c23 19 25 24 15 43 -26 48 10 55 58 13 32 -29 86 -49 98 -37 3
3 12 -2 19 -12 12 -16 13 -16 31 1 11 9 25 17 31 17 17 0 15 18 -5 36 -40 35
-113 84 -126 84 -27 0 -46 35 -53 99 -6 58 -9 64 -35 73 -30 10 -40 38 -14 38
8 0 15 7 15 15 0 8 -6 15 -12 15 -7 0 -33 25 -58 55 -25 30 -47 55 -50 54 -3
0 -17 -15 -31 -32z"/>,
    <path d="M5546 15834 c-9 -22 -8 -24 9 -24 8 0 15 9 15 20 0 24 -15 27 -24 4z" />,
    <path d="M3874 15829 c-3 -6 0 -15 7 -20 29 -18 57 -5 38 18 -14 16 -35 17
-45 2z"/>,
    <path d="M3281 15816 c-18 -21 -3 -134 20 -157 24 -24 55 -24 68 -1 11 22 3
68 -15 83 -18 15 -18 43 1 59 8 7 15 16 15 21 0 15 -77 10 -89 -5z"/>,
    <path d="M4905 15583 c-9 -21 -17 -54 -18 -73 -2 -27 2 -35 18 -38 11 -2 26
-9 34 -15 8 -7 27 -12 43 -13 34 -2 55 29 44 68 -6 24 -81 108 -97 108 -5 0
-16 -17 -24 -37z"/>,
    <path d="M2986 15545 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 -9 0
-12 -6 -9 -15z"/>,
    <path d="M5414 15485 c-4 -8 -3 -22 0 -32 11 -28 -14 -82 -45 -98 -17 -9 -29
-24 -29 -35 0 -16 5 -19 33 -14 17 3 38 8 45 10 7 2 27 -6 43 -19 l31 -22 24
30 c30 37 30 41 0 68 -13 12 -27 38 -31 57 -4 19 -13 42 -19 52 -13 22 -44 24
-52 3z"/>,
    <path d="M1817 15483 c-11 -10 -8 -61 4 -69 19 -11 42 22 35 50 -6 25 -25 34
-39 19z"/>,
    <path d="M5001 15392 c-13 -24 -3 -92 15 -99 8 -3 14 -11 14 -18 0 -29 20 -24
35 8 18 40 11 69 -28 105 -25 23 -26 23 -36 4z"/>,
    <path d="M2700 15355 c-16 -19 0 -19 26 0 16 12 16 14 3 15 -9 0 -22 -7 -29
-15z"/>,
    <path d="M3375 15330 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
-11 0 -17 -4 -14 -10z"/>,
    <path d="M5100 15244 c-6 -14 -8 -30 -4 -35 15 -25 59 6 48 34 -10 28 -34 28
-44 1z"/>,
    <path d="M5394 15235 c3 -9 0 -25 -6 -35 -10 -16 -10 -23 4 -36 15 -15 17 -15
27 5 7 12 8 27 4 34 -4 6 -6 20 -5 30 2 10 -3 17 -13 17 -11 0 -15 -5 -11 -15z"/>,
    <path d="M3092 15233 c-35 -7 -37 -29 -8 -73 29 -44 46 -51 46 -20 0 11 -4 20
-9 20 -16 0 -23 38 -11 60 6 11 9 19 8 19 -2 -1 -13 -4 -26 -6z"/>,
    <path d="M5483 15233 c-36 -5 -45 -28 -14 -36 22 -5 64 13 63 28 -1 12 -8 13
-49 8z"/>,
    <path d="M2876 15193 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />,
    <path d="M7074 15175 c-7 -17 4 -37 17 -30 12 8 12 45 -1 45 -6 0 -13 -7 -16
-15z"/>,
    <path d="M2927 15163 c-9 -16 6 -85 20 -90 25 -10 33 10 19 52 -14 38 -30 54
-39 38z"/>,
    <path d="M7014 15056 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />,
    <path d="M2833 15034 c-5 -12 -14 -15 -44 -10 -42 7 -69 0 -69 -18 0 -14 42
-36 69 -36 10 0 37 -7 61 -15 30 -11 40 -11 36 -2 -3 6 -9 20 -12 30 -4 9 -10
17 -14 17 -4 0 -5 11 -2 25 5 28 -15 35 -25 9z"/>,
    <path d="M2967 14994 c-4 -4 -18 -7 -32 -8 -41 -2 -34 -20 10 -27 22 -3 50 -9
63 -13 28 -9 29 7 2 34 -21 21 -32 24 -43 14z"/>,
    <path d="M5482 14928 c-16 -16 3 -38 34 -38 17 0 24 6 24 19 0 28 -37 40 -58
19z"/>,
    <path d="M6247 14864 c-13 -13 5 -24 39 -24 24 0 34 5 34 15 0 10 -10 15 -33
15 -19 0 -37 -3 -40 -6z"/>,
    <path d="M5000 14835 c0 -28 24 -34 34 -9 10 26 7 34 -14 34 -15 0 -20 -7 -20
-25z"/>,
    <path d="M5566 14841 c-10 -16 5 -41 25 -41 11 0 22 7 26 16 9 24 -38 47 -51
25z"/>,
    <path d="M2073 14793 c-28 -19 -32 -70 -6 -80 21 -8 63 40 63 73 0 28 -23 31
-57 7z"/>,
    <path d="M4960 14769 c-32 -19 -84 -86 -102 -131 -4 -9 -21 -24 -37 -33 -18
-9 -31 -24 -31 -34 0 -10 -9 -26 -21 -37 -25 -22 -69 -106 -69 -131 -1 -10
-12 -36 -25 -58 -47 -77 -56 -96 -50 -105 3 -5 -6 -34 -20 -63 -14 -30 -25
-66 -25 -81 0 -15 -7 -37 -15 -50 -9 -13 -20 -46 -25 -72 -5 -27 -13 -67 -19
-89 -15 -58 -13 -106 6 -140 9 -16 17 -51 18 -78 1 -35 10 -58 33 -91 19 -27
30 -51 26 -60 -5 -13 -2 -15 12 -9 17 6 17 5 6 -9 -10 -13 -11 -22 -2 -37 14
-28 44 -28 35 -1 -4 14 -1 22 10 26 11 5 30 -9 61 -41 30 -31 51 -45 61 -41
14 6 14 8 -1 25 -9 10 -14 25 -11 34 4 9 -3 22 -20 34 -15 10 -43 33 -62 51
-29 27 -33 37 -28 62 5 23 1 33 -17 49 -31 27 -28 96 6 112 11 6 35 25 51 43
78 80 178 149 232 161 25 5 41 19 69 60 48 73 139 155 208 189 31 15 76 44 99
65 36 30 59 40 147 60 l104 24 23 -27 c13 -15 23 -37 23 -49 0 -16 3 -18 12
-9 7 7 20 12 30 12 24 0 23 17 -2 25 -28 9 -25 33 10 63 55 49 34 86 -31 53
-48 -25 -80 -26 -126 -4 -35 16 -36 16 -55 -10 -12 -17 -33 -29 -56 -33 -58
-10 -135 -36 -153 -50 -8 -8 -23 -14 -31 -14 -9 0 -21 -7 -28 -15 -7 -8 -19
-15 -28 -15 -8 0 -36 -13 -61 -28 -25 -16 -53 -31 -61 -35 -8 -4 -28 -16 -45
-27 -37 -25 -72 -40 -90 -40 -13 0 -49 -59 -41 -67 2 -3 22 7 45 21 22 14 45
26 50 26 10 0 120 65 135 80 13 13 46 13 46 0 0 -12 -41 -50 -53 -50 -5 0 -37
-28 -70 -62 -50 -50 -66 -61 -91 -60 -30 1 -65 -23 -142 -98 -12 -11 -26 -20
-33 -20 -6 0 -11 -6 -11 -14 0 -8 -14 -26 -32 -39 -28 -22 -30 -27 -18 -42 12
-15 12 -16 -2 -13 -10 1 -23 -6 -31 -16 -20 -27 -52 -14 -42 17 4 12 7 39 6
61 -1 21 3 44 9 51 7 9 6 21 -4 40 -19 37 -6 135 18 135 9 0 16 -20 20 -62 4
-35 15 -71 24 -81 13 -15 14 -20 3 -27 -9 -5 -11 -15 -7 -26 7 -18 8 -18 20
-1 7 9 18 17 25 17 6 0 11 11 11 25 0 16 -6 25 -16 25 -12 0 -15 7 -11 27 3
18 0 31 -11 39 -15 11 -15 17 -3 51 15 41 48 83 66 83 6 0 22 23 34 50 13 28
38 68 56 90 18 22 35 43 37 48 3 8 95 72 103 72 9 0 110 64 113 72 2 4 10 8
18 8 18 0 18 19 1 26 -18 7 -76 -20 -111 -50 -16 -15 -33 -26 -38 -26 -15 0
-89 -54 -131 -94 -34 -34 -57 -40 -57 -15 0 6 9 30 20 55 16 35 26 44 45 44
19 0 26 7 31 28 12 57 16 62 38 62 13 0 31 12 44 30 13 17 29 30 37 30 46 0
90 49 66 73 -8 8 -23 5 -56 -13 -34 -19 -47 -22 -56 -13 -9 9 -8 18 5 39 15
21 23 25 47 20 24 -4 29 -2 29 13 0 10 -8 25 -18 34 -16 14 -20 14 -52 -4z
m-212 -472 c4 -20 -27 -69 -39 -61 -22 13 1 89 24 81 7 -2 13 -11 15 -20z
m512 -15 c0 -9 -6 -12 -16 -8 -9 3 -30 -5 -49 -20 -23 -18 -35 -22 -41 -13 -4
6 0 16 10 21 9 5 16 15 16 23 0 10 12 13 40 12 27 -1 40 -6 40 -15z"/>,
    <path d="M6983 14744 c-8 -20 9 -36 30 -28 21 8 22 30 1 38 -22 8 -24 8 -31
-10z"/>,
    <path d="M2363 14733 c-4 -10 -24 -36 -45 -58 -21 -22 -38 -50 -38 -62 0 -13
2 -23 5 -23 12 0 46 43 60 76 8 21 24 44 35 51 11 8 18 19 15 24 -10 15 -25
11 -32 -8z"/>,
    <path d="M5814 14706 c-3 -8 -4 -27 -2 -43 2 -22 9 -29 30 -31 30 -4 44 23 34
64 -5 17 -13 24 -32 24 -13 0 -27 -6 -30 -14z"/>,
    <path d="M6397 14594 c-11 -12 3 -24 29 -24 16 0 24 5 22 13 -5 13 -40 22 -51
11z"/>,
    <path d="M5190 14575 c-7 -9 -10 -18 -7 -21 10 -11 37 5 37 21 0 19 -14 19
-30 0z"/>,
    <path d="M2208 14563 c-10 -2 -18 -9 -18 -14 0 -5 -4 -9 -9 -9 -5 0 -41 -32
-80 -71 -61 -61 -71 -76 -71 -106 0 -27 4 -34 17 -31 9 2 20 13 23 25 3 12 19
32 35 44 42 33 54 50 55 78 0 17 4 22 16 17 10 -4 30 4 50 19 52 40 43 64 -18
48z"/>,
    <path d="M1912 14303 c-62 -69 -94 -128 -86 -159 4 -14 -1 -28 -13 -40 -61
-60 -85 -114 -49 -114 14 0 98 118 120 170 8 19 40 62 71 95 58 63 68 97 29
103 -14 2 -36 -14 -72 -55z"/>,
    <path d="M5886 14345 c-11 -8 -27 -15 -36 -15 -38 0 -65 -61 -39 -87 9 -9 19
-5 39 15 14 15 43 40 63 55 37 28 41 47 9 47 -9 0 -26 -7 -36 -15z"/>,
    <path d="M6072 14338 c-19 -19 -14 -28 17 -28 29 0 57 21 44 33 -11 11 -48 8
-61 -5z"/>,
    <path d="M5666 14251 c-3 -22 -3 -46 0 -55 8 -22 39 -29 65 -16 18 10 20 18
15 53 -9 53 -12 57 -45 57 -25 0 -29 -4 -35 -39z"/>,
    <path d="M6070 14262 c-8 -3 -18 -9 -21 -14 -3 -5 -37 -10 -74 -11 -44 -1 -70
-6 -73 -14 -2 -8 5 -13 17 -13 11 0 28 -11 36 -24 14 -21 20 -23 53 -16 188
41 311 31 445 -36 38 -18 87 -18 87 0 0 8 -6 16 -12 19 -7 2 -10 8 -6 12 11
11 94 -24 119 -51 13 -14 34 -24 51 -24 23 0 28 4 28 25 0 15 -9 30 -22 38
-28 17 -119 52 -173 65 -22 6 -67 19 -100 28 -44 13 -98 19 -200 20 -77 0
-147 -1 -155 -4z"/>,
    <path d="M3491 14242 c-14 -26 7 -92 29 -92 40 0 55 63 22 93 -24 22 -39 21
-51 -1z"/>,
    <path d="M5844 14205 c-3 -7 1 -27 9 -42 8 -15 25 -50 38 -77 14 -29 32 -50
42 -52 23 -3 19 2 46 -56 21 -44 22 -53 12 -137 -5 -50 -15 -100 -21 -111 -13
-25 1 -40 57 -66 42 -18 46 -18 32 5 -10 15 -8 22 10 40 25 25 31 26 31 6 0
-8 -4 -15 -10 -15 -20 0 -10 -27 15 -40 30 -16 63 -73 37 -63 -8 4 -21 -2 -29
-13 -14 -19 -15 -19 -29 2 -11 15 -22 19 -49 16 -21 -3 -35 -10 -35 -18 0 -7
-7 -17 -17 -22 -10 -6 -14 -16 -9 -28 6 -16 9 -15 32 11 l24 30 16 -22 c19
-27 36 -29 54 -8 9 11 17 12 33 3 19 -10 19 -12 4 -23 -23 -17 -21 -32 5 -38
30 -8 41 1 34 29 -5 17 -1 26 14 34 26 14 26 36 0 50 -15 8 -20 21 -20 56 0
24 -6 55 -14 67 -7 12 -17 41 -20 65 -4 24 -10 51 -15 60 -5 9 -16 34 -25 55
-8 20 -20 37 -25 37 -5 0 -16 18 -25 40 -9 23 -23 43 -32 47 -8 3 -20 16 -25
29 -5 14 -17 24 -29 24 -11 0 -20 5 -20 11 0 6 -9 22 -20 36 -11 14 -20 36
-20 48 0 27 -47 53 -56 30z"/>,
    <path d="M3230 14175 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"/>,
    <path d="M2485 14169 c-4 -12 -16 -30 -26 -40 -15 -15 -17 -24 -9 -39 6 -11
16 -20 23 -20 15 1 61 96 52 110 -11 17 -33 11 -40 -11z"/>,
    <path d="M5323 14103 c-7 -2 -13 -14 -13 -26 l0 -21 20 22 c28 30 26 39 -7 25z" />,
    <path d="M6122 14083 c2 -10 13 -19 26 -21 12 -2 22 -9 22 -16 0 -15 46 -58
80 -74 77 -37 103 -58 223 -180 50 -51 97 -123 97 -149 0 -22 23 -63 36 -63 7
0 14 -12 16 -27 2 -20 9 -28 23 -28 23 0 23 33 -1 51 -18 14 -18 24 0 24 8 0
23 -16 35 -35 11 -19 31 -39 45 -45 13 -5 27 -17 29 -27 3 -10 3 6 1 36 -5 63
-39 142 -86 197 -77 90 -146 156 -197 187 -29 17 -67 44 -85 58 -17 14 -53 33
-79 43 -27 10 -51 24 -54 32 -3 9 -20 14 -44 14 -28 0 -41 5 -49 20 -13 25
-43 27 -38 3z"/>,
    <path d="M6570 14090 c0 -6 17 -22 38 -37 39 -29 147 -148 172 -192 9 -14 30
-40 48 -58 17 -17 32 -41 32 -53 0 -12 5 -20 11 -18 6 2 10 7 10 11 -1 4 -1
16 -1 26 0 11 -17 37 -37 58 -20 21 -37 45 -38 53 -1 8 -2 22 -3 31 -3 30 -62
92 -115 122 -29 17 -63 38 -76 48 -26 21 -41 24 -41 9z"/>,
    <path d="M5722 14039 c-7 -10 -12 -25 -12 -33 0 -8 -11 -28 -25 -44 -27 -32
-33 -66 -13 -78 15 -9 48 32 48 60 0 11 7 30 15 40 16 21 20 62 7 70 -4 2 -13
-4 -20 -15z"/>,
    <path d="M4757 14032 c-23 -25 -21 -32 7 -32 17 0 25 7 28 25 6 30 -11 33 -35
7z"/>,
    <path d="M5523 14037 c-13 -5 -23 -16 -23 -24 0 -21 38 -15 45 8 9 26 5 29
-22 16z"/>,
    <path d="M1710 13967 c-35 -12 -35 -12 -38 -79 -3 -65 -4 -68 -28 -68 -13 0
-27 -7 -30 -15 -4 -9 -19 -15 -35 -15 -28 0 -29 -2 -29 -45 0 -33 -4 -45 -15
-45 -8 0 -19 -9 -25 -19 -13 -25 -13 -61 0 -61 6 0 10 7 10 15 0 9 8 19 18 22
24 10 51 44 52 66 0 18 36 56 53 57 5 0 22 12 38 27 23 22 29 37 29 70 0 34 5
45 28 61 36 28 20 44 -28 29z"/>,
    <path d="M5154 13955 c-14 -35 17 -56 60 -39 12 5 10 11 -13 30 -34 28 -40 29
-47 9z"/>,
    <path d="M5597 13963 c-15 -15 -7 -43 13 -43 21 0 24 8 14 34 -7 17 -16 20
-27 9z"/>,
    <path d="M5891 13869 c-23 -52 -25 -90 -5 -96 23 -8 58 127 36 141 -5 3 -19
-17 -31 -45z"/>,
    <path d="M4316 13864 c-31 -31 -13 -74 31 -74 21 0 23 4 19 38 -3 20 -5 40 -5
45 -2 13 -29 8 -45 -9z"/>,
    <path d="M6279 13852 c6 -12 15 -41 20 -64 5 -24 16 -63 24 -88 43 -124 77
-241 77 -260 0 -12 -5 -31 -11 -42 -9 -17 -8 -24 3 -32 10 -8 14 -22 11 -43
-4 -25 -1 -34 12 -40 23 -8 49 25 34 43 -5 7 -12 80 -14 164 -3 125 -7 156
-22 178 -9 15 -27 48 -39 73 -12 26 -29 51 -37 55 -8 5 -16 17 -18 28 -2 10
-15 26 -28 35 -24 15 -24 15 -12 -7z"/>,
    <path d="M4825 13824 c-27 -29 -30 -40 -15 -49 11 -7 70 47 70 64 0 20 -31 11
-55 -15z"/>,
    <path d="M4394 13799 c-15 -16 -16 -24 -6 -39 8 -14 9 -27 2 -45 -14 -36 -13
-36 16 -25 14 6 36 10 48 10 16 0 26 7 29 22 8 28 0 40 -23 33 -20 -6 -41 20
-33 42 8 21 -14 23 -33 2z"/>,
    <path d="M4985 13786 c-30 -30 -31 -47 -7 -69 24 -22 45 -21 66 2 21 23 13 67
-15 82 -15 8 -24 5 -44 -15z"/>,
    <path d="M5733 13785 c-3 -9 -3 -18 -1 -21 9 -9 38 8 38 22 0 19 -29 18 -37
-1z"/>,
    <path d="M5630 13770 c0 -5 -9 -10 -20 -10 -24 0 -27 -28 -4 -37 11 -4 22 1
30 12 16 22 18 45 4 45 -5 0 -10 -4 -10 -10z"/>,
    <path d="M3895 13739 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25
-2z"/>,
    <path d="M4747 13743 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23
13z"/>,
    <path d="M5675 13735 c-24 -11 -27 -15 -13 -20 26 -10 52 -2 56 18 4 20 -2 20
-43 2z"/>,
    <path d="M3762 13688 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>,
    <path d="M5082 13669 c4 -27 46 -45 55 -24 7 20 -14 45 -39 45 -14 0 -19 -6
-16 -21z"/>,
    <path d="M5935 13675 c-3 -8 -7 -21 -9 -29 -3 -8 -27 -27 -55 -43 -28 -15 -51
-31 -51 -35 0 -13 -45 -9 -84 7 -21 9 -36 11 -36 5 0 -5 -7 -10 -15 -10 -9 0
-18 -7 -22 -16 -4 -12 -14 -14 -41 -9 -27 5 -38 2 -53 -14 -11 -11 -19 -25
-19 -31 0 -15 46 -12 59 4 12 15 96 17 117 4 7 -5 10 1 6 17 -4 25 -4 25 24
10 16 -8 39 -15 51 -15 20 0 22 -4 17 -32 l-6 -33 21 25 c11 14 18 31 16 38
-3 8 7 27 22 42 48 49 83 97 83 114 0 20 -19 21 -25 1z"/>,
    <path d="M4931 13646 c-5 -13 -19 -26 -30 -29 -27 -7 -27 -27 -1 -34 14 -4 20
-12 18 -26 -2 -14 8 -28 31 -44 38 -26 47 -25 33 4 -21 41 -29 80 -20 103 10
26 4 50 -12 50 -6 0 -15 -11 -19 -24z"/>,
    <path d="M6822 13661 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z" />,
    <path d="M3710 13641 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z" />,
    <path d="M5240 13626 c0 -17 87 -96 106 -96 24 0 15 30 -11 42 -14 6 -25 15
-25 19 0 13 -42 49 -57 49 -7 0 -13 -6 -13 -14z"/>,
    <path d="M3396 13611 c-16 -17 -16 -20 0 -35 19 -20 19 -20 27 22 7 36 -2 41
-27 13z"/>,
    <path d="M1449 13590 c-9 -9 -19 -24 -21 -31 -4 -11 -30 -13 -120 -10 -137 3
-152 -4 -142 -72 3 -23 9 -54 14 -68 10 -33 -4 -49 -43 -49 -60 0 -67 -11 -67
-113 0 -105 13 -141 31 -89 11 33 16 36 43 26 20 -8 20 -24 2 -61 -8 -15 -19
-41 -25 -56 -6 -15 -15 -26 -20 -23 -14 9 -20 -30 -27 -171 -7 -131 -7 -133
-31 -133 -21 0 -23 -4 -23 -56 l0 -55 -32 3 -33 3 0 -80 0 -80 69 2 c38 1 82
7 98 14 24 10 29 17 26 43 -2 19 -9 32 -18 32 -8 1 -19 2 -24 3 -5 0 -3 8 4
16 11 13 10 20 -1 41 -10 19 -10 30 -2 44 8 11 10 39 6 75 -5 47 -3 57 8 53 8
-2 15 -17 17 -32 3 -22 6 -25 18 -15 9 7 29 9 52 6 20 -4 32 -3 26 1 -6 4 -21
24 -32 46 -17 31 -19 42 -9 58 10 16 9 21 -4 28 -23 13 -27 160 -4 160 8 0 20
9 27 20 16 25 34 26 41 1 3 -14 14 -19 48 -19 42 -1 43 0 24 15 -11 8 -32 19
-47 25 -16 5 -28 16 -28 24 0 8 -7 14 -15 14 -8 0 -15 -7 -15 -16 0 -9 -6 -14
-12 -11 -7 2 -13 9 -12 15 1 7 2 21 3 32 1 17 6 19 29 14 15 -4 29 -11 30 -16
2 -4 11 -8 21 -8 9 0 31 -13 48 -29 25 -23 45 -30 95 -35 70 -8 102 -1 94 20
-3 7 -1 16 4 19 18 11 10 30 -22 48 -22 12 -34 15 -42 7 -14 -14 -56 -13 -56
1 0 7 7 17 15 23 16 12 19 8 -77 104 -16 14 -28 35 -28 44 0 25 -26 23 -33 -2
-3 -11 -14 -20 -24 -20 -17 0 -19 8 -20 64 0 35 -3 68 -7 74 -11 18 33 34 70
27 34 -6 43 -18 24 -30 -6 -4 -8 -11 -5 -16 8 -12 55 20 64 45 5 11 23 25 42
31 19 7 39 22 45 34 7 13 17 28 23 34 6 6 11 15 11 19 0 15 -35 7 -51 -12z
m-113 -350 c15 0 24 -8 28 -26 16 -64 -57 -75 -110 -17 -39 42 -31 57 26 48
19 -2 45 -5 56 -5z"/>,
    <path d="M3549 13584 c-7 -14 -26 -36 -41 -49 -33 -28 -34 -29 -8 -55 26 -26
25 -34 -5 -48 -21 -10 -25 -19 -25 -52 0 -23 -5 -40 -11 -40 -7 0 -9 -10 -5
-25 7 -27 -18 -65 -42 -65 -12 0 -11 -5 2 -25 13 -19 14 -29 4 -50 -7 -17 -8
-32 -1 -47 9 -21 11 -19 43 28 28 40 35 59 37 109 1 33 12 85 24 115 12 30 24
69 26 87 3 18 11 38 19 44 8 6 14 21 14 33 0 12 5 27 11 33 6 6 8 16 5 22 -12
19 -32 12 -47 -15z"/>,
    <path d="M3762 13601 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />,
    <path d="M3163 13583 c-13 -8 -23 -24 -23 -33 0 -21 -28 -30 -51 -17 -16 10
-99 -19 -99 -34 0 -13 32 -19 69 -13 31 5 41 2 52 -13 15 -21 79 -39 79 -23 0
5 -5 12 -10 15 -6 4 -8 10 -5 15 4 6 14 4 25 -2 16 -10 24 -9 47 8 44 33 43
103 -2 104 -5 0 -11 -12 -12 -26 -1 -33 -15 -47 -36 -38 -25 9 -21 49 6 62 20
10 20 11 2 11 -11 0 -30 -7 -42 -16z"/>,
    <path d="M4477 13568 c3 -18 12 -41 21 -50 16 -20 9 -35 -35 -71 -25 -20 -59
-84 -50 -94 4 -3 9 2 13 11 3 9 12 16 20 16 8 0 14 9 14 20 0 12 19 38 42 60
34 31 40 42 30 52 -6 6 -12 23 -12 36 0 22 -23 51 -42 52 -4 0 -5 -15 -1 -32z"/>,
    <path d="M5454 13523 c-9 -10 -28 -16 -41 -15 -19 3 -23 -1 -23 -26 0 -28 -2
-29 -50 -30 -37 -1 -50 3 -50 14 0 8 -5 14 -11 14 -6 0 -9 -17 -7 -42 2 -35 7
-44 24 -46 11 -2 26 4 33 12 10 12 17 13 31 4 14 -9 25 -7 54 11 20 13 36 29
36 37 0 19 26 18 34 -1 3 -8 12 -15 21 -15 17 0 20 27 5 50 -7 11 -4 20 13 32
22 18 22 18 -15 18 -22 0 -44 -7 -54 -17z"/>,
    <path d="M3403 13508 c2 -7 10 -13 17 -13 7 0 15 6 18 13 2 7 -5 12 -18 12
-13 0 -20 -5 -17 -12z"/>,
    <path d="M5105 13508 c-9 -33 -12 -69 -4 -74 5 -3 9 4 9 15 0 11 7 24 17 29
10 6 14 16 10 26 -8 19 -26 21 -32 4z"/>,
    <path d="M2970 13471 c0 -5 6 -12 13 -15 8 -3 7 -8 -5 -16 -46 -29 12 -60 77
-42 35 10 36 26 1 30 -13 2 -36 14 -51 28 -28 26 -35 29 -35 15z"/>,
    <path d="M6353 13465 c-7 -20 2 -29 21 -22 9 4 13 13 10 22 -8 19 -23 19 -31
0z"/>,
    <path d="M6646 13464 c-3 -8 0 -25 8 -37 8 -12 14 -47 14 -79 0 -31 6 -66 12
-79 l13 -22 13 24 c8 14 13 56 14 102 0 63 -3 77 -15 77 -9 0 -18 7 -21 15 -8
20 -30 19 -38 -1z"/>,
    <path d="M5181 13456 c-12 -14 -5 -26 16 -26 15 0 18 26 4 34 -5 3 -14 0 -20
-8z"/>,
    <path d="M5596 13434 c3 -21 8 -41 10 -43 7 -8 24 12 24 30 0 14 7 19 25 19
14 0 25 4 25 10 0 5 -13 7 -30 4 -18 -4 -36 -1 -46 7 -14 11 -15 9 -8 -27z"/>,
    <path d="M3355 13440 c-10 -11 -24 -20 -31 -20 -41 0 -73 -107 -35 -117 16 -4
18 -11 14 -39 -4 -26 -2 -34 9 -34 18 0 36 37 44 93 8 59 24 107 35 107 5 0 9
7 9 15 0 21 -24 19 -45 -5z"/>,
    <path d="M2882 13438 c-18 -18 -15 -40 6 -36 9 2 18 13 20 26 4 24 -7 29 -26
10z"/>,
    <path d="M3635 13432 c5 -17 3 -21 -9 -16 -43 17 -40 -128 4 -188 17 -23 17
-28 5 -38 -8 -7 -12 -16 -9 -21 7 -12 24 -11 24 1 0 6 9 19 21 31 16 16 20 28
15 52 -3 18 -9 61 -12 97 -5 46 -12 71 -26 84 -20 19 -20 19 -13 -2z"/>,
    <path d="M4827 13432 c-13 -14 -14 -21 -5 -30 9 -9 16 -8 29 4 16 14 18 14 24
-7 5 -14 2 -24 -6 -30 -7 -4 -10 -11 -6 -16 5 -4 12 -1 17 6 5 9 15 11 26 7
18 -7 18 -8 1 -21 -10 -7 -17 -21 -15 -30 4 -23 22 -20 26 5 4 25 47 27 61 3
14 -26 26 -7 15 25 -6 16 -18 28 -32 30 -12 2 -28 13 -36 24 -13 19 -59 48
-76 48 -4 0 -14 -8 -23 -18z"/>,
    <path d="M5840 13410 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M6297 13396 c-7 -18 1 -26 25 -26 13 0 18 6 16 17 -4 22 -34 29 -41
9z"/>,
    <path d="M4464 13369 c-8 -14 11 -33 25 -25 6 4 11 11 11 16 0 13 -29 20 -36
9z"/>,
    <path d="M4745 13361 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z" />,
    <path d="M5715 13334 c-16 -39 -20 -74 -7 -74 4 0 23 14 42 30 32 28 34 31 18
44 -10 7 -18 18 -18 25 0 24 -20 9 -35 -25z"/>,
    <path d="M6114 13335 c-10 -24 5 -35 39 -28 42 8 46 12 23 29 -25 18 -55 18
-62 -1z"/>,
    <path d="M4537 13322 c-10 -10 -17 -24 -17 -30 0 -7 -11 -12 -25 -12 -14 0
-28 -7 -32 -16 -4 -10 -13 -14 -27 -10 -14 4 -41 -10 -88 -44 -38 -28 -75 -50
-82 -50 -8 0 -21 -6 -28 -12 -7 -7 -34 -30 -61 -51 -37 -30 -47 -45 -47 -68 0
-26 -3 -30 -24 -27 -19 2 -30 -6 -50 -35 -14 -21 -26 -42 -26 -47 0 -19 22
-21 41 -4 13 12 34 17 68 15 71 -2 103 12 99 45 -2 21 4 31 27 44 16 9 40 14
52 12 27 -5 33 25 8 34 -25 10 -17 31 15 38 17 3 30 12 30 19 0 21 44 57 68
57 16 0 22 6 22 23 0 21 57 77 78 77 13 0 43 46 35 54 -10 10 -19 7 -36 -12z"/>,
    <path d="M5922 13331 c-19 -11 -4 -39 29 -57 27 -13 29 -13 29 4 0 25 -42 63
-58 53z"/>,
    <path d="M6286 13331 c-7 -11 18 -33 27 -24 4 3 7 12 7 20 0 15 -26 18 -34 4z" />,
    <path d="M6020 13301 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z" />,
    <path d="M3830 13291 c-14 -27 -13 -41 3 -41 15 0 39 44 30 54 -11 11 -22 6
-33 -13z"/>,
    <path d="M3930 13298 c0 -7 9 -24 19 -38 14 -18 17 -29 10 -42 -10 -19 5 -26
16 -8 10 15 35 12 35 -5 0 -8 7 -15 15 -15 8 0 15 -9 15 -20 0 -11 5 -20 11
-20 7 0 22 -11 35 -25 26 -29 34 -31 34 -11 0 16 -56 76 -71 76 -5 0 -9 3 -9
8 0 11 -90 112 -101 112 -5 0 -9 -6 -9 -12z"/>,
    <path d="M1885 13289 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25
-2z"/>,
    <path d="M4755 13281 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33
14z"/>,
    <path d="M5038 13274 c-5 -4 -8 -16 -8 -26 0 -11 -7 -18 -18 -18 -10 0 -22 -3
-25 -7 -9 -9 43 -63 60 -63 18 0 16 19 -2 26 -17 7 -20 41 -4 46 6 2 9 14 7
27 -1 12 -6 19 -10 15z"/>,
    <path d="M6232 13263 c24 -50 34 -62 55 -66 18 -3 23 0 21 12 -2 9 -13 17 -25
19 -15 2 -19 8 -16 23 4 14 0 19 -17 19 -12 0 -20 -3 -18 -7z"/>,
    <path d="M6867 13263 c-15 -15 -4 -133 12 -133 16 0 29 71 20 105 -10 34 -19
42 -32 28z"/>,
    <path d="M4781 13215 c-13 -14 -18 -25 -13 -25 13 0 42 28 42 41 0 13 -2 12
-29 -16z"/>,
    <path d="M6080 13220 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 -9 -10 -20z"/>,
    <path d="M4883 13213 c-4 -10 -12 -22 -20 -26 -18 -11 -17 -27 2 -27 8 0 15 7
15 15 0 8 7 15 15 15 8 0 15 9 15 20 0 25 -18 26 -27 3z"/>,
    <path d="M6640 13192 c0 -21 5 -43 12 -50 9 -9 8 -20 -1 -49 -9 -29 -10 -45
-1 -67 7 -20 8 -32 0 -40 -16 -16 -12 -44 10 -66 20 -20 20 -20 -6 -20 -14 0
-37 -9 -51 -20 -14 -11 -30 -20 -34 -20 -15 0 -10 28 6 34 26 9 16 27 -12 24
-22 -2 -29 -9 -31 -29 -2 -21 -11 -29 -42 -38 -27 -8 -43 -21 -54 -42 -13 -26
-13 -29 -1 -19 16 13 35 4 63 -29 10 -13 16 -13 32 -3 11 6 28 12 37 12 10 0
31 18 47 39 26 36 31 39 55 30 14 -6 34 -18 43 -26 15 -14 18 -14 18 -2 0 8
-5 20 -11 26 -8 8 -13 74 -14 195 -1 101 -3 186 -4 191 0 4 -14 7 -31 7 -28 0
-30 -2 -30 -38z"/>,
    <path d="M5630 13174 c-6 -14 -10 -34 -10 -46 0 -14 -5 -18 -16 -14 -28 11
-64 -23 -64 -60 0 -27 4 -34 20 -34 13 0 22 9 26 25 3 13 20 33 38 44 41 26
58 55 50 86 -8 32 -32 32 -44 -1z"/>,
    <path d="M5114 13175 c-3 -8 -14 -17 -25 -20 -25 -8 -24 -31 1 -45 11 -6 20
-17 20 -25 0 -31 51 -25 74 10 15 23 15 23 -24 18 -31 -4 -40 -2 -40 9 0 9 7
21 15 28 8 7 15 19 15 26 0 19 -29 18 -36 -1z"/>,
    <path d="M2891 13166 c-18 -21 14 -66 45 -66 30 0 30 12 2 50 -25 34 -31 36
-47 16z"/>,
    <path d="M3973 13153 c-15 -30 -10 -41 10 -25 18 15 27 5 27 -30 0 -19 4 -28
13 -26 18 7 20 53 3 71 -9 9 -16 20 -16 26 0 21 -25 10 -37 -16z"/>,
    <path d="M4740 13162 c0 -10 6 -24 13 -31 10 -10 8 -15 -7 -27 -14 -9 -17 -17
-10 -24 7 -7 18 -2 35 16 22 23 23 26 6 35 -9 6 -17 19 -17 29 0 11 -4 20 -10
20 -5 0 -10 -8 -10 -18z"/>,
    <path d="M7214 13166 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41
16z"/>,
    <path d="M6330 13156 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />,
    <path d="M3594 13125 c-3 -8 1 -22 10 -30 9 -9 16 -26 16 -37 0 -18 4 -19 38
-14 61 11 72 17 72 34 -1 15 -2 15 -15 -2 -16 -21 -42 -16 -52 11 -6 19 -42
52 -56 53 -4 0 -10 -7 -13 -15z"/>,
    <path d="M4850 13120 c0 -5 -7 -10 -15 -10 -9 0 -15 -9 -15 -25 0 -33 23 -32
38 0 15 32 15 45 2 45 -5 0 -10 -4 -10 -10z"/>,
    <path d="M2484 13099 c-10 -17 11 -33 32 -21 16 9 16 10 -4 21 -15 8 -23 8
-28 0z"/>,
    <path d="M6368 13102 c-13 -2 -18 -13 -18 -34 0 -28 2 -30 29 -25 15 3 33 1
40 -6 8 -8 11 -6 11 11 0 15 -6 22 -19 22 -11 0 -21 8 -23 18 -2 11 -10 16
-20 14z"/>,
    <path d="M6897 13103 c-4 -3 -7 -15 -7 -27 0 -11 -19 -42 -42 -69 -39 -47 -41
-50 -23 -63 10 -8 27 -14 37 -14 24 0 23 -27 -1 -35 -21 -6 -34 -31 -25 -47 3
-5 12 -7 20 -4 8 3 26 0 39 -6 20 -9 28 -9 38 1 9 10 8 14 -6 23 -18 9 -18 10
0 23 17 13 17 15 -6 39 -20 21 -22 28 -11 41 7 8 15 44 18 80 4 53 2 65 -10
65 -8 0 -18 -3 -21 -7z"/>,
    <path d="M4375 13090 c-4 -6 5 -22 19 -35 14 -13 26 -29 26 -35 0 -5 7 -10 15
-10 24 0 17 29 -10 42 -14 6 -25 17 -25 24 0 18 -17 27 -25 14z"/>,
    <path d="M4650 13082 c0 -10 22 -32 32 -32 5 0 3 9 -4 20 -13 20 -28 27 -28
12z"/>,
    <path d="M6180 13080 c0 -7 -7 -21 -16 -31 -19 -21 -6 -82 19 -87 14 -3 16 5
14 57 -1 33 -6 63 -10 67 -4 4 -7 1 -7 -6z"/>,
    <path d="M5185 13059 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />,
    <path d="M3943 13043 c-20 -8 -15 -33 6 -33 19 0 35 23 24 34 -7 7 -13 7 -30
-1z"/>,
    <path d="M4603 13028 c2 -7 10 -13 17 -13 7 0 15 6 18 13 2 7 -5 12 -18 12
-13 0 -20 -5 -17 -12z"/>,
    <path d="M2803 13019 c-7 -7 -13 -21 -13 -31 0 -15 3 -16 26 -6 20 9 30 9 45
0 11 -7 24 -10 29 -7 18 11 10 33 -12 38 -13 3 -32 8 -43 12 -11 3 -26 0 -32
-6z"/>,
    <path d="M5247 13009 c-23 -13 -13 -29 19 -29 17 0 24 5 24 20 0 22 -15 25
-43 9z"/>,
    <path d="M5454 12995 c-21 -20 -25 -28 -16 -37 17 -17 15 -38 -3 -38 -8 0 -15
-7 -15 -15 0 -8 7 -15 15 -15 8 0 15 5 15 11 0 7 8 17 17 24 14 11 15 16 4 28
-10 13 -9 17 8 26 11 6 27 8 35 5 19 -7 21 7 4 24 -18 18 -34 15 -64 -13z"/>,
    <path d="M4405 12950 c-8 -13 3 -30 20 -30 23 0 27 18 6 30 -13 7 -22 7 -26 0z" />,
    <path d="M5270 12946 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />,
    <path d="M1400 12925 c-52 -38 -45 -49 33 -52 64 -2 67 -1 67 21 0 15 -9 28
-27 37 -16 7 -30 15 -33 16 -3 2 -21 -8 -40 -22z"/>,
    <path d="M3588 12944 c-6 -5 0 -77 8 -103 4 -11 9 -9 21 9 l16 23 18 -24 c14
-19 20 -21 30 -12 10 10 9 17 -7 31 -10 10 -27 21 -36 25 -11 4 -18 17 -18 32
0 23 -18 34 -32 19z"/>,
    <path d="M4704 12939 c-8 -13 3 -24 33 -33 20 -6 21 -5 10 18 -13 27 -31 34
-43 15z"/>,
    <path d="M6089 12908 c-18 -24 -47 -55 -64 -70 -28 -25 -28 -27 -8 -28 31 0
53 10 53 25 0 7 7 18 15 25 8 7 15 21 15 32 0 11 9 22 20 25 21 6 26 18 11 27
-5 4 -24 -13 -42 -36z"/>,
    <path d="M3226 12888 c-4 -24 -8 -57 -10 -74 -2 -17 -6 -36 -10 -43 -4 -6 -1
-28 6 -48 10 -29 10 -37 1 -40 -15 -5 -18 -53 -3 -53 6 0 10 -9 10 -20 0 -25
-18 -26 -36 -2 -12 15 -13 15 -14 0 0 -9 5 -18 11 -20 9 -3 9 -9 1 -24 -6 -11
-7 -26 -2 -34 5 -7 3 -21 -3 -31 -9 -15 -6 -23 16 -47 27 -29 27 -29 7 -47
-11 -10 -20 -26 -20 -37 0 -10 -7 -18 -15 -18 -7 0 -18 -8 -24 -17 -9 -17 -11
-17 -39 0 -40 23 -52 22 -52 -7 0 -18 8 -25 33 -32 17 -6 32 -16 32 -24 0 -10
-12 -16 -32 -18 -21 -2 -33 -9 -33 -18 0 -18 -21 -18 -46 1 -27 21 -47 19 -39
-4 7 -22 -7 -34 -59 -49 -24 -7 -47 -7 -62 -2 -14 6 -26 6 -29 0 -9 -15 -47
-12 -66 6 -17 15 -19 15 -34 0 -20 -20 -34 -20 -56 -1 -15 14 -18 14 -32 0 -9
-8 -24 -15 -34 -15 -10 0 -49 -6 -87 -14 l-69 -15 -34 26 c-29 22 -38 25 -54
15 -10 -7 -30 -12 -44 -12 -18 0 -28 -7 -35 -25 -11 -30 -30 -33 -30 -6 0 29
-21 37 -37 15 -11 -16 -18 -17 -43 -7 -24 9 -33 8 -44 -3 -19 -18 -50 -18 -66
0 -9 12 -17 13 -37 3 -19 -8 -29 -8 -44 2 -11 6 -29 9 -40 6 -14 -5 -21 0 -28
17 -8 20 -10 21 -16 6 -4 -10 -15 -18 -24 -18 -9 0 -26 -7 -38 -15 -18 -13
-22 -13 -34 1 -9 11 -22 14 -46 9 -31 -5 -48 8 -21 17 6 2 8 10 4 16 -8 14
-36 16 -36 3 0 -17 -30 -20 -46 -4 -13 13 -20 14 -35 5 -10 -7 -30 -12 -43
-12 -14 0 -36 -4 -49 -9 -17 -7 -33 -5 -53 5 -52 27 -54 54 -3 54 37 0 38 16
0 23 -22 5 -31 13 -36 37 -8 38 -24 40 -22 3 3 -47 -34 -42 -78 9 -53 61 -64
87 -42 103 9 7 17 17 17 23 0 6 9 12 20 14 27 4 27 22 0 26 -11 2 -20 -1 -20
-7 0 -6 -9 -11 -20 -11 -15 0 -20 7 -20 25 0 14 5 25 10 25 19 0 10 18 -12 23
-14 4 -26 1 -31 -8 -4 -8 -21 -15 -37 -15 l-29 0 2 -107 2 -108 42 -19 c25
-12 44 -29 47 -42 6 -23 65 -74 130 -114 34 -20 57 -24 180 -31 78 -4 166 -14
196 -23 43 -13 107 -16 319 -16 146 0 301 4 345 10 45 5 124 15 176 21 92 11
166 16 338 19 50 1 85 7 90 14 20 32 63 46 137 46 87 0 141 20 130 48 -25 62
-31 107 -27 222 3 105 4 189 3 270 0 8 3 53 7 100 8 92 -2 140 -29 140 -12 0
-19 -12 -23 -42z"/>,
    <path d="M5314 12909 c-10 -16 6 -26 54 -34 28 -4 42 -3 42 4 0 6 -4 11 -10
11 -5 0 -10 7 -10 15 0 18 -65 21 -76 4z"/>,
    <path d="M1525 12890 c-11 -18 3 -26 39 -22 41 5 47 17 14 26 -33 8 -46 7 -53
-4z"/>,
    <path d="M4007 12883 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />,
    <path d="M4242 12848 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>,
    <path d="M4581 12825 c-17 -20 -31 -40 -31 -44 0 -5 -9 -11 -20 -14 -17 -4
-20 -10 -14 -34 4 -15 12 -32 18 -36 6 -5 -1 -6 -16 -3 -22 5 -26 3 -22 -9 4
-8 1 -22 -6 -30 -11 -14 -15 -13 -32 5 -10 11 -22 20 -25 20 -13 0 -73 -70
-73 -86 0 -13 6 -15 28 -9 15 4 42 8 60 9 25 1 44 13 82 53 55 57 58 67 33 96
-15 16 -15 21 -4 25 8 2 27 17 43 33 17 15 35 30 41 32 14 4 0 27 -18 27 -7 0
-27 -16 -44 -35z"/>,
    <path d="M4042 12838 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>,
    <path d="M1735 12810 c-3 -5 -25 -10 -49 -10 -23 0 -47 -5 -52 -10 -17 -17
-175 -21 -199 -6 -32 21 -107 28 -128 12 -9 -7 -15 -20 -12 -28 3 -8 -1 -21
-10 -28 -21 -17 -19 -39 7 -80 12 -19 23 -55 24 -79 4 -52 32 -88 61 -76 16 6
15 8 -9 17 -41 14 -47 47 -12 75 27 21 140 63 170 63 7 0 34 18 59 40 25 22
53 40 62 40 9 0 30 4 47 9 96 29 110 31 128 14 13 -12 21 -13 31 -5 14 12 37
-1 37 -20 0 -5 9 -18 20 -30 18 -20 20 -20 26 -3 5 11 1 21 -9 27 -28 15 -21
46 10 50 37 4 45 38 10 38 -14 0 -28 -7 -31 -15 -5 -14 -8 -14 -29 0 -19 12
-30 13 -55 4 -22 -7 -36 -7 -51 0 -25 14 -38 14 -46 1z m-297 -112 c-7 -19
-38 -22 -38 -4 0 10 9 16 21 16 12 0 19 -5 17 -12z"/>,
    <path d="M6149 12801 c8 -15 5 -20 -15 -30 -32 -14 -27 -35 9 -39 22 -3 27 1
27 17 0 12 7 21 15 21 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 5 -15 10 0 6
-7 10 -15 10 -12 0 -13 -4 -6 -19z"/>,
    <path d="M3582 12794 c-22 -15 -32 -74 -12 -74 16 0 12 -28 -6 -38 -14 -8 -14
-12 -3 -30 14 -23 43 -29 54 -12 3 6 17 10 31 10 13 0 24 2 24 5 0 10 -25 50
-38 60 -8 7 -12 26 -10 49 3 44 -8 53 -40 30z"/>,
    <path d="M3897 12803 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />,
    <path d="M4070 12790 c0 -13 7 -20 19 -20 30 0 33 16 6 28 -22 10 -25 9 -25
-8z"/>,
    <path d="M4374 12796 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />,
    <path d="M6948 12783 c-7 -15 -24 -33 -38 -40 -74 -38 -159 -73 -178 -73 -11
0 -24 -5 -28 -11 -4 -7 -24 -9 -58 -5 -126 16 -176 17 -176 3 0 -22 151 -60
200 -51 21 5 30 3 30 -6 0 -24 80 -4 114 28 17 16 47 33 68 36 35 6 37 5 48
-29 7 -20 9 -42 6 -50 -6 -17 2 -19 23 -6 10 6 12 21 8 52 -4 31 0 53 14 80
14 29 17 44 10 63 -14 36 -28 39 -43 9z"/>,
    <path d="M2005 12790 c-3 -6 1 -12 9 -15 9 -3 23 -7 31 -10 8 -2 15 -11 15
-20 0 -8 -7 -15 -15 -15 -9 0 -15 -9 -15 -25 0 -16 6 -25 15 -25 9 0 15 9 15
24 0 16 7 27 20 31 11 3 20 13 20 21 0 8 -9 14 -20 14 -11 0 -20 7 -20 15 0
17 -45 21 -55 5z"/>,
    <path d="M5390 12785 c0 -9 9 -15 25 -15 16 0 25 6 25 15 0 9 -9 15 -25 15
-16 0 -25 -6 -25 -15z"/>,
    <path d="M1977 12763 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />,
    <path d="M4325 12760 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>,
    <path d="M5545 12760 c-7 -11 -1 -23 29 -57 22 -26 38 -28 65 -11 15 9 26 9
45 0 22 -10 27 -8 41 11 27 38 20 44 -51 41 -49 -2 -67 1 -71 12 -6 16 -49 19
-58 4z"/>,
    <path d="M6017 12764 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21
-31 12z"/>,
    <path d="M2722 12739 c1 -13 8 -23 13 -21 15 3 12 35 -3 40 -9 3 -13 -3 -10
-19z"/>,
    <path d="M6199 12744 c-18 -22 3 -32 60 -28 25 2 50 3 54 4 5 0 7 7 5 16 -4
22 -102 29 -119 8z"/>,
    <path d="M5327 12729 c-17 -10 -24 -39 -9 -39 8 0 35 40 30 45 -2 2 -11 -1
-21 -6z"/>,
    <path d="M5400 12706 c0 -22 3 -27 11 -19 8 8 8 16 0 30 -9 16 -10 14 -11 -11z" />,
    <path d="M5783 12704 c-3 -8 -4 -16 -2 -17 2 -1 19 -10 37 -20 39 -22 72 -15
72 16 -1 20 -2 20 -11 5 -13 -23 -36 -23 -48 1 -14 26 -40 34 -48 15z"/>,
    <path d="M2740 12671 c0 -11 26 -22 34 -14 12 12 5 23 -14 23 -11 0 -20 -4
-20 -9z"/>,
    <path d="M4903 12673 c-18 -6 -16 -23 1 -23 17 0 30 16 19 23 -5 2 -14 2 -20
0z"/>,
    <path d="M5275 12670 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />,
    <path d="M1970 12649 c-18 -32 -6 -45 18 -21 12 12 22 27 22 32 0 18 -29 10
-40 -11z"/>,
    <path d="M4227 12663 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23
13z"/>,
    <path d="M4707 12664 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />,
    <path d="M4320 12645 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M5216 12632 c-8 -13 3 -42 15 -42 11 0 11 21 0 39 -5 8 -11 9 -15 3z" />,
    <path d="M5569 12626 c7 -8 11 -21 9 -30 -3 -15 6 -18 30 -9 29 11 -1 53 -39
53 -9 0 -9 -3 0 -14z"/>,
    <path d="M5970 12625 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M6026 12631 c-3 -4 1 -16 8 -27 11 -15 18 -16 37 -7 20 8 29 6 55
-13 23 -18 46 -24 95 -27 35 -1 70 -6 77 -10 24 -16 25 22 1 42 -22 18 -26 18
-53 4 -26 -14 -31 -14 -41 0 -21 29 -166 60 -179 38z"/>,
    <path d="M1655 12619 c-4 -6 -3 -15 3 -21 6 -6 11 -4 15 6 7 18 -9 31 -18 15z" />,
    <path d="M4920 12610 c0 -13 7 -20 20 -20 11 0 20 4 20 10 0 5 -9 14 -20 20
-17 9 -20 8 -20 -10z"/>,
    <path d="M3530 12609 c0 -7 8 -18 18 -25 16 -12 16 -14 -2 -27 -15 -11 -18
-22 -13 -50 4 -25 1 -37 -8 -41 -12 -4 -11 -10 1 -35 18 -36 30 -39 37 -11 3
11 12 20 20 20 21 0 29 41 11 61 -13 14 -13 18 1 29 22 18 19 57 -5 65 -11 3
-20 11 -20 16 0 5 -9 9 -20 9 -11 0 -20 -5 -20 -11z"/>,
    <path d="M3620 12605 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M3712 12611 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />,
    <path d="M2544 12569 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z" />,
    <path d="M4803 12565 c-3 -9 -3 -19 1 -22 7 -7 36 16 36 28 0 15 -30 10 -37
-6z"/>,
    <path d="M4890 12560 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 -9 -10 -20z"/>,
    <path d="M4343 12554 c-4 -10 -2 -19 5 -22 9 -3 11 -16 6 -40 l-6 -37 26 30
c21 25 23 32 10 41 -7 6 -14 16 -14 22 0 19 -20 24 -27 6z"/>,
    <path d="M1861 12521 c-16 -15 -31 -36 -35 -49 -4 -12 -22 -30 -39 -39 -31
-16 -32 -16 -9 -26 30 -14 28 -21 -10 -32 -28 -9 -33 -15 -33 -42 0 -20 -7
-37 -19 -46 -12 -9 -17 -20 -13 -31 9 -22 29 -16 42 12 5 12 18 25 27 28 10 3
18 14 18 24 0 28 18 34 46 15 32 -21 43 -12 19 15 -17 19 -17 20 0 26 36 14
65 51 65 83 0 27 3 31 28 31 l27 0 -24 -19 c-14 -11 -20 -21 -13 -25 13 -8 44
4 59 23 17 22 16 32 -2 25 -9 -4 -15 0 -16 7 -1 8 -2 21 -3 29 -1 11 -14 15
-44 16 -34 1 -50 -5 -71 -25z"/>,
    <path d="M5602 12539 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>,
    <path d="M2115 12520 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />,
    <path d="M4655 12520 c-8 -12 20 -24 35 -15 5 3 10 10 10 16 0 12 -37 12 -45
-1z"/>,
    <path d="M4940 12515 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>,
    <path d="M6352 12519 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>,
    <path d="M6475 12510 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
-11 0 -17 -4 -14 -10z"/>,
    <path d="M6566 12511 c-7 -11 23 -60 36 -61 4 0 10 5 13 10 3 6 30 10 59 10
76 0 78 13 4 27 -34 6 -73 14 -84 18 -12 4 -25 2 -28 -4z"/>,
    <path d="M5274 12476 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />,
    <path d="M1410 12455 c0 -17 2 -18 24 -9 18 7 12 24 -10 24 -8 0 -14 -7 -14
-15z"/>,
    <path d="M1577 12443 c-18 -9 -25 -19 -20 -24 11 -11 43 -2 43 12 0 5 9 9 20
9 11 0 20 -6 20 -14 0 -22 16 -28 29 -12 10 11 6 17 -20 30 -37 18 -35 19 -72
-1z"/>,
    <path d="M1360 12425 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15
-11 0 -20 -7 -20 -15z"/>,
    <path d="M4370 12425 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>,
    <path d="M4655 12429 c-8 -12 14 -31 30 -26 6 2 10 10 10 18 0 17 -31 24 -40
8z"/>,
    <path d="M4750 12425 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>,
    <path d="M5216 12433 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />,
    <path d="M4570 12426 c0 -2 7 -9 15 -16 12 -10 15 -10 15 4 0 9 -7 16 -15 16
-8 0 -15 -2 -15 -4z"/>,
    <path d="M1427 12399 c-9 -6 -17 -19 -17 -31 0 -19 2 -19 21 6 24 30 22 39 -4
25z"/>,
    <path d="M3563 12384 c-3 -8 -1 -20 5 -26 16 -16 42 -2 42 22 0 24 -38 28 -47
4z"/>,
    <path d="M4612 12386 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"/>,
    <path d="M6680 12386 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21
-5 0 -10 -6 -10 -14z"/>,
    <path d="M4320 12365 c0 -18 5 -25 20 -25 11 0 20 -7 20 -15 0 -8 6 -15 14
-15 26 0 29 21 7 41 -12 11 -21 24 -21 29 0 6 -9 10 -20 10 -15 0 -20 -7 -20
-25z"/>,
    <path d="M5180 12370 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18
-8 2 -13 -5 -13 -18z"/>,
    <path d="M6590 12375 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M3482 12368 c7 -20 48 -23 48 -4 0 11 -9 16 -26 16 -16 0 -24 -5 -22
-12z"/>,
    <path d="M6758 12348 c-26 -9 -40 -38 -18 -38 17 0 11 -40 -7 -47 -10 -4 -23
-14 -30 -22 -9 -13 -14 -13 -24 -4 -10 10 -16 9 -31 -4 -10 -9 -18 -22 -18
-30 0 -7 -7 -13 -15 -13 -8 0 -15 -6 -15 -14 0 -8 -10 -16 -22 -18 -13 -2 -23
-10 -24 -18 -1 -8 -2 -21 -2 -27 -1 -7 -15 -13 -32 -13 -16 0 -33 -6 -37 -13
-11 -18 -60 -38 -77 -31 -8 3 -17 1 -20 -5 -10 -15 12 -51 30 -51 8 0 40 -25
71 -55 31 -30 61 -55 65 -55 17 0 6 31 -27 74 -42 55 -43 66 -9 66 29 0 64
-24 129 -87 28 -28 52 -43 69 -43 14 0 26 4 26 8 0 14 -43 48 -77 62 -18 7
-33 16 -33 21 0 5 -13 24 -30 42 -16 19 -30 37 -30 40 0 10 89 57 109 57 10 0
33 11 49 25 17 14 35 25 42 25 9 0 11 11 7 34 -6 36 7 46 57 46 11 0 36 8 58
17 l38 17 -36 33 c-39 35 -81 42 -136 21z"/>,
    <path d="M3445 12340 c-3 -6 -4 -19 0 -29 3 -11 1 -22 -5 -26 -17 -10 -11 -54
10 -75 11 -11 20 -22 20 -25 0 -10 -29 -4 -32 7 -2 6 -9 9 -15 5 -7 -4 -9 -38
-7 -92 3 -69 1 -85 -10 -85 -11 0 -15 14 -14 60 1 98 -54 155 -67 68 -8 -52
-1 -240 9 -256 13 -21 9 -211 -4 -227 -11 -13 -12 -108 -5 -483 12 -632 7
-881 -18 -927 -6 -11 -12 -34 -12 -52 -1 -17 -9 -39 -18 -48 -20 -19 -20 -17
-7 -85 8 -40 7 -57 -2 -68 -7 -8 -14 -46 -15 -86 -2 -77 -24 -238 -38 -271 -4
-11 -19 -47 -33 -80 -14 -33 -24 -76 -24 -95 1 -19 -5 -44 -13 -57 -10 -15
-13 -35 -8 -68 5 -41 4 -47 -16 -52 -15 -4 -21 -13 -21 -34 0 -19 -13 -42 -42
-74 -24 -25 -54 -60 -68 -79 -13 -18 -33 -37 -44 -42 -11 -5 -24 -18 -30 -29
-30 -56 -63 -98 -87 -111 -66 -34 -219 -209 -165 -188 7 3 25 -7 40 -21 35
-33 54 -20 58 38 3 42 3 42 46 45 45 3 64 24 43 45 -16 16 -14 47 3 47 8 0 18
-4 21 -10 12 -19 52 -10 69 14 10 15 12 27 6 31 -16 10 -12 65 4 65 8 0 17 6
20 13 2 6 4 1 3 -12 -2 -20 4 -26 30 -32 18 -3 39 -12 48 -19 30 -25 48 18 21
53 -7 9 -16 17 -19 17 -3 0 -17 4 -31 10 -24 9 -24 10 -10 40 19 38 29 38 49
0 18 -34 32 -38 50 -13 11 15 15 15 29 4 13 -11 16 -11 16 1 0 21 -36 47 -58
41 -12 -3 -22 1 -25 11 -4 9 -1 16 6 16 22 0 67 67 67 99 0 17 5 31 11 31 6 0
8 -7 5 -16 -7 -19 14 -84 27 -84 5 0 32 -16 59 -35 27 -20 54 -33 60 -29 8 5
6 11 -4 18 -9 6 -17 20 -19 31 -3 12 -14 21 -29 23 -18 2 -30 14 -42 41 -10
21 -18 43 -18 49 0 7 -4 12 -10 12 -5 0 -10 5 -10 10 0 6 16 10 35 10 42 0 43
8 14 85 -25 67 -21 85 17 77 35 -6 68 32 51 59 -9 14 -7 22 8 39 16 17 16 24
6 44 -14 25 -16 22 46 88 11 12 14 27 9 52 -4 19 -3 43 2 53 5 10 14 28 21 41
9 17 9 27 1 37 -7 8 -11 51 -10 107 1 51 -1 122 -4 157 -5 43 -3 68 6 78 8 10
13 59 14 144 1 85 6 134 14 144 7 8 10 24 7 35 -3 11 -1 23 4 26 5 3 9 1 9 -4
0 -6 7 -12 15 -16 11 -4 15 1 15 19 0 14 -6 25 -13 25 -18 0 -56 72 -39 78 6
2 12 15 12 28 0 19 5 24 25 24 18 0 25 5 25 19 0 27 -20 38 -32 17 -10 -19
-34 -21 -44 -5 -4 7 3 19 16 29 l23 18 -21 11 c-26 14 -31 61 -7 61 8 0 15 -4
15 -10 0 -16 30 -12 36 5 4 8 13 15 21 15 12 0 12 2 -1 16 -9 8 -16 25 -16 38
0 12 -5 27 -12 34 -8 8 -8 15 -1 24 6 7 11 47 11 88 0 41 -5 81 -11 88 -7 9
-7 16 1 24 7 7 12 18 12 25 0 19 -27 16 -38 -4 -6 -10 -16 -14 -26 -10 -19 8
-21 32 -3 47 50 42 68 62 63 67 -3 3 -14 -1 -24 -10 -28 -25 -42 -21 -42 13 0
17 5 30 10 30 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 12 -10 26 0 17 6 28
20 31 12 3 20 14 20 27 0 12 5 27 12 34 7 7 8 12 2 12 -6 0 -15 -5 -22 -12 -9
-9 -15 -9 -24 1 -11 10 -10 15 5 25 21 16 22 42 2 50 -20 7 -19 36 3 52 14 10
15 15 5 25 -7 7 -13 22 -13 33 0 18 2 18 26 -4 21 -21 27 -22 34 -10 14 22 12
40 -5 46 -17 7 -21 54 -5 54 15 0 12 26 -5 40 -20 16 -19 46 1 54 16 6 16 8 0
24 -20 23 -21 56 -1 72 20 17 19 46 -2 54 -14 6 -14 8 5 22 28 19 28 28 0 56
l-22 22 22 14 c12 8 22 24 22 35 0 11 8 26 18 33 16 13 16 15 0 28 -13 10 -18
29 -19 75 -2 59 -11 83 -24 61z m-35 -387 c0 -19 -3 -24 -10 -17 -6 6 -8 18
-4 27 9 24 14 21 14 -10z m-1 -102 c-15 -5 -22 6 -14 19 6 9 10 9 16 -1 5 -8
4 -15 -2 -18z m13 -764 c-2 -23 1 -37 9 -37 8 0 8 -4 2 -12 -16 -21 -24 -80
-12 -94 7 -9 6 -18 -3 -33 -7 -12 -16 -21 -19 -21 -4 0 -7 54 -8 121 -1 109 1
120 17 117 13 -3 16 -12 14 -41z m-102 -1287 c0 -11 -4 -20 -10 -20 -5 0 -10
9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m0 -110 c0 -11 -4 -20 -10 -20 -5
0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z"/>,
    <path d="M3624 12337 c-9 -24 6 -44 18 -22 7 11 6 21 -1 28 -7 7 -12 6 -17 -6z" />,
    <path d="M4557 12343 c-12 -12 -8 -20 18 -31 22 -10 25 -9 25 7 0 10 -7 22
-16 25 -19 7 -19 7 -27 -1z"/>,
    <path d="M4845 12341 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>,
    <path d="M5126 12343 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24
19z"/>,
    <path d="M5012 12310 c-18 -15 -29 -31 -25 -33 11 -7 65 41 61 53 -2 5 -18 -4
-36 -20z"/>,
    <path d="M5313 12328 c2 -7 10 -13 17 -13 7 0 15 6 18 13 2 7 -5 12 -18 12
-13 0 -20 -5 -17 -12z"/>,
    <path d="M3570 12282 c0 -14 11 -21 26 -15 8 3 11 9 8 14 -7 11 -34 12 -34 1z" />,
    <path d="M4593 12265 c-6 -14 -7 -25 -2 -25 5 0 9 5 9 10 0 6 9 10 20 10 11 0
20 7 20 15 0 24 -34 17 -47 -10z"/>,
    <path d="M1573 12259 c-25 -9 -32 -49 -10 -49 13 0 44 46 36 54 -2 2 -14 0
-26 -5z"/>,
    <path d="M2010 12245 c0 -8 9 -19 19 -24 15 -8 20 -7 25 5 3 9 4 20 0 25 -9
15 -44 10 -44 -6z"/>,
    <path d="M4370 12240 c0 -11 4 -20 9 -20 11 0 22 26 14 34 -12 12 -23 5 -23
-14z"/>,
    <path d="M4755 12250 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />,
    <path d="M6242 12244 c1 -9 9 -19 16 -22 9 -3 13 2 10 14 -1 9 -9 19 -16 22
-9 3 -13 -2 -10 -14z"/>,
    <path d="M1640 12225 c0 -20 5 -25 25 -25 27 0 33 18 13 38 -20 20 -38 14 -38
-13z"/>,
    <path d="M2210 12235 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M4652 12238 c3 -7 13 -15 22 -16 12 -3 17 1 14 10 -3 7 -13 15 -22
16 -12 3 -17 -1 -14 -10z"/>,
    <path d="M5130 12231 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21 -5
3 -10 -3 -10 -14z"/>,
    <path d="M5210 12235 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>,
    <path d="M2874 12229 c-9 -16 12 -33 31 -25 14 5 15 9 5 21 -15 18 -26 19 -36
4z"/>,
    <path d="M5010 12211 c-16 -31 -12 -37 10 -13 22 23 25 32 10 32 -5 0 -14 -9
-20 -19z"/>,
    <path d="M6870 12221 c0 -12 42 -39 74 -47 35 -8 33 7 -4 34 -29 22 -70 30
-70 13z"/>,
    <path d="M3587 12194 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />,
    <path d="M4137 12193 c-13 -12 -7 -23 12 -23 10 0 21 -7 25 -15 6 -17 36 -21
36 -4 0 6 -9 13 -19 16 -11 3 -22 11 -25 19 -6 15 -18 18 -29 7z"/>,
    <path d="M4890 12184 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />,
    <path d="M4340 12175 c0 -16 27 -32 37 -21 7 7 -16 36 -28 36 -5 0 -9 -7 -9
-15z"/>,
    <path d="M4282 12148 c2 -13 8 -23 13 -23 6 0 11 10 13 23 2 15 -2 22 -13 22
-11 0 -15 -7 -13 -22z"/>,
    <path d="M3887 12140 c3 -11 12 -20 19 -20 8 0 14 9 14 20 0 13 -7 20 -19 20
-14 0 -18 -5 -14 -20z"/>,
    <path d="M3984 12146 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />,
    <path d="M4670 12140 c0 -11 4 -20 9 -20 5 0 11 9 14 20 3 13 0 20 -9 20 -8 0
-14 -9 -14 -20z"/>,
    <path d="M3622 12136 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"/>,
    <path d="M4570 12135 c-10 -13 -9 -15 9 -15 12 0 21 6 21 15 0 8 -4 15 -9 15
-5 0 -14 -7 -21 -15z"/>,
    <path d="M6860 12125 c0 -16 16 -20 24 -6 3 5 -1 11 -9 15 -9 3 -15 0 -15 -9z" />,
    <path d="M5006 12114 c-8 -21 -8 -24 3 -24 5 0 11 9 14 20 6 24 -8 27 -17 4z" />,
    <path d="M3670 12100 c-11 -7 -12 -12 -3 -21 13 -13 33 -4 33 17 0 16 -9 18
-30 4z"/>,
    <path d="M4700 12090 c0 -5 -16 -12 -35 -16 -19 -3 -38 -10 -41 -15 -3 -5 -20
-9 -39 -9 -19 0 -37 -5 -40 -11 -5 -7 -13 -5 -25 6 -24 22 -60 11 -60 -17 0
-17 4 -19 27 -14 17 5 29 3 34 -6 6 -10 11 -9 21 4 11 15 20 16 57 7 34 -8 48
-7 66 5 12 8 31 13 41 10 22 -6 63 20 51 32 -4 4 -13 2 -19 -4 -17 -17 -38
-15 -38 3 0 8 5 15 10 15 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10
-10z"/>,
    <path d="M6066 12067 c-11 -8 -15 -19 -10 -30 7 -21 23 -18 38 6 15 24 -4 41
-28 24z"/>,
    <path d="M6812 12058 c2 -13 15 -29 31 -36 15 -7 27 -20 27 -28 0 -9 11 -14
30 -14 37 0 38 14 4 37 -14 9 -30 27 -35 40 -12 33 -62 33 -57 1z"/>,
    <path d="M4292 12058 c-18 -18 -14 -38 7 -38 17 0 31 18 31 41 0 13 -24 11
-38 -3z"/>,
    <path d="M3810 12045 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M4065 12046 c-19 -8 -25 -15 -17 -20 7 -4 16 -5 22 -1 5 3 26 -1 46
-10 46 -19 54 -12 24 20 -26 28 -33 29 -75 11z"/>,
    <path d="M4840 12046 c0 -8 4 -17 8 -20 13 -8 35 11 28 23 -10 16 -36 14 -36
-3z"/>,
    <path d="M6720 12028 c0 -14 148 -158 162 -158 5 0 8 3 6 8 -28 57 -44 82 -55
82 -7 0 -17 9 -23 21 -11 19 -66 59 -82 59 -5 0 -8 -6 -8 -12z"/>,
    <path d="M6281 11985 c-8 -24 -9 -40 -3 -48 6 -7 12 -23 14 -37 2 -18 9 -25
25 -24 15 0 25 -7 29 -20 4 -12 13 -28 21 -38 11 -13 12 -20 3 -35 -10 -15 -8
-25 11 -55 13 -21 32 -40 41 -43 22 -8 76 -64 94 -97 8 -15 28 -35 44 -43 17
-9 30 -24 30 -33 0 -10 14 -33 32 -51 24 -24 28 -35 20 -43 -20 -20 -13 -42
19 -60 28 -16 32 -16 55 -2 13 9 24 17 24 19 0 1 -9 13 -20 25 -15 17 -19 32
-15 52 6 24 -1 34 -51 80 -31 29 -61 52 -66 50 -14 -4 -47 40 -48 64 0 11 -18
37 -40 58 -22 20 -40 43 -40 51 0 22 -33 85 -44 85 -6 0 -23 12 -38 25 -23 19
-28 32 -26 64 1 42 -23 91 -45 91 -7 0 -19 -16 -26 -35z"/>,
    <path d="M3575 11990 c-10 -11 -27 -20 -37 -20 -13 0 -18 -6 -16 -17 5 -26 34
-28 41 -3 3 12 13 20 26 20 14 0 21 6 21 20 0 11 -4 20 -8 20 -5 0 -17 -9 -27
-20z"/>,
    <path d="M4260 11960 c0 -20 -5 -30 -15 -30 -8 0 -15 -7 -15 -15 0 -8 5 -15
10 -15 6 0 13 7 16 15 4 8 17 15 30 15 31 0 31 23 -1 44 -25 16 -25 16 -25
-14z"/>,
    <path d="M4180 11950 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M6080 11950 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M4050 11925 c0 -15 4 -24 10 -20 6 3 10 12 10 20 0 8 -4 17 -10 20
-6 4 -10 -5 -10 -20z"/>,
    <path d="M6166 11920 c11 -20 12 -35 6 -47 -11 -19 2 -59 28 -83 9 -8 22 -33
28 -56 7 -23 23 -58 37 -78 14 -20 25 -41 25 -46 0 -21 24 -21 41 1 10 13 21
21 25 17 4 -4 2 -14 -5 -21 -8 -10 -8 -22 0 -46 6 -17 9 -37 6 -45 -3 -7 -1
-19 3 -27 7 -11 10 -10 16 4 3 9 12 17 19 17 18 0 75 -58 75 -75 0 -25 12 -45
28 -45 8 0 24 -7 34 -17 15 -13 18 -14 18 -2 0 8 -4 19 -9 25 -5 5 -11 20 -13
34 -2 19 -10 26 -30 28 -21 3 -27 8 -27 25 2 44 -37 141 -62 154 -13 7 -24 20
-24 29 0 26 -25 29 -32 5 -6 -23 -19 -26 -43 -11 -12 8 -13 13 -2 30 16 27 15
47 -3 54 -23 8 -19 46 5 46 23 0 24 4 9 31 -10 19 -11 19 -39 -11 -15 -16 -33
-30 -39 -30 -19 0 -44 69 -38 104 4 25 1 39 -12 50 -29 26 -41 19 -25 -14z"/>,
    <path d="M3860 11906 c0 -26 31 -66 50 -66 6 0 10 7 10 15 0 8 -6 15 -14 15
-8 0 -16 12 -18 27 -4 35 -28 43 -28 9z"/>,
    <path d="M4780 11921 c0 -21 13 -41 26 -41 8 0 14 10 14 25 0 18 -5 25 -20 25
-11 0 -20 -4 -20 -9z"/>,
    <path d="M3570 11895 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>,
    <path d="M6740 11879 c0 -14 97 -109 111 -109 37 0 -43 113 -84 118 -17 2 -27
-1 -27 -9z"/>,
    <path d="M3542 11867 c-9 -11 -10 -17 -2 -22 15 -9 23 -1 18 21 -3 15 -4 16
-16 1z"/>,
    <path d="M4280 11870 c-12 -7 -12 -12 -2 -22 9 -9 15 -9 24 0 16 16 -2 34 -22
22z"/>,
    <path d="M6579 11861 c6 -10 32 -38 58 -61 89 -78 112 -75 42 7 -50 59 -68 73
-91 73 -15 0 -17 -4 -9 -19z"/>,
    <path d="M3827 11863 c-8 -7 11 -33 24 -33 5 0 9 9 9 20 0 19 -20 27 -33 13z" />,
    <path d="M3990 11855 c0 -9 9 -15 25 -15 28 0 34 15 9 24 -25 10 -34 7 -34 -9z" />,
    <path d="M4103 11863 c-18 -6 -16 -23 1 -23 17 0 30 16 19 23 -5 2 -14 2 -20
0z"/>,
    <path d="M4190 11855 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>,
    <path d="M4831 11857 c-1 -10 -8 -20 -16 -23 -19 -8 -20 -34 0 -34 16 0 30 41
22 62 -3 8 -6 6 -6 -5z"/>,
    <path d="M3588 11843 c-33 -17 -37 -43 -8 -43 12 0 24 10 30 24 5 13 15 26 22
29 9 4 9 5 -2 5 -8 0 -27 -7 -42 -15z"/>,
    <path d="M5405 11851 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />,
    <path d="M4046 11824 c-4 -10 -2 -22 3 -27 7 -7 13 -5 17 7 4 10 2 22 -3 27
-7 7 -13 5 -17 -7z"/>,
    <path d="M4690 11821 c0 -10 6 -24 14 -30 12 -10 17 -8 25 8 17 31 13 41 -14
41 -18 0 -25 -5 -25 -19z"/>,
    <path d="M4154 11815 c-18 -13 -18 -14 5 -39 36 -38 70 -31 57 12 -10 29 -41
43 -62 27z"/>,
    <path d="M3893 11775 c-3 -9 -3 -18 0 -21 9 -9 27 6 27 22 0 19 -19 18 -27 -1z" />,
    <path d="M4832 11771 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />,
    <path d="M4643 11740 c-13 -5 -23 -14 -23 -20 0 -12 -4 -13 50 4 25 8 40 15
33 15 -6 1 -17 3 -25 5 -7 3 -23 1 -35 -4z"/>,
    <path d="M6596 11741 c-3 -4 10 -23 29 -41 34 -33 51 -37 40 -8 -8 21 -63 60
-69 49z"/>,
    <path d="M3864 11726 c-5 -13 -2 -23 9 -31 10 -7 17 -17 17 -24 0 -14 17 -14
25 0 4 6 0 16 -8 22 -8 7 -19 21 -25 32 -10 19 -11 19 -18 1z"/>,
    <path d="M5090 11725 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M7311 11678 c-18 -48 -17 -100 2 -112 15 -9 22 -6 38 13 27 32 22 88
-8 108 -21 14 -23 13 -32 -9z"/>,
    <path d="M4820 11676 c0 -10 9 -16 21 -16 24 0 21 23 -4 28 -10 2 -17 -3 -17
-12z"/>,
    <path d="M4586 11671 c-8 -13 13 -59 30 -65 8 -3 18 4 24 14 6 11 20 20 30 20
13 0 20 7 20 20 0 13 -7 20 -20 20 -11 0 -20 -4 -20 -9 0 -17 -29 -20 -44 -5
-8 8 -17 10 -20 5z"/>,
    <path d="M4755 11670 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
-11 0 -17 -4 -14 -10z"/>,
    <path d="M6090 11655 c0 -6 11 -20 38 -50 16 -18 15 -30 -3 -45 -8 -7 -15 -20
-15 -30 0 -10 7 -20 15 -24 8 -3 15 -13 15 -22 0 -10 7 -25 15 -34 14 -15 14
-19 1 -30 -14 -10 -12 -16 10 -45 21 -28 23 -37 13 -53 -10 -16 -8 -24 11 -50
13 -18 30 -32 37 -32 14 0 17 -16 14 -66 -1 -17 7 -48 18 -68 24 -42 27 -72 9
-79 -7 -2 -9 -8 -5 -13 4 -4 17 -2 29 5 28 18 52 -4 25 -23 -16 -13 -15 -46 2
-46 4 0 13 -16 20 -35 7 -19 17 -35 22 -35 5 0 9 -7 9 -15 0 -8 -4 -15 -10
-15 -5 0 -10 -7 -10 -15 0 -22 41 -75 57 -75 7 0 19 -12 26 -27 6 -16 17 -32
24 -36 23 -14 14 -30 -12 -23 -23 6 -25 4 -25 -29 0 -24 5 -38 16 -42 25 -9
50 11 46 37 -2 13 2 26 10 31 11 7 11 12 0 30 -12 18 -11 24 3 38 15 15 17 15
29 -1 7 -10 18 -18 25 -18 17 0 13 37 -4 44 -13 4 -13 9 -3 25 15 24 -4 51
-36 50 -21 0 -21 0 2 -16 29 -20 28 -33 -3 -33 -22 0 -25 4 -25 35 0 19 -4 35
-8 35 -4 0 -6 15 -4 34 2 21 -3 44 -13 59 -13 21 -14 29 -3 46 16 26 1 42 -17
17 -8 -10 -19 -15 -26 -10 -9 5 -8 10 4 19 10 7 18 23 19 36 2 30 -20 99 -39
121 -8 9 -12 22 -8 27 3 6 -3 24 -14 40 -12 16 -21 36 -21 45 0 29 -23 66 -42
66 -13 0 -18 8 -18 30 0 35 -29 43 -37 10 -6 -22 -18 -26 -28 -9 -4 5 0 18 9
28 17 19 23 86 6 76 -5 -3 -10 -15 -10 -26 0 -12 -5 -19 -12 -17 -16 6 -23 58
-9 58 6 0 11 7 11 15 0 8 -4 15 -10 15 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -9
-15 -19 0 -21 20 -6 44 19 29 13 46 -18 46 -14 0 -28 11 -39 30 -15 26 -38 41
-38 25z m190 -316 c0 -24 -23 -21 -28 4 -2 10 3 17 12 17 10 0 16 -9 16 -21z
m-20 -61 c31 -23 31 -38 -1 -38 -10 0 -19 7 -19 15 0 9 -7 18 -15 21 -17 7
-20 24 -5 24 6 0 24 -10 40 -22z m68 -75 c2 -7 -5 -16 -15 -20 -10 -4 -23 -13
-29 -21 -6 -8 -16 -12 -22 -8 -18 11 -14 23 8 29 11 3 20 13 20 22 0 19 31 17
38 -2z m52 -38 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15
-7 15 -15z m38 -337 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z"/>,
    <path d="M3860 11616 c0 -18 -6 -39 -14 -45 -13 -11 -11 -61 3 -61 20 1 30 22
37 78 6 53 4 62 -10 62 -12 0 -16 -9 -16 -34z"/>,
    <path d="M4794 11636 c-6 -17 2 -36 17 -36 12 0 12 37 -1 45 -6 3 -13 -1 -16
-9z"/>,
    <path d="M5454 11635 c-6 -14 3 -52 10 -44 2 2 6 16 10 32 7 29 -10 39 -20 12z" />,
    <path d="M6683 11634 c-5 -14 41 -74 58 -74 5 0 20 -11 35 -25 24 -22 64 -34
64 -20 0 16 -51 71 -93 101 -54 39 -56 39 -64 18z"/>,
    <path d="M3572 11618 c3 -7 14 -14 26 -16 12 -2 22 -10 22 -18 0 -8 5 -14 10
-14 15 0 12 17 -7 40 -19 22 -57 28 -51 8z"/>,
    <path d="M4702 11619 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>,
    <path d="M3425 11590 c-8 -13 14 -42 26 -34 5 3 9 14 9 25 0 20 -24 26 -35 9z" />,
    <path d="M4107 11594 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21
-31 12z"/>,
    <path d="M4557 11594 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27
-31 17z"/>,
    <path d="M5126 11592 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z" />,
    <path d="M5885 11580 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
-11 0 -17 -4 -14 -10z"/>,
    <path d="M4520 11530 c0 -11 6 -20 14 -20 8 0 17 -7 20 -16 3 -8 11 -12 17 -9
7 5 5 13 -5 24 -9 10 -16 23 -16 29 0 7 -7 12 -15 12 -8 0 -15 -9 -15 -20z"/>,
    <path d="M4600 11531 c0 -5 7 -12 16 -15 22 -9 24 -8 24 9 0 8 -9 15 -20 15
-11 0 -20 -4 -20 -9z"/>,
    <path d="M4982 11529 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>,
    <path d="M5452 11531 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z" />,
    <path d="M5870 11491 c0 -25 14 -41 36 -41 27 0 28 3 14 31 -11 19 -50 27 -50
10z"/>,
    <path d="M7306 11438 c-8 -33 -7 -91 3 -106 4 -8 19 -12 32 -10 21 3 24 9 27
56 2 31 -2 60 -9 68 -18 21 -46 17 -53 -8z"/>,
    <path d="M4520 11436 c0 -8 -11 -17 -25 -20 -14 -4 -25 -13 -25 -21 0 -23 38
-18 51 7 11 21 12 21 25 4 8 -11 20 -16 29 -12 21 8 10 26 -16 26 -10 0 -19 7
-19 15 0 8 -4 15 -10 15 -5 0 -10 -6 -10 -14z"/>,
    <path d="M5460 11435 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M5035 11397 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17
-12 0z"/>,
    <path d="M3426 11385 c-4 -10 1 -15 14 -15 11 0 20 7 20 15 0 8 -6 15 -14 15
-8 0 -16 -7 -20 -15z"/>,
    <path d="M5867 11393 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />,
    <path d="M6840 11387 c0 -10 9 -27 20 -39 26 -28 26 -18 0 24 -14 23 -20 27
-20 15z"/>,
    <path d="M3762 11353 c-15 -6 -6 -23 14 -23 8 0 14 7 14 15 0 15 -6 17 -28 8z" />,
    <path d="M6410 11349 c0 -14 41 -83 52 -87 4 -2 8 -10 8 -17 0 -8 10 -23 21
-34 12 -11 24 -35 28 -53 6 -31 9 -33 49 -33 27 0 42 4 42 13 0 6 -6 12 -13
12 -19 0 -47 33 -47 56 -1 27 -36 66 -56 62 -11 -2 -20 5 -23 17 -13 46 -61
96 -61 64z"/>,
    <path d="M3657 11330 c-6 -24 12 -38 24 -19 10 16 7 31 -8 36 -6 2 -13 -5 -16
-17z"/>,
    <path d="M4040 11340 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M6734 11327 c-31 -18 -19 -38 17 -30 25 5 35 0 59 -26 26 -28 50 -40
50 -25 0 11 -84 94 -94 94 -6 -1 -20 -6 -32 -13z"/>,
    <path d="M3805 11310 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />,
    <path d="M4080 11286 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12
-12z"/>,
    <path d="M4380 11285 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>,
    <path d="M4655 11290 c-3 -5 -1 -10 4 -10 6 0 11 -6 11 -14 0 -18 16 -29 30
-21 6 4 3 16 -7 30 -20 27 -29 30 -38 15z"/>,
    <path d="M5030 11290 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M6620 11286 c0 -7 -9 -16 -20 -19 -13 -3 -18 -10 -14 -21 9 -22 34
-20 34 3 0 10 7 21 15 25 8 3 15 10 15 16 0 5 -7 10 -15 10 -8 0 -15 -6 -15
-14z"/>,
    <path d="M3662 11258 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>,
    <path d="M4975 11260 c-4 -6 5 -10 20 -10 15 0 24 4 20 10 -3 6 -12 10 -20 10
-8 0 -17 -4 -20 -10z"/>,
    <path d="M5540 11251 c0 -24 18 -37 31 -24 13 13 1 43 -17 43 -8 0 -14 -9 -14
-19z"/>,
    <path d="M5635 11260 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />,
    <path d="M3626 11213 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />,
    <path d="M6620 11206 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12
-12z"/>,
    <path d="M6818 11213 c-23 -6 -23 -29 -1 -37 19 -8 48 17 39 33 -7 11 -10 12
-38 4z"/>,
    <path d="M3710 11200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M5593 11198 c2 -7 10 -13 17 -13 7 0 15 6 18 13 2 7 -5 12 -18 12
-13 0 -20 -5 -17 -12z"/>,
    <path d="M6693 11193 c-27 -18 -30 -38 -7 -47 10 -4 18 0 21 13 3 11 12 22 20
25 8 3 11 10 8 16 -8 13 -16 12 -42 -7z"/>,
    <path d="M4526 11173 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24
19z"/>,
    <path d="M5547 11173 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />,
    <path d="M4800 11162 c0 -4 9 -13 20 -20 22 -13 27 -3 8 16 -14 14 -28 16 -28
4z"/>,
    <path d="M4885 11161 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />,
    <path d="M6703 11109 c-14 -37 -8 -55 14 -38 12 11 13 9 8 -12 -4 -17 1 -35
17 -58 31 -47 48 -51 48 -12 0 28 -6 46 -32 91 -4 8 -12 25 -18 38 -13 30 -23
28 -37 -9z"/>,
    <path d="M5600 11115 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M3720 11105 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M6540 11080 c0 -11 4 -20 8 -20 10 0 42 -65 42 -84 0 -7 12 -26 26
-42 14 -16 43 -59 65 -95 22 -37 49 -75 59 -84 19 -17 20 -17 26 0 5 11 -2 38
-18 69 -15 28 -31 68 -38 89 -8 27 -17 37 -32 37 -15 0 -19 5 -15 20 3 10 -2
25 -10 32 -8 6 -22 29 -30 51 -12 29 -20 37 -34 34 -11 -3 -22 -1 -25 4 -10
16 -24 10 -24 -11z"/>,
    <path d="M5870 11080 c-13 -8 -13 -11 2 -26 16 -17 18 -17 24 0 9 23 -7 38
-26 26z"/>,
    <path d="M3760 11060 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M3840 11056 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />,
    <path d="M3985 11020 c-8 -13 20 -24 36 -15 8 6 7 10 -2 16 -18 11 -26 11 -34
-1z"/>,
    <path d="M4187 11024 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21
-31 12z"/>,
    <path d="M5600 11000 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 -9 -10 -20z"/>,
    <path d="M3776 10971 c-4 -6 0 -14 8 -17 9 -3 16 -18 16 -33 1 -24 44 -70 56
-58 12 12 -14 73 -35 84 -13 6 -27 17 -31 23 -5 9 -9 9 -14 1z"/>,
    <path d="M3985 10930 c-8 -13 15 -24 27 -12 6 6 4 11 -6 15 -8 3 -18 2 -21 -3z" />,
    <path d="M5782 10918 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>,
    <path d="M5840 10910 c0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -20 0 -14 7 -20
24 -20 13 0 26 -6 29 -12 2 -7 8 -10 12 -6 4 4 0 17 -9 30 -9 12 -16 33 -16
45 0 13 -4 23 -10 23 -5 0 -10 -9 -10 -20z"/>,
    <path d="M7310 10900 c0 -16 5 -21 23 -18 12 2 22 10 22 18 0 8 -10 16 -22 18
-18 3 -23 -2 -23 -18z"/>,
    <path d="M5455 10880 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />,
    <path d="M6100 10876 c0 -8 -3 -21 -6 -29 -4 -11 1 -13 19 -10 19 4 26 0 30
-17 3 -12 10 -19 16 -15 16 9 14 32 -5 60 -18 27 -54 34 -54 11z"/>,
    <path d="M3712 10836 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"/>,
    <path d="M5595 10841 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33
14z"/>,
    <path d="M4840 10830 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M5827 10803 c-13 -12 -7 -34 11 -40 9 -3 25 -15 34 -27 16 -20 17
-20 24 -2 5 12 2 22 -9 28 -10 5 -17 15 -17 22 0 8 -7 17 -16 20 -19 7 -19 7
-27 -1z"/>,
    <path d="M6158 10760 c-21 -13 -12 -50 12 -50 20 0 32 23 24 45 -7 17 -13 18
-36 5z"/>,
    <path d="M3810 10740 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18
-8 2 -13 -5 -13 -18z"/>,
    <path d="M4253 10725 c-38 -40 -29 -52 21 -28 25 13 36 25 36 41 0 31 -19 27
-57 -13z"/>,
    <path d="M5595 10750 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z" />,
    <path d="M4107 10743 c-12 -12 -7 -33 8 -33 17 0 19 9 7 28 -5 7 -11 9 -15 5z" />,
    <path d="M4560 10742 c0 -4 9 -13 21 -20 19 -12 19 -11 9 8 -10 19 -30 28 -30
12z"/>,
    <path d="M4935 10740 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
-10z"/>,
    <path d="M5420 10735 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M6074 10719 c-8 -14 18 -31 30 -19 9 9 0 30 -14 30 -5 0 -12 -5 -16
-11z"/>,
    <path d="M6660 10713 c0 -12 5 -25 10 -28 6 -3 10 -21 10 -39 0 -17 4 -36 9
-41 5 -6 13 -26 18 -45 11 -42 27 -46 31 -6 2 19 -3 34 -17 47 -12 11 -21 28
-21 38 0 9 -9 35 -20 57 -15 30 -19 34 -20 17z"/>,
    <path d="M3854 10706 c-4 -10 -2 -22 4 -28 8 -8 13 -7 18 6 4 10 2 22 -4 28
-8 8 -13 7 -18 -6z"/>,
    <path d="M5827 10703 c-14 -13 -6 -33 13 -33 13 0 20 7 20 20 0 19 -20 27 -33
13z"/>,
    <path d="M3480 10685 c0 -16 16 -20 24 -6 3 5 -1 11 -9 15 -9 3 -15 0 -15 -9z" />,
    <path d="M4610 10690 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M5360 10685 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>,
    <path d="M5456 10692 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z" />,
    <path d="M5550 10685 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M5640 10685 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>,
    <path d="M5901 10669 c-10 -23 -10 -36 0 -66 l12 -38 11 26 c6 14 16 32 23 40
9 11 6 20 -11 40 l-22 26 -13 -28z"/>,
    <path d="M6200 10666 c-5 -15 -22 -29 -44 -36 -20 -6 -36 -18 -36 -26 0 -19
16 -18 24 1 7 19 30 19 46 0 11 -13 10 -18 -8 -31 -13 -9 -19 -21 -15 -31 3
-9 7 -36 7 -61 1 -27 6 -47 14 -49 6 -3 12 3 12 11 0 9 7 19 15 22 8 4 15 16
15 29 0 13 -7 25 -15 29 -19 7 -20 46 -1 46 8 0 18 -9 21 -21 5 -16 9 -18 21
-8 17 14 10 64 -12 90 -8 8 -14 25 -14 37 0 29 -18 28 -30 -2z"/>,
    <path d="M6582 10668 c-9 -9 -6 -18 13 -39 16 -16 27 -40 28 -60 3 -39 30 -84
46 -75 8 5 6 16 -4 36 -8 16 -15 43 -15 60 0 19 -5 30 -14 30 -7 0 -16 14 -19
30 -7 32 -16 37 -35 18z"/>,
    <path d="M3434 10644 c3 -9 6 -18 6 -20 0 -2 5 -4 10 -4 6 0 10 9 10 20 0 12
-7 20 -16 20 -11 0 -14 -5 -10 -16z"/>,
    <path d="M6346 10645 c-3 -8 1 -27 8 -42 8 -16 24 -57 35 -93 18 -54 19 -70
10 -95 -10 -26 -8 -35 11 -67 28 -48 42 -40 39 23 -2 27 0 52 4 55 4 4 7 26 7
49 0 31 -6 47 -25 65 -14 13 -25 30 -25 37 0 8 -7 16 -15 19 -9 4 -15 19 -15
35 0 31 -23 41 -34 14z"/>,
    <path d="M3812 10641 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />,
    <path d="M5695 10640 c-8 -13 -1 -24 14 -19 6 3 7 10 2 18 -6 10 -10 10 -16 1z" />,
    <path d="M3765 10600 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
-10z"/>,
    <path d="M4795 10601 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z" />,
    <path d="M5457 10603 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />,
    <path d="M5836 10591 c-3 -5 1 -14 9 -21 13 -10 15 -9 15 9 0 21 -14 28 -24
12z"/>,
    <path d="M3695 10560 c9 -18 23 -30 35 -30 28 0 25 19 -5 30 -14 5 -25 14 -25
20 0 5 -5 10 -10 10 -6 0 -4 -13 5 -30z"/>,
    <path d="M3530 10555 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M5600 10550 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 -9 -10 -20z"/>,
    <path d="M4757 10553 c-11 -10 -8 -20 8 -27 10 -4 15 1 15 14 0 20 -11 26 -23
13z"/>,
    <path d="M5036 10553 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />,
    <path d="M5510 10545 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>,
    <path d="M5810 10541 c0 -25 21 -60 39 -66 12 -3 21 -15 21 -26 0 -10 7 -19
15 -19 19 0 20 29 0 36 -8 3 -17 15 -21 26 -11 37 -54 75 -54 49z"/>,
    <path d="M5872 10539 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>,
    <path d="M5960 10535 c0 -8 -9 -15 -19 -15 -25 0 -38 -20 -20 -31 22 -13 30
-11 36 11 3 11 12 20 19 20 8 0 14 7 14 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15
-15z"/>,
    <path d="M3675 10511 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />,
    <path d="M6252 10491 c-9 -20 -10 -36 -3 -55 9 -22 7 -29 -17 -51 -26 -25 -26
-26 -8 -40 16 -12 18 -23 13 -69 -3 -31 -1 -68 3 -83 5 -15 16 -55 24 -88 28
-118 74 -228 106 -253 26 -20 30 -29 25 -55 -6 -33 8 -67 21 -54 36 35 34 150
-2 155 -23 3 -54 63 -54 103 0 13 -11 47 -23 74 -29 62 -47 131 -44 169 3 37
27 48 27 12 0 -13 5 -28 10 -31 6 -3 10 -16 10 -29 0 -30 19 -49 37 -35 30 26
2 119 -36 119 -15 0 -24 52 -10 60 8 6 4 19 -15 46 -14 21 -26 50 -26 64 0 32
-10 70 -19 70 -3 0 -12 -13 -19 -29z"/>,
    <path d="M4790 10501 c0 -12 29 -23 41 -15 5 3 7 10 4 15 -8 12 -45 12 -45 0z" />,
    <path d="M5453 10491 c-3 -11 -13 -21 -24 -24 -21 -6 -26 -27 -6 -27 15 0 57
44 57 59 0 19 -22 12 -27 -8z"/>,
    <path d="M6574 10495 c-4 -8 1 -33 10 -55 9 -22 16 -62 16 -89 0 -46 16 -100
42 -146 12 -21 37 -109 45 -162 7 -42 16 -41 42 3 20 33 20 38 6 54 -8 9 -15
27 -15 39 0 12 -5 31 -11 42 -7 15 -7 24 1 35 10 11 9 21 -3 47 -23 47 -36
113 -26 128 13 21 12 24 -34 73 -46 48 -64 56 -73 31z"/>,
    <path d="M7302 10471 c-6 -16 -8 -31 -5 -34 2 -3 7 -26 11 -53 4 -26 13 -49
20 -52 30 -10 57 129 30 156 -21 21 -46 13 -56 -17z"/>,
    <path d="M5960 10455 c0 -18 5 -25 20 -25 11 0 20 7 20 15 0 8 -9 19 -20 25
-18 9 -20 8 -20 -15z"/>,
    <path d="M3440 10451 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z" />,
    <path d="M4840 10456 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />,
    <path d="M5310 10450 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>,
    <path d="M5690 10445 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>,
    <path d="M5637 10424 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />,
    <path d="M4990 10410 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M5843 10404 c-4 -10 0 -22 10 -29 10 -7 17 -20 17 -29 0 -9 7 -16 15
-16 8 0 15 9 15 20 0 11 -7 23 -15 26 -8 4 -15 12 -15 19 0 22 -19 28 -27 9z"/>,
    <path d="M5915 10410 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>,
    <path d="M5973 10393 c-18 -7 -16 -29 5 -61 10 -15 22 -36 28 -46 18 -33 38
-33 30 0 -25 109 -32 120 -63 107z"/>,
    <path d="M3444 10366 c-4 -10 -2 -22 4 -28 8 -8 13 -7 18 6 4 10 2 22 -4 28
-8 8 -13 7 -18 -6z"/>,
    <path d="M5848 10312 c23 -18 22 -10 36 -287 2 -46 8 -81 15 -83 6 -2 11 -16
11 -31 0 -24 22 -68 42 -81 4 -3 10 -39 13 -80 4 -40 10 -82 16 -91 5 -10 9
-25 9 -34 0 -8 11 -33 25 -55 14 -22 25 -50 25 -62 0 -13 12 -45 25 -73 14
-27 25 -55 25 -62 0 -17 68 -139 85 -153 8 -7 15 -20 15 -29 0 -9 11 -34 25
-56 13 -22 25 -48 25 -57 0 -20 61 -84 87 -92 13 -4 23 4 36 30 21 43 22 64 2
64 -8 0 -15 5 -15 10 0 6 -5 10 -11 10 -8 0 -7 -5 1 -15 10 -12 10 -19 2 -27
-18 -18 -64 21 -68 57 -2 17 -6 46 -10 66 -3 23 -2 39 5 43 18 11 12 36 -8 36
-10 0 -21 6 -24 14 -3 8 -18 16 -34 18 -25 3 -28 7 -25 35 2 17 -2 34 -7 38
-6 3 -11 15 -11 26 0 11 -6 19 -12 18 -27 -3 -38 2 -38 16 0 9 10 15 26 15 33
0 30 33 -4 38 -18 3 -22 10 -22 37 0 18 6 35 13 38 6 2 8 8 4 13 -4 5 -12 2
-16 -5 -14 -22 -33 -2 -26 29 5 22 2 28 -14 33 -14 3 -21 13 -21 30 0 17 -7
26 -21 30 -19 5 -20 7 -4 36 14 29 14 32 -5 51 -11 11 -20 26 -20 34 0 7 -10
20 -22 29 l-23 15 28 11 c32 12 34 25 9 43 -10 7 -27 28 -37 46 -17 28 -18 35
-6 54 12 20 11 24 -8 36 -24 17 -28 60 -6 68 13 6 14 8 1 18 -15 12 -28 64
-26 111 1 51 -9 75 -30 75 -11 0 -20 5 -20 10 0 6 -8 10 -17 10 -15 0 -15 -3
5 -18z"/>,
    <path d="M3446 10293 c-4 -9 -2 -21 4 -27 13 -13 46 17 36 33 -11 16 -33 13
-40 -6z"/>,
    <path d="M4520 10295 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>,
    <path d="M3712 10269 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17
-8 3 -12 -1 -9 -9z"/>,
    <path d="M3778 10264 c-37 -19 -35 -30 8 -50 26 -13 38 -14 47 -5 6 6 23 11
38 11 15 0 29 7 33 16 4 11 10 13 19 6 20 -16 57 -24 57 -13 0 6 -8 11 -18 11
-10 0 -27 7 -39 15 -14 10 -24 12 -28 5 -9 -15 -41 -12 -55 5 -15 19 -27 18
-62 -1z"/>,
    <path d="M4087 10274 c-14 -14 -7 -25 13 -22 12 2 21 8 21 13 0 12 -24 18 -34
9z"/>,
    <path d="M4740 10270 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>,
    <path d="M6445 10249 c-7 -22 10 -49 31 -49 25 0 26 21 0 45 -24 22 -25 23
-31 4z"/>,
    <path d="M3480 10233 c0 -20 4 -22 33 -19 17 3 36 5 40 5 5 1 7 9 5 18 -2 13
-13 18 -41 18 -32 0 -37 -3 -37 -22z"/>,
    <path d="M4011 10202 c-11 -16 -18 -32 -15 -35 10 -9 85 34 81 47 -8 24 -45
17 -66 -12z"/>,
    <path d="M6020 10207 c0 -7 5 -18 12 -25 9 -9 9 -15 0 -24 -16 -16 -15 -48 1
-48 17 0 44 63 29 68 -7 2 -12 12 -12 23 0 10 -7 19 -15 19 -8 0 -15 -6 -15
-13z"/>,
    <path d="M3530 10180 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>,
    <path d="M3600 10165 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>,
    <path d="M3650 10126 c0 -17 5 -26 16 -26 10 0 14 7 12 22 -5 32 -28 35 -28 4z" />,
    <path d="M3441 10091 c-10 -6 -10 -10 -1 -16 13 -8 24 -1 19 14 -3 6 -10 7
-18 2z"/>,
    <path d="M6050 10080 c0 -11 5 -20 10 -20 19 0 10 -19 -10 -22 -29 -4 -30 -22
-2 -26 20 -3 23 -8 18 -28 -5 -17 -2 -24 9 -24 8 0 17 -11 21 -25 3 -14 12
-25 20 -25 19 0 18 -19 -3 -42 -15 -17 -15 -21 0 -54 10 -19 15 -39 12 -43 -2
-5 6 -6 19 -4 34 7 56 -30 56 -94 0 -36 4 -53 13 -53 6 0 20 -12 30 -26 15
-21 17 -32 9 -69 -7 -35 -6 -45 5 -49 9 -4 11 -14 7 -32 -5 -17 -2 -29 7 -35
8 -5 14 -19 14 -31 0 -16 7 -24 23 -26 24 -4 26 -9 12 -32 -8 -12 -13 -13 -29
-3 -14 9 -22 9 -27 2 -3 -6 -4 -11 -3 -12 50 -26 67 -40 79 -66 8 -17 25 -34
38 -37 15 -4 22 -14 22 -29 0 -22 45 -65 68 -65 5 0 12 -7 16 -15 3 -8 11 -15
19 -15 20 0 62 -61 62 -90 0 -18 6 -26 23 -28 22 -3 33 -42 11 -42 -5 0 -3
-10 5 -22 9 -12 16 -31 16 -41 0 -9 7 -20 15 -23 17 -7 20 -30 5 -39 -6 -4
-17 -1 -25 5 -11 9 -15 9 -15 1 0 -6 5 -11 10 -11 6 0 10 -9 10 -20 0 -10 6
-23 13 -27 8 -4 16 -16 20 -25 9 -26 -20 -23 -34 4 -11 20 -13 20 -24 5 -17
-24 -25 -21 -25 11 0 15 -7 40 -15 56 -9 16 -13 37 -10 46 3 9 -2 23 -10 30
-8 7 -13 21 -10 30 4 11 -3 23 -20 34 -14 9 -25 25 -25 35 0 22 -30 51 -53 51
-19 0 -24 -35 -7 -45 6 -3 10 -16 10 -29 0 -12 11 -33 25 -46 14 -13 25 -30
25 -39 0 -8 4 -28 9 -45 5 -17 19 -65 31 -106 12 -41 31 -102 41 -135 11 -33
19 -71 19 -83 0 -13 9 -35 20 -49 12 -16 20 -41 20 -65 0 -41 43 -136 66 -145
8 -3 11 -10 8 -15 -10 -16 29 -77 55 -86 21 -7 26 -20 47 -122 22 -104 23
-116 8 -127 -14 -10 -14 -19 -4 -67 13 -64 32 -75 28 -16 -2 42 4 56 21 46 6
-4 9 -29 8 -56 -2 -40 -6 -50 -21 -52 -34 -5 -31 -28 3 -28 27 0 31 -3 31 -27
0 -16 11 -46 25 -68 48 -78 17 -130 -38 -62 -16 21 -33 37 -37 37 -5 0 -15
-12 -22 -27 -12 -26 -18 -28 -70 -28 -83 0 -90 -12 -51 -88 42 -83 58 -160 50
-244 -20 -215 -28 -263 -48 -309 -23 -54 -45 -125 -60 -195 -5 -25 -27 -89
-48 -141 -22 -53 -43 -118 -47 -144 -3 -27 -13 -61 -20 -76 -8 -15 -14 -36
-14 -47 0 -11 -5 -23 -12 -27 -16 -10 -47 32 -41 56 6 24 -21 37 -42 20 -10
-8 -15 -31 -15 -65 0 -33 -9 -72 -24 -106 -13 -30 -27 -70 -31 -89 -4 -19 -17
-50 -29 -70 -12 -19 -30 -62 -40 -95 -10 -33 -23 -69 -29 -80 -36 -65 -56
-113 -57 -130 0 -20 -21 -66 -52 -115 -8 -14 -21 -70 -27 -125 -6 -55 -15
-103 -19 -107 -5 -5 -44 -3 -88 2 -44 6 -194 10 -336 8 -141 -2 -260 0 -264 5
-4 4 2 17 14 30 27 29 27 31 -1 59 -22 22 -25 23 -50 8 -15 -9 -37 -32 -49
-50 -27 -40 -97 -62 -165 -53 -45 6 -57 21 -23 30 11 3 20 13 20 22 0 9 16 41
35 71 19 30 40 66 45 80 6 14 36 40 66 58 59 36 69 65 24 69 -14 1 -36 5 -50
8 -18 5 -33 0 -52 -14 -22 -18 -26 -27 -22 -54 5 -29 1 -36 -51 -81 -32 -28
-76 -54 -102 -62 -25 -8 -51 -19 -58 -24 -9 -7 -21 -7 -38 1 -42 19 -57 6 -57
-50 0 -59 -3 -60 -115 -49 -44 4 -106 10 -139 13 -32 3 -65 12 -73 21 -8 8
-30 21 -49 29 -19 8 -34 20 -34 28 0 18 -32 47 -53 47 -23 0 -32 -21 -13 -32
37 -21 5 -58 -51 -58 -15 0 -43 -9 -61 -20 -42 -26 -84 -26 -107 0 -15 16 -16
24 -7 45 7 15 8 26 2 30 -10 6 -69 -9 -104 -27 -13 -7 -21 -6 -27 4 -6 9 -2
16 11 22 12 5 20 19 20 32 0 13 9 28 20 34 17 9 20 20 18 52 -1 23 3 43 9 45
47 16 53 53 8 53 -27 0 -45 -25 -45 -63 0 -14 -7 -27 -14 -30 -8 -3 -18 -22
-22 -42 -4 -25 -22 -52 -56 -86 -27 -27 -54 -49 -60 -49 -7 0 -23 -11 -37 -24
-24 -22 -36 -24 -146 -27 l-120 -4 -5 65 c-3 36 -2 97 2 135 5 39 10 99 13
134 7 99 16 153 31 183 8 15 14 45 14 68 0 105 41 281 75 320 7 8 16 30 20 48
6 31 5 33 -32 47 -21 9 -45 20 -53 25 -8 6 -25 14 -36 19 -24 11 -58 54 -68
89 -4 12 -12 22 -18 22 -6 0 -21 11 -34 25 -12 13 -35 27 -51 31 -15 4 -56 20
-90 35 -33 16 -82 33 -108 39 -25 6 -58 18 -73 28 -19 12 -41 16 -79 14 -43
-3 -64 2 -114 27 -34 17 -68 31 -75 31 -8 0 -33 9 -56 19 -24 11 -70 30 -103
42 -87 32 -113 42 -185 77 -97 47 -241 91 -262 80 -10 -5 -18 -14 -18 -21 0
-17 79 -82 110 -92 14 -4 36 -16 49 -26 13 -11 30 -19 38 -19 8 0 11 -5 7 -12
-4 -7 -3 -8 5 -4 6 4 33 -3 58 -15 26 -13 57 -22 68 -21 11 1 29 -2 40 -8 34
-16 167 -65 240 -90 39 -13 94 -36 124 -52 30 -15 59 -28 66 -28 6 0 22 -11
35 -25 15 -16 30 -23 44 -19 15 3 24 -1 31 -18 8 -18 17 -23 46 -21 30 2 38
-3 53 -28 9 -16 24 -29 31 -29 8 0 29 -9 47 -20 18 -11 44 -20 56 -20 15 0 26
-7 29 -19 3 -11 18 -24 34 -30 18 -6 29 -17 29 -28 0 -11 5 -24 10 -29 7 -7 4
-16 -10 -29 -11 -10 -20 -26 -20 -35 0 -23 -38 -136 -65 -195 -13 -27 -26 -66
-28 -86 -3 -20 -23 -83 -45 -140 -39 -102 -73 -225 -73 -263 0 -11 -8 -25 -18
-32 -9 -7 -23 -32 -30 -56 -29 -97 -11 -88 -183 -89 -84 0 -173 -2 -197 -4
-24 -2 -54 0 -66 5 -20 7 -115 144 -115 164 0 4 -25 49 -55 101 -30 51 -55 96
-55 100 0 4 -16 25 -35 46 -21 24 -35 49 -35 65 0 18 -15 43 -45 75 -35 37
-45 56 -45 82 0 22 -8 41 -21 53 -34 31 -69 91 -69 121 0 16 -14 48 -30 72
-17 24 -30 54 -30 66 0 22 -25 64 -33 55 -12 -14 -14 -100 -1 -111 7 -6 16
-36 20 -67 3 -31 14 -69 23 -85 13 -23 13 -31 3 -48 -8 -12 -10 -33 -6 -55 7
-39 24 -56 24 -25 0 30 12 26 37 -12 33 -48 37 -66 19 -73 -22 -9 -6 -34 43
-68 34 -24 41 -35 46 -75 4 -25 10 -55 15 -66 4 -11 11 -28 14 -37 9 -28 34
-21 38 10 l3 27 22 -35 c16 -26 19 -39 12 -53 -15 -26 -2 -73 25 -91 13 -8 31
-32 41 -53 10 -21 24 -46 33 -56 8 -9 12 -20 8 -24 -4 -4 -163 -6 -353 -5
l-347 1 -23 28 c-21 26 -36 52 -98 175 -13 25 -29 49 -34 53 -6 3 -11 21 -11
39 0 18 -6 41 -14 52 -14 19 -15 19 -22 0 -4 -10 -2 -33 5 -50 7 -17 17 -48
22 -70 5 -21 20 -62 33 -90 32 -72 46 -118 40 -127 -6 -10 -104 -12 -128 -3
-9 3 -16 17 -16 30 0 32 -28 31 -36 -1 -8 -31 -17 -34 -146 -37 -107 -3 -132
2 -110 24 7 7 12 22 12 33 0 12 16 44 35 71 19 27 35 53 35 59 0 35 25 87 60
124 23 24 40 53 40 66 0 12 6 28 14 34 7 6 16 32 19 57 5 49 38 94 68 94 36 0
60 33 69 97 4 34 15 74 24 90 41 75 56 110 56 137 0 16 4 43 10 60 37 118 43
147 32 158 -19 19 -14 99 7 131 19 30 17 77 -3 77 -19 0 -50 -99 -52 -166 -1
-33 -9 -75 -18 -92 -9 -17 -16 -44 -16 -60 0 -16 -12 -63 -26 -103 -14 -41
-28 -91 -31 -111 -3 -21 -9 -38 -14 -38 -5 0 -9 -11 -9 -25 0 -36 -42 -62 -76
-46 -47 21 -69 14 -87 -27 -26 -60 -22 -107 14 -152 30 -37 32 -60 4 -60 -17
0 -95 -90 -95 -111 0 -8 -13 -28 -28 -45 -16 -16 -36 -56 -46 -89 -10 -33 -22
-64 -27 -69 -5 -6 -9 -23 -9 -38 0 -30 -27 -64 -63 -77 -25 -10 -74 -4 -83 10
-3 5 -14 9 -25 9 -17 0 -20 -6 -17 -32 l3 -33 80 1 c483 4 4892 3 4988 -2 135
-6 157 -1 157 38 0 24 -26 34 -53 19 -12 -7 -64 -13 -115 -15 -73 -3 -97 0
-108 12 -19 18 -12 53 20 96 39 51 54 90 49 119 -4 22 -2 27 14 27 24 0 105
76 126 120 10 18 17 44 17 56 0 13 9 38 20 56 11 18 20 42 20 53 0 11 7 29 15
39 8 11 15 30 15 42 0 12 8 37 17 56 9 18 22 45 30 60 7 14 13 54 13 87 0 46
4 63 15 67 10 4 15 19 15 43 0 21 7 46 15 57 8 10 15 29 15 41 0 20 40 111 85
193 7 14 19 39 25 55 7 17 16 37 21 45 5 8 18 35 30 60 11 25 27 58 35 73 8
16 14 50 14 76 0 26 4 51 9 57 5 5 12 38 15 74 2 36 9 92 15 125 6 33 13 78
17 100 4 22 13 49 21 61 7 12 13 34 12 50 -2 16 7 45 19 64 12 19 22 45 22 57
0 12 5 25 10 28 18 11 12 247 -7 312 -14 46 -17 88 -15 204 2 143 2 146 -22
168 -33 31 -52 22 -60 -28 -8 -51 -19 -57 -55 -32 -38 27 -67 111 -49 141 10
16 10 25 -5 49 -11 19 -17 49 -17 83 2 78 -10 138 -26 138 -8 0 -14 -9 -14
-21 0 -15 -4 -19 -16 -15 -9 3 -18 6 -20 6 -12 0 -2 41 11 46 8 4 15 10 15 15
0 5 -7 9 -15 9 -17 0 -22 55 -6 65 38 23 64 167 34 183 -14 9 -15 14 -3 42 12
29 11 33 -3 42 -9 5 -17 20 -17 32 0 13 -6 26 -13 29 -7 2 -23 25 -35 51 -16
31 -30 46 -43 46 -11 0 -23 10 -28 23 -5 12 -28 40 -52 63 -78 73 -119 122
-119 144 0 11 -10 34 -22 50 -21 29 -21 30 -2 51 14 16 15 23 7 26 -7 3 -13
17 -13 33 0 19 -6 29 -20 33 -16 4 -20 14 -20 51 0 37 -5 51 -25 68 -29 25
-31 40 -10 58 19 16 19 40 0 40 -9 0 -18 8 -22 18 -3 9 -12 24 -20 33 -10 12
-11 23 -3 43 9 23 7 28 -16 38 -19 9 -24 17 -20 34 4 14 1 26 -7 31 -22 13
-42 56 -28 61 18 6 13 18 -14 30 -15 7 -25 20 -25 32 0 30 -18 60 -35 60 -8 0
-15 -9 -15 -20z m-2659 -3562 c10 -13 18 -29 19 -35 0 -18 -19 -16 -26 2 -4 8
-12 15 -20 15 -8 0 -14 9 -14 20 0 26 17 25 41 -2z m262 -259 c4 -16 -1 -40
-13 -64 -11 -22 -17 -48 -14 -58 3 -11 1 -23 -6 -27 -9 -6 -9 -12 0 -29 8 -16
8 -26 1 -35 -6 -7 -9 -36 -7 -65 1 -30 -3 -63 -11 -78 -7 -14 -13 -34 -13 -45
0 -11 -8 -22 -20 -25 -22 -6 -25 -14 -10 -29 19 -19 1 -251 -21 -265 -11 -6
-11 -11 -1 -21 17 -17 15 -54 -4 -84 l-17 -24 -34 20 c-29 17 -35 18 -39 5
-11 -26 -52 -18 -68 14 -14 26 -14 32 0 60 9 17 20 31 25 31 18 0 50 87 43
115 -4 14 -1 31 6 40 8 9 10 28 6 50 -6 31 -4 35 14 35 13 0 20 7 20 19 0 11
7 27 16 36 11 11 14 25 10 46 -3 19 0 42 9 59 8 16 15 40 15 54 0 14 6 40 14
58 19 45 46 137 46 158 0 28 31 93 39 84 5 -5 11 -21 14 -35z m2803 -115 c-3
-9 -6 -18 -6 -20 0 -2 -7 -4 -15 -4 -13 0 -14 4 -5 20 13 24 35 27 26 4z"/>,
    <path d="M3606 10065 c5 -19 2 -25 -9 -25 -26 0 -72 -49 -60 -61 8 -8 15 -5
25 10 20 32 48 27 48 -9 0 -60 32 -27 43 44 7 44 -5 66 -34 66 -16 0 -18 -5
-13 -25z"/>,
    <path d="M6537 10073 c-12 -11 -8 -40 8 -53 8 -7 15 -24 15 -38 0 -13 9 -40
21 -59 12 -19 19 -43 16 -55 -3 -11 -2 -28 3 -37 12 -20 34 -100 35 -123 0 -9
3 -20 8 -24 4 -4 7 -20 7 -35 0 -15 5 -40 11 -56 6 -15 14 -46 19 -68 4 -22
14 -56 21 -75 6 -19 14 -42 16 -50 4 -13 5 -13 13 0 14 21 12 86 -4 155 -8 33
-17 96 -21 140 -4 44 -13 92 -21 107 -8 15 -14 38 -14 52 0 14 -16 55 -35 93
-19 37 -35 77 -35 89 0 34 -42 59 -63 37z"/>,
    <path d="M5534 10046 c-8 -21 3 -36 27 -36 15 0 20 6 17 22 -4 27 -35 37 -44
14z"/>,
    <path d="M6442 10032 c-5 -15 -7 -42 -4 -60 8 -59 32 -31 32 38 0 48 -15 60
-28 22z"/>,
    <path d="M3945 10040 c-3 -5 -17 -10 -29 -10 -13 0 -29 -7 -36 -15 -16 -19
-64 -19 -84 0 -19 19 -33 1 -16 -20 15 -18 45 -19 89 -4 23 8 35 8 45 0 11 -9
20 -7 40 12 14 13 26 29 26 36 0 14 -27 15 -35 1z"/>,
    <path d="M4760 9985 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>,
    <path d="M5690 9980 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>,
    <path d="M5970 9979 c0 -18 2 -19 15 -9 8 7 15 16 15 21 0 5 -7 9 -15 9 -9 0
-15 -9 -15 -21z"/>,
    <path d="M3480 9935 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>,
    <path d="M3587 9944 c-4 -4 -2 -13 4 -20 15 -18 -17 -114 -37 -114 -11 0 -13
-9 -8 -39 4 -24 2 -41 -5 -45 -6 -4 -11 -21 -11 -37 0 -26 3 -30 23 -27 18 2
24 12 31 53 4 28 11 57 13 65 24 66 16 190 -10 164z"/>,
    <path d="M5117 9896 c-7 -16 1 -26 20 -26 7 0 13 9 13 20 0 23 -25 27 -33 6z" />,
    <path d="M5497 9903 c-14 -13 -6 -24 15 -21 27 4 36 28 11 28 -11 0 -23 -3
-26 -7z"/>,
    <path d="M4750 9880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>,
    <path d="M3477 9854 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z" />,
    <path d="M4890 9845 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>,
    <path d="M3446 9812 c-2 -4 -1 -14 3 -21 6 -10 10 -9 14 3 6 16 -8 32 -17 18z" />,
    <path d="M5306 9803 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />,
    <path d="M5680 9794 c0 -10 50 -54 61 -54 13 0 11 22 -5 43 -12 16 -56 25 -56
11z"/>,
    <path d="M6506 9783 c-12 -13 -5 -36 22 -72 16 -20 31 -44 35 -53 4 -11 13
-15 26 -11 26 7 27 19 1 33 -10 6 -24 27 -29 48 -10 35 -42 68 -55 55z"/>,
    <path d="M4220 9750 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>,
    <path d="M4107 9734 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21 -31
12z"/>,
    <path d="M5215 9710 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
-10z"/>,
    <path d="M4750 9700 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>,
    <path d="M4837 9704 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z" />,
    <path d="M5272 9664 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"/>,
    <path d="M5542 9668 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>,
    <path d="M6380 9665 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>,
    <path d="M3480 9655 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>,
    <path d="M5090 9656 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
0 -10 -6 -10 -14z"/>,
    <path d="M4430 9645 c-10 -12 -10 -15 4 -15 9 0 16 7 16 15 0 8 -2 15 -4 15
-2 0 -9 -7 -16 -15z"/>,
    <path d="M6335 9629 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />,
    <path d="M3527 9624 c-15 -15 -7 -45 11 -42 9 2 17 12 17 22 0 20 -16 31 -28
20z"/>,
    <path d="M6375 9619 c-11 -16 5 -80 26 -110 10 -15 16 -34 13 -42 -3 -9 -1
-23 4 -32 16 -30 23 94 8 139 -14 41 -40 64 -51 45z"/>,
    <path d="M3343 9608 c2 -7 10 -13 17 -13 7 0 15 6 18 13 2 7 -5 12 -18 12 -13
0 -20 -5 -17 -12z"/>,
    <path d="M3477 9565 c6 -17 25 -15 31 3 2 7 -5 12 -17 12 -14 0 -19 -5 -14
-15z"/>,
    <path d="M4510 9576 c0 -2 7 -9 15 -16 12 -10 15 -10 15 4 0 9 -7 16 -15 16
-8 0 -15 -2 -15 -4z"/>,
    <path d="M5436 9561 c-17 -18 -16 -20 3 -26 26 -8 41 1 41 26 0 24 -22 25 -44
0z"/>,
    <path d="M5707 9562 c-20 -22 -13 -32 20 -32 24 0 27 7 17 34 -8 21 -17 20
-37 -2z"/>,
    <path d="M5251 9557 c-6 -8 -8 -17 -4 -20 7 -8 33 11 33 24 0 13 -16 11 -29
-4z"/>,
    <path d="M3530 9547 c0 -22 11 -27 31 -14 25 15 43 -7 28 -34 -12 -23 -11 -29
4 -29 15 1 57 35 57 47 0 10 -43 29 -87 38 -25 5 -33 3 -33 -8z"/>,
    <path d="M3807 9523 c-13 -12 -7 -23 12 -23 13 0 21 -8 23 -22 2 -13 11 -24
21 -26 26 -5 20 20 -12 51 -31 29 -34 30 -44 20z"/>,
    <path d="M5776 9523 c-3 -3 -3 -10 0 -14 7 -12 34 -11 34 0 0 11 -26 22 -34
14z"/>,
    <path d="M3736 9504 c-11 -7 -18 -16 -15 -18 2 -2 13 2 23 10 11 7 18 16 15
18 -2 2 -13 -2 -23 -10z"/>,
    <path d="M5590 9510 c0 -5 6 -13 14 -17 8 -4 15 -17 18 -28 4 -26 32 -26 36
-1 3 12 -5 27 -19 37 -26 20 -49 24 -49 9z"/>,
    <path d="M3485 9480 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z" />,
    <path d="M3763 9474 c-7 -18 17 -38 30 -25 9 9 -1 41 -14 41 -5 0 -12 -7 -16
-16z"/>,
    <path d="M3952 9478 c-16 -16 -15 -28 3 -28 8 0 15 2 15 4 0 2 3 11 6 20 7 19
-7 21 -24 4z"/>,
    <path d="M4606 9473 c-9 -22 10 -41 25 -26 13 13 5 43 -10 43 -5 0 -12 -8 -15
-17z"/>,
    <path d="M5550 9470 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>,
    <path d="M4132 9461 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />,
    <path d="M3895 9431 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />,
    <path d="M4665 9431 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />,
    <path d="M4832 9428 c5 -15 37 -18 41 -3 1 6 -9 12 -22 13 -16 3 -22 -1 -19
-10z"/>,
    <path d="M4740 9420 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>,
    <path d="M5310 9415 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>,
    <path d="M5504 9418 c-7 -11 38 -58 57 -58 17 0 9 32 -13 51 -27 22 -34 23
-44 7z"/>,
    <path d="M7309 9413 c-1 -10 -2 -64 -3 -121 -3 -113 5 -136 42 -121 21 8 22
14 22 114 0 59 -5 115 -10 126 -13 24 -50 25 -51 2z"/>,
    <path d="M3856 9402 c-2 -4 0 -14 5 -22 8 -13 10 -13 18 0 10 15 6 30 -9 30
-5 0 -11 -4 -14 -8z"/>,
    <path d="M5357 9394 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>,
    <path d="M3660 9370 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>,
    <path d="M3066 9322 c-15 -22 -38 -45 -52 -52 -23 -13 -104 -122 -104 -141 0
-4 -8 -14 -18 -21 -28 -20 -72 -95 -71 -120 1 -21 2 -21 12 5 6 15 14 27 19
27 4 0 8 5 8 11 0 6 15 25 33 43 60 62 112 121 137 158 14 20 37 46 53 58 16
13 27 31 27 46 0 34 -13 30 -44 -14z"/>,
    <path d="M3519 9329 c-7 -14 -8 -29 -2 -43 8 -15 5 -29 -8 -51 -31 -50 -32
-56 -16 -73 21 -21 27 -13 34 40 3 24 13 51 21 61 13 14 13 20 4 26 -9 5 -11
17 -6 34 8 33 -10 36 -27 6z"/>,
    <path d="M5584 9324 c-10 -40 16 -61 55 -44 17 7 31 14 31 16 0 10 -53 54 -65
54 -8 0 -18 -12 -21 -26z"/>,
    <path d="M5694 9301 c10 -16 46 -20 46 -5 0 8 -10 14 -26 14 -14 0 -23 -4 -20
-9z"/>,
    <path d="M4420 9284 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z" />,
    <path d="M3246 9258 c-22 -31 -21 -52 2 -42 9 4 29 7 44 6 39 -2 43 13 9 37
-36 26 -36 26 -55 -1z"/>,
    <path d="M4580 9265 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>,
    <path d="M6661 9208 c-1 -21 4 -38 9 -38 12 0 12 27 0 55 -7 16 -9 13 -9 -17z" />,
    <path d="M3685 9220 c-10 -16 4 -30 30 -30 13 0 27 -5 31 -11 3 -6 22 -9 43
-6 48 6 81 -8 81 -33 0 -24 -13 -26 -30 -5 -18 22 -35 18 -48 -10 -18 -39 -14
-50 8 -30 16 14 23 16 32 7 15 -15 48 -16 48 -2 0 6 6 10 14 10 7 0 25 9 39
20 14 11 30 20 36 20 6 0 11 -9 11 -20 0 -13 -7 -20 -20 -20 -11 0 -20 -7 -20
-15 0 -8 9 -15 20 -15 11 0 23 7 26 15 8 19 33 19 55 -1 12 -11 22 -13 36 -5
10 5 26 12 36 15 9 3 17 10 17 16 0 17 -31 24 -52 11 -12 -8 -24 -9 -31 -3 -7
5 -24 12 -39 16 -19 4 -28 13 -28 26 0 32 -22 35 -45 7 -25 -32 -51 -35 -58
-7 -3 12 -14 20 -26 20 -12 0 -24 7 -27 15 -5 11 -11 12 -25 4 -15 -7 -28 -6
-51 5 -36 18 -54 20 -63 6z"/>,
    <path d="M4672 9221 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />,
    <path d="M4735 9200 c-8 -13 13 -40 30 -40 7 0 18 6 24 13 8 11 5 16 -15 25
-31 15 -31 15 -39 2z"/>,
    <path d="M3342 9161 c0 -27 5 -41 17 -44 9 -3 24 -8 34 -12 12 -5 17 -2 17 9
0 19 9 20 32 2 22 -16 22 -103 0 -129 -16 -17 -15 -24 17 -128 5 -15 4 -34 -1
-43 -11 -20 -38 -21 -38 -1 0 8 -4 15 -10 15 -5 0 -18 10 -29 21 -13 14 -26
19 -37 15 -15 -6 -16 -8 0 -24 20 -23 20 -36 1 -52 -8 -7 -15 -16 -15 -21 0
-12 37 -11 45 1 11 18 24 10 27 -17 2 -23 9 -29 34 -31 27 -3 35 2 53 32 22
36 21 83 -3 92 -18 7 -18 120 -1 134 19 16 19 36 0 44 -22 8 -18 26 5 26 16 0
20 7 20 30 0 19 -7 33 -20 40 -11 6 -20 17 -20 25 0 8 -8 15 -17 15 -10 0 -31
9 -48 20 -48 32 -64 27 -63 -19z"/>,
    <path d="M4135 9190 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />,
    <path d="M4817 9193 c-8 -7 11 -33 24 -33 5 0 9 9 9 20 0 19 -20 27 -33 13z" />,
    <path d="M4917 9155 c29 -36 43 -44 43 -22 -1 19 -37 57 -55 57 -12 0 -10 -7
12 -35z"/>,
    <path d="M6357 9163 c-11 -10 -8 -43 3 -43 12 0 31 35 23 44 -8 8 -18 8 -26
-1z"/>,
    <path d="M4277 9143 c-18 -17 -5 -33 27 -33 19 0 36 -6 39 -12 2 -7 8 -10 12
-6 5 4 -4 19 -19 33 -27 25 -47 31 -59 18z"/>,
    <path d="M3685 9131 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />,
    <path d="M4162 9128 c5 -14 85 -35 94 -25 4 3 4 13 1 22 -8 19 -101 22 -95 3z" />,
    <path d="M4681 9110 c17 -26 39 -38 39 -22 0 5 -12 18 -27 28 l-28 18 16 -24z" />,
    <path d="M5177 9106 c-16 -12 -16 -14 -1 -20 20 -8 34 1 34 20 0 17 -10 17
-33 0z"/>,
    <path d="M4507 9103 c-14 -4 -16 -8 -6 -19 12 -15 41 -19 52 -7 7 7 -11 34
-22 32 -3 -1 -14 -3 -24 -6z"/>,
    <path d="M5070 9094 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z" />,
    <path d="M5264 9096 c-7 -19 53 -76 72 -69 9 3 14 -2 14 -15 0 -21 48 -62 72
-62 27 0 29 14 6 32 -20 15 -21 20 -10 38 12 19 11 20 -27 14 -34 -5 -40 -3
-45 14 -4 12 -15 24 -26 27 -10 3 -21 12 -24 20 -7 19 -25 19 -32 1z"/>,
    <path d="M5625 9100 c-3 -5 5 -15 20 -22 14 -6 28 -22 31 -35 9 -34 38 -29 52
10 17 48 16 50 -22 43 -20 -4 -37 -2 -41 4 -8 13 -32 13 -40 0z"/>,
    <path d="M6056 9101 c-9 -13 17 -39 47 -47 31 -7 34 2 11 34 -15 22 -48 30
-58 13z"/>,
    <path d="M3590 9076 c0 -21 33 -56 55 -57 43 -4 85 2 85 11 0 6 -12 10 -28 10
-15 0 -44 11 -64 25 -43 29 -48 30 -48 11z"/>,
    <path d="M4474 9049 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>,
    <path d="M4562 9049 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>,
    <path d="M4830 9040 c-8 -6 -27 -7 -42 -4 -37 7 -39 -22 -3 -38 20 -9 28 -8
43 5 23 21 52 22 52 2 0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -19 15
-10 0 -21 7 -25 15 -6 17 -24 19 -46 5z"/>,
    <path d="M5781 9021 c-13 -23 -5 -31 31 -31 21 0 28 5 28 19 0 17 -18 31 -40
31 -5 0 -14 -8 -19 -19z"/>,
    <path d="M5870 9020 c0 -11 12 -26 29 -35 41 -20 46 -33 25 -56 -17 -18 -17
-21 -1 -37 15 -14 21 -15 38 -4 15 10 24 10 34 2 10 -8 16 -7 23 7 10 17 11
17 31 -1 43 -39 59 27 16 69 -18 18 -35 25 -65 25 -22 0 -40 3 -40 8 0 10 -58
42 -76 42 -8 0 -14 -9 -14 -20z m168 -83 c3 -16 1 -17 -17 -7 -25 13 -28 33
-4 28 10 -2 19 -11 21 -21z"/>,
    <path d="M4993 9013 c-18 -6 -16 -23 1 -23 17 0 30 16 19 23 -5 2 -14 2 -20 0z" />,
    <path d="M5463 8990 c4 -14 7 -33 7 -42 0 -10 4 -18 10 -18 17 0 100 -63 100
-76 0 -7 10 -16 21 -19 18 -6 20 -13 16 -42 -5 -30 -4 -33 9 -22 27 22 39 -6
14 -31 -21 -21 -32 -22 -72 -5 -15 6 -18 2 -18 -19 0 -34 42 -57 68 -38 9 7
25 15 35 18 9 4 17 13 17 20 0 17 16 18 26 2 4 -6 2 -14 -4 -16 -18 -6 -14
-32 4 -32 17 0 54 36 54 51 0 5 -9 13 -20 19 -13 7 -20 21 -20 41 0 26 -6 33
-40 47 -22 9 -40 22 -40 28 0 26 -27 65 -50 71 -14 3 -33 14 -43 24 -10 11
-22 19 -27 19 -5 0 -19 10 -31 23 l-22 22 6 -25z"/>,
    <path d="M3140 8949 c0 -25 33 -22 38 4 2 12 -3 17 -17 17 -15 0 -21 -6 -21
-21z"/>,
    <path d="M5112 8949 c2 -20 8 -24 39 -23 19 1 49 5 65 9 l29 8 -40 8 c-22 4
-53 11 -68 15 -26 6 -28 4 -25 -17z"/>,
    <path d="M7310 8934 c-11 -28 -13 -97 -4 -131 7 -25 33 -30 52 -11 18 18 15
133 -4 152 -22 22 -33 20 -44 -10z"/>,
    <path d="M3197 8903 c8 -24 44 -53 66 -53 10 0 28 9 39 20 18 17 20 22 9 33
-11 9 -14 9 -18 0 -6 -18 -48 -16 -63 2 -18 21 -41 19 -33 -2z"/>,
    <path d="M4610 8905 c7 -8 16 -15 21 -15 5 0 9 7 9 15 0 9 -9 15 -21 15 -18 0
-19 -2 -9 -15z"/>,
    <path d="M4795 8911 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />,
    <path d="M4653 8864 c-7 -19 0 -26 21 -18 20 8 21 20 1 28 -9 3 -18 -1 -22
-10z"/>,
    <path d="M3810 8855 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>,
    <path d="M3900 8855 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>,
    <path d="M4835 8859 c-4 -6 -4 -13 -1 -16 8 -8 36 5 36 17 0 13 -27 13 -35 -1z" />,
    <path d="M6131 8843 c-12 -13 -12 -17 3 -25 13 -7 16 -17 12 -34 -7 -25 10
-33 30 -15 5 5 22 11 39 13 31 3 51 38 21 38 -8 0 -31 9 -51 20 -42 23 -37 23
-54 3z"/>,
    <path d="M5370 8840 c-8 -5 -11 -12 -7 -16 4 -4 15 0 24 9 18 18 8 23 -17 7z" />,
    <path d="M3300 8825 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>,
    <path d="M6005 8829 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z" />,
    <path d="M3774 8815 c-8 -21 6 -41 17 -24 12 19 11 39 -1 39 -6 0 -13 -7 -16
-15z"/>,
    <path d="M3950 8811 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z" />,
    <path d="M4890 8810 c0 -11 8 -24 18 -27 9 -4 22 -13 28 -22 13 -17 44 -8 44
14 0 8 -8 15 -18 15 -9 0 -23 9 -30 20 -16 26 -42 26 -42 0z"/>,
    <path d="M4980 8811 c0 -11 9 -23 21 -26 27 -9 33 -1 19 24 -14 26 -40 27 -40
2z"/>,
    <path d="M6290 8810 c0 -11 -6 -20 -14 -20 -8 0 -17 -14 -21 -32 -4 -18 -12
-39 -17 -45 -14 -17 3 -16 26 2 30 23 63 18 82 -10 9 -14 15 -27 13 -28 -2 -2
-16 -11 -31 -20 -16 -10 -28 -23 -28 -30 0 -15 79 -59 92 -51 13 8 9 46 -7 78
-12 22 -13 31 -4 37 8 4 9 19 4 40 -4 19 -7 49 -8 67 0 31 -27 48 -27 17 0 -8
-7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -8 0 -15 -9 -15 -20z"/>,
    <path d="M6748 8775 c-5 -46 1 -85 13 -85 19 0 22 33 7 78 l-15 47 -5 -40z" />,
    <path d="M5114 8775 c-15 -10 -16 -16 -7 -26 14 -15 43 -5 43 15 0 22 -15 27
-36 11z"/>,
    <path d="M5490 8780 c-11 -6 -12 -12 -4 -20 13 -13 44 -2 44 16 0 16 -18 18
-40 4z"/>,
    <path d="M3712 8763 c5 -25 28 -28 28 -4 0 12 -6 21 -16 21 -9 0 -14 -7 -12
-17z"/>,
    <path d="M3900 8765 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>,
    <path d="M4370 8760 c0 -11 5 -20 11 -20 6 0 20 -11 31 -25 11 -14 25 -25 33
-25 7 0 16 -7 19 -16 5 -13 15 -15 41 -11 23 4 38 1 46 -9 6 -8 20 -14 30 -14
10 0 35 -11 55 -25 27 -19 45 -24 65 -20 15 4 30 1 34 -5 4 -6 15 -8 24 -5 12
3 31 -7 55 -29 42 -38 117 -60 256 -75 82 -8 107 -16 160 -46 14 -8 35 -15 48
-15 13 0 31 -4 41 -9 10 -6 40 -11 67 -13 27 -1 56 -8 65 -15 10 -8 57 -15
130 -18 191 -8 726 -8 738 -1 21 13 2 24 -56 31 -38 5 -67 15 -86 31 -16 13
-38 24 -49 24 -11 0 -31 9 -45 20 -27 21 -43 19 -43 -6 0 -8 -4 -12 -10 -9 -5
3 -7 19 -3 35 3 18 0 36 -7 45 -19 23 -30 18 -30 -14 0 -31 -34 -61 -68 -61
-13 0 -21 -9 -25 -29 -4 -18 -14 -30 -27 -34 -25 -6 -40 20 -19 34 10 6 7 9
-13 9 -15 0 -30 5 -33 10 -10 16 -22 12 -28 -10 -4 -16 -14 -20 -49 -22 -23 0
-50 0 -58 1 -8 2 -32 2 -54 2 -21 -1 -44 3 -51 9 -10 8 -30 8 -71 1 -41 -8
-61 -8 -70 0 -8 6 -31 17 -51 24 -44 15 -53 48 -15 53 39 6 55 82 18 82 -8 0
-19 -8 -25 -17 -9 -17 -11 -17 -25 2 -8 11 -16 15 -16 9 0 -5 5 -15 10 -20 8
-8 6 -15 -5 -24 -8 -7 -15 -21 -15 -31 0 -31 -16 -30 -52 6 -42 40 -92 52
-142 34 -29 -10 -42 -10 -77 1 -24 8 -50 23 -58 32 -18 20 -42 23 -58 7 -13
-13 -36 -3 -28 11 7 11 -31 36 -68 45 -15 4 -31 15 -36 24 -9 14 -12 14 -33
-7 -30 -27 -55 -28 -63 -2 -7 23 -33 27 -41 5 -9 -21 -32 -19 -39 5 -3 11 -13
20 -21 20 -8 0 -28 10 -45 22 -30 22 -31 23 -45 4 -13 -19 -14 -18 -35 7 -11
15 -29 27 -40 27 -10 0 -19 7 -19 15 0 8 -7 15 -15 15 -8 0 -15 -9 -15 -20z"/>,
    <path d="M4840 8770 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>,
    <path d="M3800 8761 c0 -5 -12 -12 -27 -15 -25 -7 -25 -8 -8 -24 13 -13 19
-14 22 -4 3 7 11 19 19 26 16 17 18 26 4 26 -5 0 -10 -4 -10 -9z"/>,
    <path d="M6038 8763 c-23 -6 -23 -26 0 -46 29 -27 44 -21 40 15 -3 33 -11 39
-40 31z"/>,
    <path d="M5352 8728 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>,
    <path d="M5453 8723 c-7 -3 -13 -8 -13 -13 0 -11 58 -40 82 -40 23 0 24 16 1
23 -10 4 -27 13 -38 20 -11 8 -26 13 -32 10z"/>,
    <path d="M3480 8700 c-20 -20 -27 -50 -12 -50 11 0 60 58 54 65 -10 9 -22 5
-42 -15z"/>,
    <path d="M5777 8692 c-10 -10 -17 -24 -17 -30 0 -16 32 -15 54 1 16 12 16 14
-2 30 -18 16 -20 16 -35 -1z"/>,
    <path d="M2607 8663 c-20 -20 -37 -40 -37 -44 0 -5 -20 -20 -45 -35 -25 -14
-45 -31 -45 -36 0 -6 -20 -35 -45 -64 -25 -28 -45 -56 -45 -60 0 -4 -20 -38
-45 -75 -25 -37 -45 -71 -45 -76 0 -5 -20 -38 -45 -73 -25 -35 -45 -70 -45
-78 0 -7 -13 -30 -29 -50 -37 -48 -55 -85 -75 -153 -9 -31 -23 -62 -31 -69 -8
-7 -15 -23 -15 -37 0 -13 -5 -34 -12 -46 -7 -12 -15 -48 -19 -80 -4 -32 -14
-66 -22 -75 -8 -9 -20 -35 -26 -57 -6 -22 -18 -49 -26 -59 -35 -46 -6 -143 40
-132 38 10 47 -14 39 -96 -3 -40 -12 -84 -19 -98 -8 -14 -16 -33 -19 -43 -3
-9 -17 -22 -31 -27 -21 -8 -25 -16 -25 -50 0 -27 -4 -40 -14 -40 -8 0 -21 -22
-30 -52 -9 -29 -27 -73 -40 -98 -13 -25 -27 -61 -31 -80 -8 -37 -42 -107 -56
-116 -5 -3 -9 -18 -9 -34 0 -16 -4 -31 -9 -34 -5 -3 -12 -36 -16 -72 -5 -51
-14 -77 -36 -108 -22 -29 -28 -47 -24 -64 3 -13 2 -29 -3 -35 -20 -27 -34 -97
-27 -133 6 -36 8 -38 21 -19 8 10 14 26 14 34 0 9 14 43 30 76 17 33 30 66 30
75 0 8 9 37 19 65 11 27 25 70 31 95 24 89 42 129 71 156 18 17 27 34 24 45
-6 19 39 121 61 140 8 6 14 30 14 53 0 27 7 49 18 62 41 44 72 92 72 110 0 11
4 24 9 29 19 19 42 145 31 166 -13 23 1 55 29 64 46 14 91 112 91 200 0 60 30
200 64 308 17 52 27 111 31 174 4 89 8 101 55 197 27 55 73 131 102 167 61 76
181 179 207 179 24 0 45 -33 60 -94 7 -27 19 -63 28 -80 11 -21 17 -59 18
-116 2 -99 13 -170 26 -170 5 0 9 6 9 13 0 8 9 22 19 31 11 10 17 24 14 32 -3
7 7 28 21 45 14 17 26 39 26 49 0 10 9 25 21 33 22 16 10 33 -16 24 -7 -3 -21
0 -32 8 -17 13 -16 14 10 24 16 6 25 15 22 21 -8 13 -55 13 -55 0 0 -5 -4 -10
-10 -10 -5 0 -10 7 -10 15 0 8 5 15 11 15 8 0 10 17 7 49 -3 27 -1 56 4 65 12
21 -10 21 -36 1 -29 -22 -36 -19 -36 20 0 28 -7 40 -41 67 -22 18 -47 47 -54
65 -8 18 -18 33 -23 33 -5 0 -25 -16 -45 -37z m203 -224 c0 -10 -4 -19 -10
-19 -5 0 -10 12 -10 26 0 14 4 23 10 19 6 -3 10 -15 10 -26z m-692 -876 c3
-34 -1 -44 -24 -62 -15 -11 -31 -21 -35 -21 -16 0 -9 25 11 43 13 11 20 29 20
53 0 50 24 39 28 -13z"/>,
    <path d="M3714 8676 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />,
    <path d="M6080 8681 c0 -5 17 -23 37 -40 28 -23 40 -28 50 -19 19 15 16 28 -5
28 -11 0 -27 9 -37 20 -19 21 -45 27 -45 11z"/>,
    <path d="M3193 8633 c-18 -7 -17 -83 0 -83 29 0 67 26 67 46 0 35 -30 52 -67
37z"/>,
    <path d="M6764 8625 c-3 -8 -3 -19 1 -25 11 -18 25 -11 25 15 0 27 -17 34 -26
10z"/>,
    <path d="M3613 8584 c-37 -37 -53 -64 -39 -64 16 0 76 45 81 61 4 10 11 19 16
19 5 0 9 7 9 15 0 25 -23 15 -67 -31z"/>,
    <path d="M5773 8584 c-3 -8 1 -23 10 -32 15 -14 18 -14 37 3 18 16 22 17 38 3
33 -30 58 -31 77 -2 14 22 15 28 4 35 -19 12 -66 11 -89 -1 -13 -7 -21 -7 -25
0 -10 16 -45 12 -52 -6z"/>,
    <path d="M6180 8585 c0 -9 10 -19 22 -24 13 -5 17 -10 10 -15 -15 -9 -3 -36
17 -36 9 0 29 -7 45 -15 32 -17 142 -20 166 -5 27 17 -6 34 -70 35 -39 1 -65
6 -77 17 -10 8 -28 19 -40 22 -11 4 -24 13 -27 21 -7 20 -46 19 -46 0z"/>,
    <path d="M5442 8579 c-28 -11 -28 -20 1 -48 21 -19 25 -20 37 -6 17 20 60 20
60 -1 0 -10 7 -14 22 -12 31 4 34 26 4 34 -14 3 -26 10 -26 14 0 13 -75 27
-98 19z"/>,
    <path d="M5627 8544 c-16 -16 -6 -35 16 -32 12 2 22 10 22 18 0 16 -26 25 -38
14z"/>,
    <path d="M2995 8521 c-7 -24 -55 -37 -66 -19 -4 7 -12 9 -16 4 -4 -5 -2 -11 5
-13 6 -3 12 -18 12 -34 0 -16 4 -29 8 -29 13 0 92 84 92 98 0 19 -28 14 -35
-7z"/>,
    <path d="M3133 8508 c17 -27 31 -26 35 2 3 16 -2 20 -24 20 -25 0 -26 -1 -11
-22z"/>,
    <path d="M2064 8493 c-3 -10 -9 -28 -14 -40 -7 -16 -5 -23 8 -28 19 -7 72 45
72 70 0 22 -59 20 -66 -2z"/>,
    <path d="M3716 8502 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z" />,
    <path d="M3800 8496 c0 -21 21 -31 31 -15 14 22 11 29 -11 29 -11 0 -20 -6
-20 -14z"/>,
    <path d="M4305 8490 c-3 -5 6 -17 20 -26 20 -13 28 -14 40 -4 26 22 18 40 -19
40 -19 0 -37 -4 -41 -10z"/>,
    <path d="M3493 8472 c-10 -10 -23 -27 -28 -36 -7 -12 -27 -20 -60 -23 -35 -4
-61 -14 -87 -34 -20 -16 -41 -29 -45 -29 -26 0 -179 -142 -218 -202 -60 -92
-149 -157 -191 -141 -28 11 -35 -8 -14 -42 11 -19 24 -53 28 -77 6 -35 12 -44
31 -46 25 -4 67 29 77 60 3 10 24 31 47 46 43 27 164 82 182 82 5 0 31 15 57
33 50 33 78 77 78 121 0 16 8 29 23 37 12 6 38 26 57 44 19 18 49 37 65 42 28
9 30 13 32 69 1 32 4 70 5 83 4 31 -16 38 -39 13z m-268 -304 c-9 -42 -14 -50
-26 -43 -7 5 -7 47 0 73 1 1 8 2 17 2 12 0 14 -7 9 -32z"/>,
    <path d="M4455 8471 c-7 -13 17 -24 40 -19 9 1 16 8 16 13 0 14 -48 19 -56 6z" />,
    <path d="M4536 8459 c-12 -15 -15 -24 -7 -32 7 -7 12 -5 17 6 3 9 15 17 25 17
10 0 19 7 19 15 0 22 -33 18 -54 -6z"/>,
    <path d="M3049 8438 c-52 -69 -51 -64 -12 -60 34 4 39 10 57 70 11 34 -16 28
-45 -10z"/>,
    <path d="M6800 8436 c0 -8 4 -17 9 -20 10 -7 23 19 14 28 -11 11 -23 6 -23 -8z" />,
    <path d="M4648 8418 c-7 -17 8 -46 18 -35 4 4 3 17 -3 29 -7 17 -11 19 -15 6z" />,
    <path d="M3807 8404 c-9 -9 4 -35 14 -28 5 3 9 12 9 20 0 14 -12 19 -23 8z" />,
    <path d="M4562 8399 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>,
    <path d="M3967 8384 c-29 -16 -37 -44 -12 -44 8 0 19 -9 25 -20 13 -24 38 -26
46 -5 4 8 12 15 20 15 8 0 14 5 14 10 0 6 -4 10 -9 10 -6 0 -13 -4 -16 -10 -8
-13 -55 -13 -55 0 0 6 7 13 15 16 16 6 20 29 7 37 -4 3 -19 -1 -35 -9z"/>,
    <path d="M4795 8380 c-6 -11 5 -40 15 -40 4 0 11 7 14 15 3 9 11 15 18 14 24
-3 38 1 38 10 0 12 -78 12 -85 1z"/>,
    <path d="M4520 8345 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>,
    <path d="M4353 8332 c10 -10 17 -26 17 -35 0 -9 7 -17 15 -17 8 0 15 9 15 20
0 11 -6 20 -14 20 -8 0 -16 7 -20 15 -3 8 -11 15 -17 15 -8 0 -6 -7 4 -18z"/>,
    <path d="M4440 8334 c0 -25 29 -54 54 -54 29 0 16 44 -14 49 -14 1 -28 8 -32
15 -5 6 -8 3 -8 -10z"/>,
    <path d="M3717 8306 c-6 -15 1 -26 15 -26 4 0 8 9 8 20 0 23 -15 27 -23 6z" />,
    <path d="M4746 8303 c-12 -12 -5 -23 14 -23 11 0 20 4 20 9 0 11 -26 22 -34
14z"/>,
    <path d="M6363 8298 c-14 -7 -23 -20 -23 -34 0 -19 8 -26 43 -36 23 -7 49 -11
57 -10 37 4 73 -13 125 -63 56 -53 63 -75 24 -75 -11 0 -22 -7 -25 -15 -9 -22
-23 -18 -36 11 -11 24 -58 44 -83 35 -22 -8 -149 1 -167 12 -17 10 -23 8 -42
-16 -17 -21 -25 -24 -34 -15 -7 7 -12 18 -12 26 0 10 -4 10 -22 -2 -35 -25
-46 -20 -46 24 -1 29 3 40 14 40 8 0 27 6 42 14 15 7 42 16 60 19 28 6 32 4
33 -16 1 -15 3 -17 6 -6 2 10 13 19 24 22 26 7 24 21 -6 37 -20 10 -27 11 -40
0 -8 -7 -24 -10 -36 -7 -33 9 -142 -15 -166 -36 -12 -10 -26 -16 -32 -12 -12
7 -93 -20 -126 -42 -11 -7 -30 -13 -42 -13 -12 0 -47 -11 -78 -25 -31 -13 -73
-26 -94 -29 -21 -2 -48 -9 -60 -16 -12 -6 -35 -9 -50 -5 -21 4 -30 2 -34 -9
-7 -19 -52 -21 -63 -4 -5 8 -10 7 -18 -3 -8 -10 -37 -14 -104 -15 -57 0 -97
-5 -104 -12 -7 -7 -19 -12 -28 -12 -9 0 -25 -9 -34 -19 -23 -26 -116 -71 -161
-78 -20 -3 -53 -14 -73 -24 -20 -11 -45 -19 -55 -19 -10 0 -39 -6 -65 -14
-134 -39 -195 -48 -437 -66 -111 -7 -163 -6 -253 5 -62 8 -135 15 -161 15 -65
0 -139 -34 -186 -84 -19 -21 -32 -26 -49 -22 -16 4 -33 -1 -51 -16 -18 -14
-41 -21 -71 -21 -28 0 -45 -5 -49 -15 -5 -14 12 -138 21 -154 9 -14 41 -9 68
13 35 27 78 39 100 27 13 -7 22 -5 35 6 10 9 30 16 44 16 21 0 27 5 27 21 0
18 3 19 14 10 12 -10 20 -9 38 1 13 7 48 22 78 34 44 17 55 26 53 42 -2 15 2
20 11 16 8 -3 37 1 64 10 88 25 201 30 284 12 71 -16 76 -16 137 4 71 22 159
26 243 9 52 -11 57 -10 117 21 35 17 82 49 105 71 24 21 46 39 50 39 3 0 25
14 48 30 22 17 48 30 55 30 23 0 74 40 84 66 24 64 107 44 139 -34 5 -12 21
-28 36 -34 23 -11 28 -9 57 23 30 34 64 52 147 77 19 6 118 12 220 14 l186 3
93 -62 c52 -35 100 -63 106 -63 7 0 20 -9 30 -20 14 -16 31 -20 77 -20 43 0
58 4 58 14 0 17 -19 33 -45 38 -13 2 -20 11 -20 28 0 26 -5 28 -72 14 -24 -5
-36 -1 -54 17 -13 13 -34 23 -48 23 -13 1 -26 7 -28 14 -6 15 53 17 62 2 7
-11 26 -7 75 13 14 5 57 9 95 8 85 -3 100 -16 111 -97 5 -43 14 -64 39 -91 29
-31 35 -34 54 -23 21 11 21 13 7 29 -17 18 -22 57 -8 64 4 2 22 -2 40 -10 32
-13 33 -16 27 -52 -5 -32 -1 -49 24 -96 17 -31 33 -72 37 -91 5 -26 11 -34 28
-34 29 0 40 9 59 46 16 31 16 32 -8 38 -44 11 -56 44 -57 157 0 63 -6 117 -14
138 -8 18 -14 42 -14 51 0 10 -14 31 -30 47 -27 25 -60 80 -73 123 -7 23 -110
72 -160 76 -23 2 -52 -1 -64 -8z m-81 -223 c0 -5 -5 -11 -11 -13 -6 -2 -11 4
-11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m331 -53 c3 -4 -5 -19 -18 -34 -26
-31 -39 -23 -29 17 6 24 36 35 47 17z m-2664 -309 c9 -17 10 -17 28 0 22 19
43 22 43 6 0 -6 -9 -13 -20 -16 -11 -3 -20 -9 -20 -14 0 -5 -7 -9 -15 -9 -8 0
-15 4 -15 9 0 5 -9 13 -20 16 -23 7 -27 25 -5 25 7 0 18 -8 24 -17z"/>,
    <path d="M4567 8294 c-10 -11 3 -24 24 -24 10 0 19 7 19 15 0 15 -31 21 -43 9z" />,
    <path d="M3531 8261 c-14 -25 -14 -152 0 -361 11 -167 19 -200 45 -179 8 6 14
17 14 25 0 7 6 17 13 21 8 4 22 22 32 40 16 27 16 34 5 48 -11 13 -10 18 4 28
13 9 14 19 7 43 -5 17 -6 43 -4 58 3 14 0 39 -7 55 -8 21 -9 35 -1 49 8 14 7
24 -4 38 -18 24 -19 51 -2 57 9 3 9 9 0 28 -6 13 -14 32 -16 41 -4 11 -13 15
-26 11 -12 -3 -28 0 -35 6 -12 10 -17 8 -25 -8z m64 -56 c0 -16 -6 -30 -12
-32 -9 -3 -13 7 -13 32 0 25 4 35 13 33 6 -3 12 -17 12 -33z m-1 -77 c4 -22 2
-37 -9 -47 -13 -14 -15 -10 -15 32 0 54 14 63 24 15z m-4 -117 c0 -11 -4 -23
-10 -26 -6 -4 -10 5 -10 19 0 14 5 26 10 26 6 0 10 -9 10 -19z"/>,
    <path d="M6777 8263 c-3 -5 -2 -15 2 -22 7 -10 10 -10 16 -1 4 6 3 16 -3 22
-5 5 -12 6 -15 1z"/>,
    <path d="M4790 8252 c0 -13 45 -52 59 -52 7 0 24 -9 38 -20 14 -11 34 -20 44
-20 29 0 25 51 -7 81 -23 22 -26 23 -35 6 -12 -21 -38 -22 -45 -2 -6 15 -54
21 -54 7z"/>,
    <path d="M4534 8228 c-3 -4 4 -8 15 -8 10 0 26 -3 35 -6 9 -3 16 -2 16 4 0 12
-59 22 -66 10z"/>,
    <path d="M4188 8207 c-9 -7 -18 -24 -22 -38 -4 -21 -1 -30 17 -42 21 -14 23
-13 34 9 11 23 7 70 -7 79 -4 2 -14 -1 -22 -8z"/>,
    <path d="M3800 8191 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z" />,
    <path d="M4257 8203 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />,
    <path d="M4482 8198 c3 -7 13 -15 22 -16 12 -3 17 1 14 10 -3 7 -13 15 -22 16
-12 3 -17 -1 -14 -10z"/>,
    <path d="M5082 8177 c-22 -23 -29 -47 -14 -47 15 1 63 50 57 60 -9 15 -19 12
-43 -13z"/>,
    <path d="M2112 8162 c-12 -12 -22 -33 -22 -46 0 -14 -4 -28 -10 -31 -5 -3 -10
-18 -10 -33 0 -21 7 -13 35 42 39 75 41 102 7 68z"/>,
    <path d="M3917 8173 c-17 -16 -5 -30 28 -28 38 1 50 8 40 24 -7 12 -58 15 -68
4z"/>,
    <path d="M4666 8161 c-3 -5 -24 -7 -47 -4 -36 5 -41 4 -37 -13 2 -15 13 -20
52 -22 53 -3 70 5 60 31 -7 19 -20 22 -28 8z"/>,
    <path d="M3750 8150 c0 -17 41 -33 53 -21 9 9 8 14 -3 21 -20 13 -50 13 -50 0z" />,
    <path d="M7220 8140 c-6 -12 -10 -33 -8 -48 2 -23 8 -27 33 -27 27 0 30 4 33
33 5 55 -36 84 -58 42z"/>,
    <path d="M3830 8111 c0 -10 7 -22 16 -25 10 -4 13 -13 9 -25 -4 -14 0 -21 14
-26 10 -3 22 -12 25 -20 3 -8 12 -15 21 -15 21 0 19 -24 -5 -45 -47 -42 -11
-59 53 -25 20 11 50 20 66 20 34 0 49 16 31 33 -10 9 -14 9 -17 0 -6 -18 -41
-16 -59 2 -9 8 -24 15 -34 15 -10 0 -20 7 -24 15 -3 8 -12 15 -21 15 -10 0
-15 10 -15 28 0 15 -10 37 -24 50 -29 27 -36 28 -36 3z"/>,
    <path d="M3996 8111 c-3 -5 1 -12 10 -15 20 -8 29 -1 19 13 -8 13 -22 14 -29
2z"/>,
    <path d="M4932 8095 c-11 -25 -11 -25 32 -25 32 0 46 5 50 17 15 39 -64 47
-82 8z"/>,
    <path d="M4057 8066 l-29 -23 32 -7 c53 -10 67 -7 73 15 4 15 -1 22 -19 30
-31 11 -24 13 -57 -15z"/>,
    <path d="M4664 8059 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>,
    <path d="M4797 8050 c-9 -11 -25 -20 -36 -20 -10 0 -26 -11 -35 -25 -21 -32
-49 -46 -97 -49 -45 -4 -78 -24 -57 -37 23 -15 51 -9 113 21 33 16 67 30 76
30 8 0 20 5 26 11 13 13 46 33 91 54 40 19 41 29 3 20 -17 -3 -32 -2 -36 4
-10 17 -29 13 -48 -9z"/>,
    <path d="M3700 8026 c0 -18 17 -29 30 -21 8 5 7 11 -1 21 -15 18 -29 18 -29 0z" />,
    <path d="M3796 8031 c-7 -10 22 -37 30 -28 9 8 -3 37 -15 37 -5 0 -12 -4 -15
-9z"/>,
    <path d="M4470 8009 c0 -16 4 -19 23 -13 28 8 30 10 22 23 -12 20 -45 13 -45
-10z"/>,
    <path d="M2034 7985 c-8 -19 2 -45 16 -45 5 0 10 14 10 30 0 31 -16 41 -26 15z" />,
    <path d="M3841 7961 c-7 -12 -19 -21 -27 -21 -20 0 -13 -24 8 -28 20 -4 53 47
40 60 -7 7 -14 3 -21 -11z"/>,
    <path d="M4360 7960 c-12 -8 -12 -10 3 -16 22 -8 50 3 42 16 -8 12 -25 13 -45
0z"/>,
    <path d="M4227 7934 c-4 -4 -7 -11 -7 -16 0 -13 50 -6 53 7 2 12 -36 19 -46 9z" />,
    <path d="M4314 7917 c-6 -17 2 -37 16 -37 15 0 19 29 5 40 -12 10 -16 9 -21
-3z"/>,
    <path d="M2463 7913 c-27 -5 -38 -39 -29 -88 10 -53 49 -45 90 19 14 23 16 33
7 52 -10 23 -21 26 -68 17z"/>,
    <path d="M2007 7886 c-6 -15 1 -26 15 -26 11 0 10 27 -1 34 -5 3 -11 0 -14 -8z" />,
    <path d="M6050 7880 c14 -11 36 -23 50 -26 14 -4 36 -16 50 -28 39 -35 77 -56
100 -56 26 0 26 21 -2 47 -33 30 -155 83 -192 83 l-31 0 25 -20z"/>,
    <path d="M7314 7789 c-8 -14 11 -33 25 -25 6 4 11 11 11 16 0 13 -29 20 -36 9z" />,
    <path d="M3107 7753 c-7 -12 11 -53 23 -53 15 0 8 52 -7 57 -6 2 -13 0 -16 -4z" />,
    <path d="M5140 7726 c0 -15 45 -77 66 -90 28 -19 28 -17 12 21 -15 36 -48 73
-65 73 -7 0 -13 -2 -13 -4z"/>,
    <path d="M5428 7666 c-12 -26 -28 -46 -35 -46 -28 0 -8 -72 28 -98 9 -6 20
-25 24 -40 3 -16 21 -43 40 -60 20 -19 41 -54 51 -84 22 -68 91 -180 147 -241
38 -41 48 -47 70 -41 23 5 27 3 27 -15 0 -15 9 -25 29 -32 16 -6 32 -17 35
-25 6 -16 32 -19 41 -4 3 5 -12 27 -34 50 -23 24 -41 54 -45 75 -6 28 -15 39
-46 55 -37 19 -40 24 -40 61 0 41 -32 99 -55 99 -16 0 -35 44 -35 79 0 34 -34
81 -59 81 -13 0 -19 15 -28 66 -9 52 -19 74 -42 98 -17 18 -31 37 -31 43 0 31
-22 19 -42 -21z m38 -83 c-5 -12 -10 -13 -17 -6 -8 8 -7 18 2 34 12 21 14 22
17 6 2 -10 1 -25 -2 -34z"/>,
    <path d="M3185 7610 c-4 -6 0 -22 9 -35 9 -13 16 -36 16 -49 0 -16 9 -32 24
-41 13 -9 30 -30 37 -48 15 -40 38 -67 56 -67 14 0 18 31 5 46 -5 5 -16 30
-25 55 -10 26 -30 55 -47 68 -19 13 -30 31 -30 45 0 13 -7 27 -16 30 -21 8
-22 8 -29 -4z"/>,
    <path d="M4304 7575 c-7 -18 14 -52 61 -98 19 -19 35 -39 35 -45 0 -6 9 -16
21 -22 16 -9 19 -18 14 -37 -4 -16 -1 -29 9 -37 19 -15 28 -48 17 -65 -12 -19
-28 -6 -36 28 -6 27 -9 29 -45 24 -22 -3 -45 -1 -52 4 -26 21 -63 22 -85 3
-33 -27 -28 -40 15 -40 20 -1 55 -9 77 -20 22 -11 47 -19 56 -20 8 0 24 -7 35
-15 25 -19 34 -19 34 1 0 9 6 14 13 11 6 -2 11 -13 10 -23 -1 -15 15 -28 65
-53 174 -87 231 -117 248 -133 11 -9 31 -19 46 -23 15 -4 29 -13 32 -21 5 -12
67 -44 87 -44 5 0 9 11 9 24 0 17 -27 51 -95 117 -52 51 -103 98 -112 103 -29
16 -187 149 -197 166 -5 8 -28 33 -51 55 -24 22 -47 50 -53 61 -6 12 -27 29
-47 38 -19 9 -40 27 -46 39 -21 38 -55 50 -65 22z m220 -281 c16 -6 23 -34 9
-34 -11 1 -43 26 -43 34 0 8 14 8 34 0z"/>,
    <path d="M4666 7581 c-7 -11 53 -121 86 -157 156 -165 215 -218 270 -241 15
-7 46 -25 70 -40 62 -40 130 -73 152 -73 11 0 28 -7 37 -16 12 -11 49 -20 106
-25 86 -8 107 -13 188 -42 47 -18 52 -28 26 -51 -16 -14 -29 -17 -63 -12 -86
14 -132 12 -136 -5 -5 -25 103 -61 278 -95 19 -3 52 -15 74 -26 30 -16 40 -17
47 -7 13 22 -12 57 -138 196 -51 55 -115 97 -198 128 -27 10 -72 31 -98 47
-26 15 -51 28 -55 28 -6 0 -73 31 -165 76 -16 8 -39 14 -51 14 -15 0 -27 9
-34 25 -6 14 -18 25 -27 25 -8 0 -15 6 -15 13 0 7 -17 30 -38 50 -28 27 -41
34 -49 26 -15 -15 -32 -3 -49 36 -9 23 -22 34 -44 38 -16 4 -30 12 -30 19 0 7
-9 24 -20 38 -19 23 -21 24 -31 7 -10 -16 -13 -17 -27 -4 -27 23 -60 38 -66
28z"/>,
    <path d="M5265 7540 c-3 -5 -31 -22 -61 -36 -67 -32 -89 -61 -81 -110 8 -47
23 -64 57 -64 31 0 50 19 76 75 14 29 28 43 52 50 39 11 40 24 6 64 -27 33
-39 38 -49 21z"/>,
    <path d="M5972 7523 c-5 -10 -12 -38 -15 -62 -5 -42 -3 -47 31 -78 20 -18 37
-33 39 -33 1 0 7 9 13 20 8 16 6 24 -11 41 -17 17 -19 25 -11 44 8 18 8 26 -4
35 -8 7 -14 21 -14 31 0 24 -16 25 -28 2z"/>,
    <path d="M4062 7494 c-26 -31 -22 -42 11 -27 12 6 30 14 40 17 9 3 17 12 17
21 0 24 -43 17 -68 -11z"/>,
    <path d="M6209 7443 c-21 -23 -36 -49 -37 -66 -1 -16 2 -26 8 -22 6 3 10 13
10 21 0 8 7 14 15 14 20 0 78 29 87 43 10 16 -10 47 -30 47 -10 0 -34 -17 -53
-37z"/>,
    <path d="M6402 7438 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>,
    <path d="M3602 7418 c-7 -7 -12 -20 -12 -29 0 -9 -7 -22 -15 -29 -8 -7 -15
-18 -15 -25 0 -7 -6 -18 -14 -24 -8 -6 -16 -26 -19 -44 -13 -80 -23 -102 -45
-105 -25 -4 -42 -37 -42 -81 0 -31 -32 -67 -50 -56 -6 4 -18 4 -27 1 -12 -5
-13 -9 -3 -20 9 -12 7 -17 -9 -26 -18 -9 -19 -13 -8 -32 9 -16 9 -22 0 -25 -7
-3 -13 -10 -13 -17 0 -19 -31 -44 -60 -48 -31 -4 -41 -38 -11 -38 12 0 26 8
32 19 7 10 20 16 30 14 11 -3 19 2 24 16 7 23 84 106 105 113 9 3 14 21 15 48
1 33 6 47 23 58 14 9 22 25 22 43 0 17 9 37 20 46 11 10 20 23 20 30 0 22 32
43 66 43 28 0 35 4 32 18 -2 9 -13 18 -26 20 -18 3 -22 9 -21 40 0 34 1 35 11
15 6 -13 15 -23 19 -23 13 0 30 38 27 58 -2 11 5 18 20 20 12 2 22 7 22 12 0
4 -10 7 -22 7 -13 -1 -32 2 -43 6 -12 4 -26 2 -33 -5z"/>,
    <path d="M5907 7414 c-15 -15 -7 -45 11 -42 9 2 17 12 17 22 0 20 -16 31 -28
20z"/>,
    <path d="M3766 7385 c-32 -49 -14 -60 31 -20 24 21 26 26 13 35 -22 14 -26 13
-44 -15z"/>,
    <path d="M4023 7373 c-49 -34 -18 -55 42 -29 26 12 32 11 50 -5 11 -9 32 -21
48 -24 23 -6 27 -4 27 14 0 16 -6 21 -25 21 -13 0 -39 9 -57 20 -39 24 -55 24
-85 3z"/>,
    <path d="M5384 7367 c-12 -31 1 -60 29 -70 39 -13 45 -1 21 41 -24 41 -41 51
-50 29z"/>,
    <path d="M2379 7339 c-33 -20 37 -67 122 -83 21 -3 48 -15 60 -25 13 -10 47
-21 74 -25 28 -4 70 -16 94 -27 24 -10 52 -19 62 -19 10 0 21 -4 24 -10 3 -5
19 -10 35 -10 28 0 60 -22 60 -41 0 -5 7 -9 15 -9 25 0 105 -63 105 -83 0 -14
5 -17 23 -12 12 4 28 14 34 23 11 14 13 13 19 -1 9 -25 34 -21 34 6 0 26 -24
50 -91 89 -25 15 -51 34 -59 42 -7 9 -21 16 -30 16 -10 0 -32 11 -48 25 -17
14 -34 25 -40 25 -6 0 -32 11 -59 25 -26 14 -77 34 -113 44 -36 11 -83 26
-105 34 -45 16 -198 28 -216 16z"/>,
    <path d="M6282 7289 c-13 -16 -20 -34 -16 -40 7 -12 23 3 45 43 16 29 -5 27
-29 -3z"/>,
    <path d="M5835 7266 c-18 -27 -11 -74 11 -83 21 -8 63 24 70 53 7 29 0 39 -38
48 -22 5 -31 1 -43 -18z"/>,
    <path d="M3886 7271 c-3 -4 -2 -18 1 -30 5 -18 10 -21 32 -16 62 16 70 52 11
53 -22 0 -42 -3 -44 -7z"/>,
    <path d="M3820 7200 c-8 -5 -29 -10 -47 -10 -36 0 -85 -16 -94 -32 -10 -15 1
-48 17 -48 7 0 16 5 19 10 3 6 17 10 29 10 13 0 35 9 49 20 14 11 28 20 32 20
4 0 18 9 32 20 l26 20 -24 0 c-13 0 -31 -5 -39 -10z"/>,
    <path d="M6192 7168 c-24 -17 -48 -53 -87 -135 -59 -119 -64 -137 -39 -147 18
-7 29 -2 61 27 13 11 30 17 42 14 23 -6 31 9 31 56 1 50 27 147 45 167 17 19
13 40 -8 40 -7 0 -27 -10 -45 -22z"/>,
    <path d="M3597 7143 c-9 -8 -9 -53 0 -53 15 0 41 33 36 46 -5 15 -25 19 -36 7z" />,
    <path d="M3595 6983 c3 -10 9 -30 12 -46 3 -15 9 -30 14 -33 14 -8 22 46 10
72 -12 28 -47 34 -36 7z"/>,
    <path d="M3397 6884 c-17 -18 -5 -40 29 -50 38 -12 49 -4 42 33 -3 18 -57 31
-71 17z"/>,
    <path d="M3627 6843 c-10 -10 -8 -53 2 -53 5 0 21 6 35 14 l26 13 -24 17 c-29
18 -30 19 -39 9z"/>,
    <path d="M4115 6770 c-9 -15 4 -50 19 -50 8 0 20 7 27 16 11 14 10 18 -6 30
-22 17 -32 17 -40 4z"/>,
    <path d="M5384 6754 c-5 -18 -1 -29 12 -39 46 -34 165 -58 347 -70 67 -4 88
-10 121 -33 21 -15 53 -37 71 -49 66 -44 47 -76 -32 -54 -37 9 -279 41 -320
41 -7 0 -13 -11 -13 -24 0 -19 6 -25 33 -29 17 -2 46 -9 62 -15 17 -6 80 -16
140 -22 61 -6 131 -14 157 -17 l47 -5 7 41 c4 22 13 46 20 52 38 32 -8 119
-62 119 -27 0 -101 27 -124 45 -14 11 -93 26 -217 41 -37 5 -53 3 -53 -5 0
-14 -37 -15 -45 -1 -3 6 -22 10 -40 10 -24 0 -38 6 -45 20 -16 30 -58 26 -66
-6z"/>,
    <path d="M5332 6754 c2 -11 11 -20 22 -22 13 -3 17 1 14 14 -2 11 -11 20 -22
22 -13 3 -17 -1 -14 -14z"/>,
    <path d="M6302 6753 c-15 -26 -15 -53 -2 -53 11 0 24 43 18 62 -3 8 -9 5 -16
-9z"/>,
    <path d="M3689 6748 c-1 -2 -3 -24 -4 -50 -2 -38 2 -51 21 -69 23 -21 24 -21
24 -3 0 10 -7 28 -16 39 -9 12 -12 30 -9 46 4 15 1 30 -4 34 -6 3 -12 5 -12 3z"/>,
    <path d="M1520 6685 c0 -20 4 -26 18 -23 9 2 17 12 17 23 0 11 -8 21 -17 23
-14 3 -18 -3 -18 -23z"/>,
    <path d="M7037 6623 c-4 -3 -7 -15 -7 -25 0 -22 42 -25 59 -5 19 23 -31 52
-52 30z"/>,
    <path d="M6166 6585 c-29 -44 -16 -60 24 -27 14 11 19 23 15 35 -10 23 -20 21
-39 -8z"/>,
    <path d="M1495 6520 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>,
    <path d="M7300 6475 c-14 -42 -8 -65 17 -65 24 1 55 45 51 73 -2 15 -11 23
-30 25 -22 3 -28 -2 -38 -33z"/>,
    <path d="M7130 6484 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />,
    <path d="M3856 6341 c-7 -10 19 -59 39 -73 5 -4 20 -8 31 -8 12 0 34 -10 49
-22 25 -19 32 -20 71 -10 35 9 49 9 64 0 24 -15 25 -27 5 -34 -16 -7 -21 -44
-5 -44 5 0 16 13 24 29 22 42 80 57 106 26 7 -8 20 -15 29 -15 10 0 39 -11 67
-24 61 -30 195 -72 253 -80 24 -4 56 -13 72 -21 31 -16 75 -20 83 -7 3 5 -1
18 -9 30 -15 20 -48 34 -160 67 -33 10 -73 24 -90 31 -16 8 -57 21 -90 29 -33
7 -91 26 -130 40 -99 37 -172 54 -233 55 -29 0 -65 6 -80 14 -40 20 -89 29
-96 17z"/>,
    <path d="M5635 6281 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />,
    <path d="M7306 6268 c-15 -73 -15 -106 -1 -118 16 -13 35 -6 35 14 0 8 7 19
15 26 18 15 20 71 3 88 -20 20 -47 14 -52 -10z"/>,
    <path d="M5849 6181 c-8 -22 -19 -31 -43 -35 -18 -4 -40 -4 -48 -1 -23 9 -127
-21 -160 -45 -37 -27 -102 -50 -142 -50 -17 0 -58 -14 -91 -30 -33 -16 -69
-30 -80 -30 -31 0 -65 -11 -65 -21 0 -18 48 -33 113 -35 37 -1 81 -8 98 -14
36 -15 48 -7 25 16 -23 23 -18 33 44 75 73 50 122 60 205 41 60 -13 68 -13 84
2 10 9 21 16 25 16 4 0 24 14 45 30 21 17 54 33 74 37 38 6 83 45 72 62 -3 6
-37 11 -76 11 -68 0 -70 -1 -80 -29z"/>,
    <path d="M1614 6115 c-7 -17 4 -37 17 -30 12 8 12 45 -1 45 -6 0 -13 -7 -16
-15z"/>,
    <path d="M6070 6009 c0 -20 21 -49 35 -49 16 0 19 18 9 44 -7 18 -44 22 -44 5z" />,
    <path d="M3798 5976 c-17 -39 -4 -60 34 -52 42 8 42 9 23 45 -19 37 -41 40
-57 7z"/>,
    <path d="M3995 5955 c-16 -13 -40 -24 -53 -25 -38 0 -23 -33 18 -42 29 -6 38
-3 52 15 14 18 26 77 16 77 -2 0 -16 -11 -33 -25z"/>,
    <path d="M4867 5973 c-4 -3 -7 -32 -7 -63 0 -32 -7 -75 -16 -96 -12 -29 -14
-55 -9 -110 6 -66 5 -73 -13 -83 -16 -9 -20 -20 -18 -66 2 -63 11 -71 37 -35
25 34 59 204 59 294 0 20 6 36 14 39 16 6 190 -17 256 -34 25 -6 66 -18 92
-25 26 -8 53 -14 60 -14 8 0 31 -9 53 -20 22 -11 47 -20 56 -20 10 0 31 -11
47 -25 52 -43 124 -73 197 -80 39 -4 77 -13 85 -20 8 -7 31 -21 50 -30 19 -9
55 -27 80 -40 59 -32 70 -31 70 3 0 43 -49 82 -119 96 -19 3 -43 14 -53 23
-10 9 -46 20 -79 24 -41 5 -77 17 -117 41 -66 40 -114 59 -312 123 -80 26
-156 56 -169 66 -18 15 -41 19 -96 19 -59 0 -75 4 -90 20 -18 20 -46 26 -58
13z"/>,
    <path d="M1326 5934 c-12 -30 -6 -63 13 -73 25 -14 31 -5 31 46 0 36 -3 43
-19 43 -10 0 -22 -7 -25 -16z"/>,
    <path d="M5515 5920 c-12 -20 35 -67 116 -116 104 -61 96 -59 108 -35 17 31 2
52 -55 77 -29 12 -55 28 -59 34 -15 24 -100 56 -110 40z"/>,
    <path d="M5957 5903 c-3 -5 -2 -33 1 -63 6 -44 11 -55 27 -58 34 -7 55 -38 55
-81 0 -49 8 -71 26 -71 11 0 14 17 14 68 0 63 -2 71 -30 97 -16 16 -30 34 -30
41 0 18 -56 78 -63 67z"/>,
    <path d="M1767 5843 c-4 -3 -7 -19 -7 -35 0 -35 23 -34 28 1 4 25 -10 46 -21
34z"/>,
    <path d="M4147 5833 c-4 -3 -7 -20 -7 -37 0 -25 7 -34 33 -46 19 -9 39 -30 50
-52 14 -32 22 -38 47 -38 30 0 30 1 30 55 0 47 -3 55 -19 55 -10 0 -39 16 -64
35 -46 36 -58 40 -70 28z"/>,
    <path d="M4390 5770 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>,
    <path d="M4515 5750 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z" />,
    <path d="M4612 5734 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"/>,
    <path d="M4310 5627 c0 -14 10 -29 29 -40 55 -32 67 -17 25 32 -31 37 -54 40
-54 8z"/>,
    <path d="M5440 5561 c-33 -17 -53 -51 -31 -51 6 0 19 -8 29 -18 11 -11 35 -21
54 -25 31 -5 40 -1 67 27 17 18 28 36 24 40 -8 8 -92 46 -102 46 -3 0 -22 -8
-41 -19z"/>,
    <path d="M7297 5458 c-21 -51 -11 -65 35 -52 28 8 34 15 36 43 2 20 -2 37 -10
42 -28 18 -45 8 -61 -33z"/>,
    <path d="M6497 5372 c-20 -22 -22 -42 -4 -42 23 -1 612 -1 625 -1 21 2 13 30
-10 35 -13 3 -39 8 -58 12 -19 4 -148 9 -286 11 -234 3 -251 2 -267 -15z"/>,
];
const Portfolio = () => {

    const [reverse, setReverse] = useState(null);
    const [button, setButton] = useState(null)
    useEffect(() => {
        setTimeout(() => {
            setReverse(!reverse)
        }, 500)
        setTimeout(() => {
            setButton("display")
        }, 4000)
    },[])
    return (
        <React.Fragment>
            <section className="section1">
                <Navbar />
                <div className="container">
                    <div className="row">
                        <div className="typist">
                            <Typist
                                className="Typist-message"
                                startDelay={1500}
                                avgTypingDelay={40}
                                cursor={{ hideWhenDone: true }}
                            >
                                Hello, I'm Mikel Rodriguez.<br></br> I'm a Full-Stack Web Developer.
                            </Typist>
                            {button === "display" ?
                               <Link to="/portfolio"> <button type="button" className="btn btn-outline-dark">My Work</button></Link>
                                : null}
                        </div>
                        <div className="App" onClick={() => setReverse(!reverse)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 50 20000"
                                clipPath="url(#clip)"
                                className={reverse ? "reverse" : ""}
                            >
                                <defs>
                                    <clipPath id="clip" clipPathUnits="objectBoundingBox">
                                        <rect x="0" y="0" width="100%" height="100%" />
                                    </clipPath>
                                </defs>

                                {paths.map((path, index) => (
                                    <g
                                        key={index}
                                        className={
                                            !reverse ? ` cl-${index}  reverse` : `cl-${index}  initial`
                                        }
                                    >
                                        {path}
                                    </g>
                                ))}
                            </svg>
                        </div>

                    </div>
                </div>
            </section>

        </React.Fragment>
    );
}


export default Portfolio;